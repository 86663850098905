import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { formatDateTime, priceChange } from '../../../../helpers';
import { formatCurrency } from 'utils/currency';
import styles from './AccountStatement.module.scss';
import trendingUp from '../../../../assets/icons/trending-up.svg';
import trendingDown from '../../../../assets/icons/trending-down.svg';
import Spacer from 'components/Spacer/Spacer';
import InstrumentIconName from 'components/InstrumentIconName/InstrumentIconName';
import { useAppSelector } from '../../../../storeHooks';
const _t = require('counterpart');
const CardInfo = ({ title, value }) => {
    return (_jsxs("div", Object.assign({ className: styles.flexSpaceBetween }, { children: [_jsxs("span", Object.assign({ className: styles.cardLabel }, { children: [title, ":"] })), _jsx("span", Object.assign({ className: styles.cardValue }, { children: value }))] })));
};
const PositionCard = ({ symbol, side, openPrice, closePrice, openedAt, closedAt, closedPL }) => {
    const instrument = useAppSelector((state) => state.instruments[symbol]);
    const priceChangePerc = priceChange(Number(openPrice), Number(closePrice)).toFixed(2);
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles.flexSpaceBetween }, { children: [_jsx("div", { children: instrument && _jsx(InstrumentIconName, { instrument: instrument }) }), _jsxs("div", { children: [_jsx("span", { children: side === 'buy' ? _t('account_statement.buy') : _t('account_statement.sell') }), _jsx(Spacer, { x: "extraExtraSmall" }), _jsx("img", { alt: side, width: "16", src: side === 'buy' ? trendingUp : trendingDown })] })] })), _jsxs("div", Object.assign({ className: styles.positionCardBody }, { children: [_jsxs("div", { children: [_jsx(CardInfo, { title: _t('account_statement.open-price'), value: openPrice }), _jsx(CardInfo, { title: _t('account_statement.close-price'), value: closePrice }), _jsx(CardInfo, { title: "P&L", value: `${priceChangePerc}%` })] }), _jsxs("div", { children: [_jsx(CardInfo, { title: _t('account_statement.opened-at'), value: formatDateTime(openedAt) }), _jsx(CardInfo, { title: _t('account_statement.closed-at'), value: formatDateTime(closedAt) }), _jsx(CardInfo, { title: _t('account_statement.return'), value: formatCurrency(Number(closedPL), 'USD') })] })] }))] }));
};
export default React.memo(PositionCard);
