import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './InstrumentIconName.module.scss';
import InstrumentIcon from 'components/InstrumentIcon/InstrumentIcon';
import Spacer from 'components/Spacer/Spacer';
import classNames from 'classnames';
const InstrumentIconName = ({ instrument, className }) => {
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, className) }, { children: [_jsx(InstrumentIcon, { imgUrl: instrument.iconUrl, symbol: instrument.symbol }), _jsx(Spacer, { x: "small" }), _jsx("span", Object.assign({ className: styles.title }, { children: instrument.symbol }))] })));
};
export default React.memo(InstrumentIconName);
