import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export function MessageItem({ id, title, date, unread }) {
	return (
		<NavLink
			to={`/messages/${id}`}
			className={classNames('message-item', {
				'message-item--unread': unread,
			})}
		>
			<p className="message-item__title">{title}</p>
			<div className="message-item__end">
				<span className="message-item__date">{date.toLocaleDateString()}</span>
				<div className="message-item__unread-badge" />
			</div>
		</NavLink>
	);
}
