import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { getInitials } from '../../helpers';
import * as Avatar from '@radix-ui/react-avatar';
import styles from './InstrumentIcon.module.scss';
const InstrumentIcon = ({ symbol, imgUrl }) => {
    const avatarImg = imgUrl || undefined;
    const nameInitials = useMemo(() => {
        if (!symbol) {
            return null;
        }
        return getInitials(symbol);
    }, [symbol]);
    return (_jsxs(Avatar.Root, Object.assign({ className: styles.avatarRoot }, { children: [_jsx(Avatar.Image, { className: styles.avatarImage, src: avatarImg, alt: symbol !== null && symbol !== void 0 ? symbol : '' }), _jsx(Avatar.Fallback, Object.assign({ className: styles.avatarFallback, delayMs: imgUrl ? 600 : 0 }, { children: nameInitials }))] })));
};
export default React.memo(InstrumentIcon);
