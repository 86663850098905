import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import MobileScreenLayout from '../../layout/MobileScreenLayout/MobileScreenLayout';
import { useGoBackWithInternalFallback } from '../../hooks/useGoBackWithInternalFallback';
import InstrumentView from 'components/InstrumentView/InstrumentView';
const MobileInstrumentScreen = () => {
    const goBack = useGoBackWithInternalFallback('/trading/instruments');
    return (_jsx(MobileScreenLayout, { children: _jsx(InstrumentView, { onBackClick: goBack }) }));
};
export default React.memo(MobileInstrumentScreen);
