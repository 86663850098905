export var SupportedFeatures;
(function (SupportedFeatures) {
    SupportedFeatures["Coins"] = "COINS";
    SupportedFeatures["Messages"] = "MESSAGES";
    SupportedFeatures["UserUpdate"] = "USER_UPDATE";
    SupportedFeatures["Competitions"] = "COMPETITIONS";
    SupportedFeatures["Banners"] = "BANNERS";
    SupportedFeatures["Copytrading"] = "COPYTRADING";
})(SupportedFeatures || (SupportedFeatures = {}));
export var WalletStatus;
(function (WalletStatus) {
    WalletStatus["Active"] = "ACTIVE";
    WalletStatus["Disabled"] = "DISABLED";
})(WalletStatus || (WalletStatus = {}));
export var WalletPlatform;
(function (WalletPlatform) {
    WalletPlatform["MT4"] = "MT4";
    WalletPlatform["WINTRADO"] = "WINTRADO";
})(WalletPlatform || (WalletPlatform = {}));
export var LoyaltyLevelName;
(function (LoyaltyLevelName) {
    LoyaltyLevelName["BRONZE"] = "bronze";
    LoyaltyLevelName["SILVER"] = "silver";
    LoyaltyLevelName["GOLD"] = "gold";
    LoyaltyLevelName["PLATINUM"] = "platinum";
    LoyaltyLevelName["DIAMOND"] = "diamond";
})(LoyaltyLevelName || (LoyaltyLevelName = {}));
