import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import styles from './SidebarToggleButton.module.scss';
import { useIsMobile } from 'utils/responsiveHelpers';
import { FiMenu, FiX } from 'react-icons/fi';
const SidebarToggleButton = ({ collapsed, onToggle, className }) => {
    const handleOnClick = (e) => {
        e.preventDefault();
        onToggle(!collapsed);
    };
    const isMobile = useIsMobile();
    return (_jsx("button", Object.assign({ className: classNames(styles.button, className), onClick: handleOnClick }, { children: collapsed && isMobile ? _jsx(FiX, {}) : _jsx(FiMenu, {}) })));
};
export default React.memo(SidebarToggleButton);
