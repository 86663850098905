import { RECEIVED_TREND_QUOTES } from '../../actions';
// @ts-ignore
export default function trendQuotesReducer(state, action) {
    var _a;
    switch (action.type) {
        case RECEIVED_TREND_QUOTES:
            return Object.assign(Object.assign({}, state), { trendQuotes: (_a = action.payload.trends) !== null && _a !== void 0 ? _a : state.trendQuotes });
        default:
            return state;
    }
}
