const getVar = (name) => {
    return getComputedStyle(document.documentElement).getPropertyValue(name).trim();
};
// Colors
export const backgroundBlack = getVar('--background-black');
export const borderGray = getVar('--border-gray');
export const darkGray = getVar('--dark-gray');
export const lightGray = getVar('--light-gray');
export const lighterGray = getVar('--lighter-gray');
export const lightestGray = getVar('--lightest-gray');
export const fadedWhite = getVar('--faded-white');
export const blue = getVar('--blue');
export const fadedBlue = getVar('--faded-blue');
export const lightBlue = getVar('--light-blue');
export const darkBlue = getVar('--dark-blue');
export const darkRed = getVar('--dark-red');
export const darkerRed = getVar('--darker-red');
export const borderWhite = getVar('--border-white');
export const lightGreen = getVar('--light-green');
export const yellow = getVar('--yellow');
export const orange = getVar('--orange');
export const loyaltyBronze = getVar('--loyalty-bronze');
export const loyaltySilver = getVar('--loyalty-silver');
export const loyaltyGold = getVar('--loyalty-gold');
export const loyaltyPlatinum = getVar('--loyalty-platinum');
export const loyaltyDiamond = getVar('--loyalty-diamond');
export const buyColor = getVar('--buy-color');
export const sellColor = getVar('--sell-color');
// Sizes
export const headerHeight = parseInt(getVar('--header-height'), 10);
export const headerItemHeight = parseInt(getVar('--header-item-height'), 10);
export const mobileHeaderHeight = parseInt(getVar('--mobile-header-height'), 10);
export const mobileHeaderItemHeight = parseInt(getVar('--mobile-header-item-height'), 10);
// Icon sizes
export const iconSizeM = parseInt(getVar('--icon-size-m'), 10);
// Gradients
export const grayGradient = getVar('--gray-gradient');
export const blueGradient = getVar('--blue-gradient');
export const redGradient = getVar('--red-gradient');
export const greenGradient = getVar('--green-gradient');
// Spacings
export const spacerXxs = parseInt(getVar('--spacer-xxs'), 10);
export const spacerXs = parseInt(getVar('--spacer-xs'), 10);
export const spacerS = parseInt(getVar('--spacer-s'), 10);
export const spacerM = parseInt(getVar('--spacer-m'), 10);
export const spacerL = parseInt(getVar('--spacer-l'), 10);
export const spacerXl = parseInt(getVar('--spacer-xl'), 10);
// Border radius
export const radiusM = parseInt(getVar('--radius-m'), 10);
// Typography
export const fontPrim = getVar('--font-prim');
export const textColor = getVar('--text-color');
export const weightThin = parseInt(getVar('--weight-thin'), 10);
export const weightExtraLight = parseInt(getVar('--weight-extra-light'), 10);
export const weightLight = parseInt(getVar('--weight-light'), 10);
export const weightNormal = parseInt(getVar('--weight-normal'), 10);
export const weightMedium = parseInt(getVar('--weight-medium'), 10);
export const weightSemiBold = parseInt(getVar('--weight-semi-bold'), 10);
export const weightBold = parseInt(getVar('--weight-bold'), 10);
export const weightExtraBold = parseInt(getVar('--weight-extra-bold'), 10);
export const weightHeavy = parseInt(getVar('--weight-heavy'), 10);
export const headingXl = parseInt(getVar('--heading-xl'), 10);
export const headingLarge = parseInt(getVar('--heading-large'), 10);
export const heading = parseInt(getVar('--heading'), 10);
export const headingS = parseInt(getVar('--heading-s'), 10);
export const title = parseInt(getVar('--title'), 10);
export const body = parseInt(getVar('--body'), 10);
export const label = parseInt(getVar('--label'), 10);
export const caption = parseInt(getVar('--caption'), 10);
export const captionSmall = parseInt(getVar('--caption-small'), 10);
export const bodyLineHeight = parseInt(getVar('--body-line-height'), 10);
export const headingLineHeight = parseInt(getVar('--heading-line-height'), 10);
export const labelLineHeight = parseInt(getVar('--label-line-height'), 10);
export const captionLineHeight = parseInt(getVar('--caption-line-height'), 10);
// Breakpoints
export const xs = parseInt(getVar('--xs'), 10);
export const sm = parseInt(getVar('--sm'), 10);
export const mdSm = parseInt(getVar('--md-sm'), 10);
export const md = parseInt(getVar('--md'), 10);
export const lg = parseInt(getVar('--lg'), 10);
export const desktop = parseInt(getVar('--desktop'), 10);
export const xlg = parseInt(getVar('--xlg'), 10);
export const xxlg = parseInt(getVar('--xxlg'), 10);
export const xxxlg = parseInt(getVar('--xxxlg'), 10);
// Z-indexes
export const zIndexDropdown = parseInt(getVar('--zindex-dropdown'), 10);
export const zIndexSticky = parseInt(getVar('--zindex-sticky'), 10);
export const zIndexFixed = parseInt(getVar('--zindex-fixed'), 10);
export const zIndexModalBackdrop = parseInt(getVar('--zindex-modal-backdrop'), 10);
export const zIndexModal = parseInt(getVar('--zindex-modal'), 10);
export const zIndexPopover = parseInt(getVar('--zindex-popover'), 10);
export const zIndexTooltip = parseInt(getVar('--zindex-tooltip'), 10);
