import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './CustomIcon.module.scss';
import classNames from 'classnames';
const CustomIcon = ({ src, alt, className, alignBottom = false }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.container, className, {
            [styles.containerAlignBottom]: alignBottom,
        }) }, { children: _jsx("img", { src: src, alt: alt, className: styles.icon }) })));
};
export default React.memo(CustomIcon);
