import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import styles from './MobileSidebar.module.scss';
import SidebarToggleButton from 'components/SidebarToggleButton/SidebarToggleButton';
import classNames from 'classnames';
import { Portal } from '@radix-ui/react-portal';
import SidebarNavItem from 'components/Sidebar/SidebarNavItem';
import { HiArrowDown, HiArrowUp, HiLogout, HiOutlineDocumentText, HiOutlineHome, HiOutlineSpeakerphone, } from 'react-icons/hi';
import { isSet } from '../../helpers';
import config from 'config';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import Spacer from 'components/Spacer/Spacer';
import _t from 'counterpart';
import { useAppDispatch } from '../../storeHooks';
import { actions } from 'wintrado-api';
import MobileSidebarFooter from 'components/MobileSidebar/MobileSidebarFooter';
import { FiEye } from 'react-icons/fi';
const MobileSidebar = ({ className }) => {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const dispatch = useAppDispatch();
    const handleLogout = useCallback((e) => {
        e.preventDefault();
        dispatch(actions.logoff({ userRequested: true }));
    }, [dispatch]);
    const handleCloseSidebar = useCallback(() => {
        setIsSidebarOpen(false);
    }, []);
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, className) }, { children: [_jsx(SidebarToggleButton, { collapsed: isSidebarOpen, onToggle: setIsSidebarOpen }), _jsx(Portal, { children: _jsxs("div", Object.assign({ className: classNames(styles.sidebar, {
                        [styles.sidebarOpen]: isSidebarOpen,
                    }) }, { children: [_jsx(SidebarNavItem, { to: "/trading", icon: _jsx(HiOutlineHome, {}), labelKey: "main.trading", onClick: handleCloseSidebar }), _jsx(SidebarNavItem, { to: "/trading/watchlist", icon: _jsx(FiEye, {}), labelKey: "main.watchlist", onClick: handleCloseSidebar }), _jsx(SidebarNavItem, { to: "/deposit", icon: _jsx(HiArrowUp, {}), labelKey: "main.deposit", onClick: handleCloseSidebar }), _jsx(SidebarNavItem, { to: "/withdraw", icon: _jsx(HiArrowDown, {}), labelKey: "main.withdraw", onClick: handleCloseSidebar }), _jsx(SidebarNavItem, { to: "/account-statement", icon: _jsx(HiOutlineDocumentText, {}), labelKey: "main.account_statement", onClick: handleCloseSidebar }), isSet(config.affiliatePortalUrl) && (_jsx(SidebarNavItem, { to: config.affiliatePortalUrl, icon: _jsx(HiOutlineSpeakerphone, {}), labelKey: "main.marketing", onClick: handleCloseSidebar })), _jsx("div", Object.assign({ className: styles.logoutWrapper }, { children: _jsxs(ButtonBase, Object.assign({ onClick: handleLogout, className: classNames(styles.logoutButton) }, { children: [_jsx(HiLogout, {}), _jsx(Spacer, { x: "medium" }), _t('main.logout')] })) })), _jsx(MobileSidebarFooter, {})] })) })] })));
};
export default React.memo(MobileSidebar);
