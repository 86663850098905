import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './Stat.module.scss';
import classNames from 'classnames';
const Stat = ({ className, prepend = null, label, data, dataAppend = null, onDataClick, dark = false }) => {
    const dataSpan = _jsx("span", Object.assign({ className: styles.data }, { children: data }));
    const dataElement = onDataClick ? _jsx("a", Object.assign({ onClick: onDataClick }, { children: dataSpan })) : dataSpan;
    return (_jsxs("div", Object.assign({ className: classNames(styles.stat, className, {
            [styles.statDark]: dark,
        }) }, { children: [prepend !== null && _jsx("div", Object.assign({ className: styles.prepend }, { children: prepend })), _jsxs("div", Object.assign({ className: styles.labelDataWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.labelTinyAppendWrapper }, { children: [_jsx("span", Object.assign({ className: styles.label }, { children: label })), dataAppend !== null && _jsx("div", Object.assign({ className: styles.append }, { children: dataAppend }))] })), _jsxs("div", Object.assign({ className: styles.dataWrapper }, { children: [dataElement, dataAppend !== null && _jsx("div", Object.assign({ className: styles.append }, { children: dataAppend }))] }))] }))] })));
};
export default React.memo(Stat);
