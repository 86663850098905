import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import styles from './LoyaltyLevelsProgress.module.scss';
import classNames from 'classnames';
import { useAppSelector } from '../../../storeHooks';
import StarIcon from '../StarIcon/StarIcon';
import _t from 'counterpart';
const LoyaltyLevelsProgress = () => {
    const { currentLevel, totalCoinsMonth, coinsUntilNextLevel } = useAppSelector((state) => state.user.loyaltyLevel);
    const coinsForNextLevel = useMemo(() => {
        if (coinsUntilNextLevel !== null) {
            return totalCoinsMonth + coinsUntilNextLevel;
        }
        else {
            return null;
        }
    }, [totalCoinsMonth, coinsUntilNextLevel]);
    const percentageUntilNextLevel = useMemo(() => {
        if (coinsForNextLevel) {
            return (totalCoinsMonth / coinsForNextLevel) * 100;
        }
        else {
            return 0;
        }
    }, [coinsForNextLevel, totalCoinsMonth]);
    const getProgressBarPercentageForLevel = useCallback((level) => {
        if (currentLevel === level)
            return `${percentageUntilNextLevel}%`;
        if (currentLevel > level)
            return '100%';
        return '0%';
    }, [currentLevel, percentageUntilNextLevel]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(StarIcon, { active: currentLevel > 0, iconClassName: styles.starBronze, label: _t.translate('loyalty.levels.bronze') }), _jsx("div", Object.assign({ className: styles.progressBarTrack }, { children: _jsx("div", { className: classNames(styles.progressBar, styles.progressBarSilver), style: { width: getProgressBarPercentageForLevel(1) } }) })), _jsx(StarIcon, { active: currentLevel > 1, iconClassName: styles.starSilver, label: _t.translate('loyalty.levels.silver') }), _jsx("div", Object.assign({ className: styles.progressBarTrack }, { children: _jsx("div", { className: classNames(styles.progressBar, styles.progressBarGold), style: { width: getProgressBarPercentageForLevel(2) } }) })), _jsx(StarIcon, { active: currentLevel > 2, iconClassName: styles.starGold, label: _t.translate('loyalty.levels.gold') }), _jsx("div", Object.assign({ className: styles.progressBarTrack }, { children: _jsx("div", { className: classNames(styles.progressBar, styles.progressBarPlatinum), style: { width: getProgressBarPercentageForLevel(3) } }) })), _jsx(StarIcon, { active: currentLevel > 3, iconClassName: styles.starPlatinum, label: _t.translate('loyalty.levels.platinum') }), _jsx("div", Object.assign({ className: styles.progressBarTrack }, { children: _jsx("div", { className: classNames(styles.progressBar, styles.progressBarDiamond), style: { width: getProgressBarPercentageForLevel(4) } }) })), _jsx(StarIcon, { active: currentLevel > 4, iconClassName: styles.starDiamond, label: _t.translate('loyalty.levels.diamond') })] })));
};
export default React.memo(LoyaltyLevelsProgress);
