import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import BrandLogo from '../BrandLogo/BrandLogo';
import SidebarToggleButton from '../SidebarToggleButton/SidebarToggleButton';
import styles from './Sidebar.module.scss';
import LoyaltyStat from '../LoyaltyStat/LoyaltyStat';
import Spacer from '../Spacer/Spacer';
import config from 'config';
import { HiArrowDown, HiArrowUp, HiOutlineDocumentText, HiOutlineHome, HiOutlineSpeakerphone, } from 'react-icons/hi';
import { isSet } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../storeHooks';
import { actions } from 'wintrado-api';
import classNames from 'classnames';
import SidebarNavItem from 'components/Sidebar/SidebarNavItem';
import { useIsTablet } from 'utils/responsiveHelpers';
const Sidebar = () => {
    const isSidebarCollapsed = useAppSelector((state) => state.settings.isSidebarCollapsed);
    const dispatch = useAppDispatch();
    const isTablet = useIsTablet();
    useEffect(() => {
        if (isTablet) {
            dispatch(actions.setIsSidebarCollapsed(true));
        }
    }, [dispatch, isTablet]);
    const handleSidebarCollapseToggle = useCallback((collapsed) => {
        dispatch(actions.setIsSidebarCollapsed(collapsed));
    }, [dispatch]);
    const handleLogout = useCallback((e) => {
        e.preventDefault();
        dispatch(actions.logoff({ userRequested: true }));
    }, [dispatch]);
    return (_jsxs("aside", Object.assign({ className: classNames(styles.sidebar, {
            [styles.sidebarCollapsed]: isSidebarCollapsed,
        }) }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [!isSidebarCollapsed && _jsx(BrandLogo, {}), _jsx(SidebarToggleButton, { collapsed: isSidebarCollapsed, onToggle: handleSidebarCollapseToggle })] })), _jsx(Spacer, { y: "extraLarge" }), _jsx(LoyaltyStat, { isCollapsed: isSidebarCollapsed }), _jsx(Spacer, { y: "extraLarge" }), _jsx(SidebarNavItem, { to: "/trading", icon: _jsx(HiOutlineHome, {}), labelKey: "main.trading", isCollapsed: isSidebarCollapsed }), _jsx(SidebarNavItem, { to: "/deposit", icon: _jsx(HiArrowUp, {}), labelKey: "main.deposit", isCollapsed: isSidebarCollapsed }), _jsx(SidebarNavItem, { to: "/withdraw", icon: _jsx(HiArrowDown, {}), labelKey: "main.withdraw", isCollapsed: isSidebarCollapsed }), _jsx(SidebarNavItem, { to: "/account-statement", icon: _jsx(HiOutlineDocumentText, {}), labelKey: "main.account_statement", isCollapsed: isSidebarCollapsed }), isSet(config.affiliatePortalUrl) && (_jsx(SidebarNavItem, { to: config.affiliatePortalUrl, icon: _jsx(HiOutlineSpeakerphone, {}), labelKey: "main.marketing", isCollapsed: isSidebarCollapsed }))] })));
};
export default React.memo(Sidebar);
