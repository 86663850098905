import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styles from './Alert.module.scss';
import { FiAlertCircle, FiAlertTriangle, FiCheckCircle } from 'react-icons/fi';
import classNames from 'classnames';
// make sure to also change this in Alert.module.scss, ideally they'd be the same variable but requires some Webpack setup
const AUTO_CLOSE_DELAY = 3000;
const Alert = ({ title, description, variant, autoClose, onClose, actions, fillParent = false, withUnboundProgressBar = false, }) => {
    const iconBasedOnVariant = React.useMemo(() => {
        const iconSize = 48;
        if (variant === 'success') {
            return _jsx(FiCheckCircle, { className: styles.icon, size: iconSize });
        }
        if (variant === 'error') {
            return _jsx(FiAlertTriangle, { className: styles.icon, size: iconSize });
        }
        if (variant === 'warning') {
            return _jsx(FiAlertCircle, { className: styles.icon, size: iconSize });
        }
    }, [variant]);
    useEffect(() => {
        if (autoClose) {
            const timeout = setTimeout(() => {
                onClose === null || onClose === void 0 ? void 0 : onClose();
            }, AUTO_CLOSE_DELAY);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [autoClose, onClose]);
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                onClose === null || onClose === void 0 ? void 0 : onClose();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, {
            [styles.containerSuccess]: variant === 'success',
            [styles.containerError]: variant === 'error',
            [styles.containerWarning]: variant === 'warning',
            [styles.containerFillParent]: fillParent,
            [styles.containerWithUnboundProgressBar]: withUnboundProgressBar,
        }) }, { children: [autoClose && (_jsx("div", Object.assign({ className: styles.autoClose }, { children: _jsx("div", Object.assign({ className: styles.autoCloseProgressBackground }, { children: _jsx("div", { className: styles.autoCloseProgressBar }) })) }))), _jsxs("div", Object.assign({ className: styles.contentWrapper }, { children: [_jsx("div", Object.assign({ className: styles.iconWrapper }, { children: iconBasedOnVariant })), _jsx("h4", Object.assign({ className: styles.title }, { children: title })), description && _jsx("p", Object.assign({ className: styles.description }, { children: description }))] })), actions && _jsx("div", Object.assign({ className: styles.actions }, { children: actions }))] })));
};
export default React.memo(Alert);
