import { HISTORY_QUOTES_LOADING, HISTORY_UPDATED, SET_CANDLES_DATA } from "../../actions";
export default function candlesReducer(state, action) {
    switch (action.type) {
        case HISTORY_UPDATED: {
            const { start, end, candles, symbol, bucketSizeSeconds } = action.payload;
            return Object.assign(Object.assign({}, state), { candles: Object.assign(Object.assign({}, state.candles), { symbol,
                    start,
                    end, data: candles, bucketSizeSeconds, loading: false }) });
        }
        case HISTORY_QUOTES_LOADING: {
            return Object.assign(Object.assign({}, state), { candles: Object.assign(Object.assign({}, state.candles), { loading: true, data: [] }) });
        }
        case SET_CANDLES_DATA: {
            return Object.assign(Object.assign({}, state), { candles: Object.assign(Object.assign({}, state.candles), { data: action.payload.data }) });
        }
        default:
            return state;
    }
}
