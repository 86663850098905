import { API_LISTENER_CONNECTED, SHOW_LOADING, HIDE_LOADING, LOGIN_SUCCESS, SET_DEMO_LOGIN_FLAG, UNSET_DEMO_LOGIN_FLAG, LOGIN_REQUESTED, SET_SOUNDBANK_LOADING, SET_SOUNDBANK_LOADING_PERCENTAGE, apiActions, UPDATE_USER_SUCCESS, SET_RELEASE_VERSION, SET_OAUTH_TOKENS, UPDATE_USER_LOYALTY_LEVEL, FETCH_GREY_LABEL_LOGO_SUCCESS, FETCH_GREY_LABEL_LOGO_REQUESTED, FETCH_GREY_LABEL_LOGO_REJECTED, } from '../../actions';
import { isSet } from '../../utils/helpers';
export default function coreApiReducer(state, action) {
    switch (action.type) {
        case SHOW_LOADING:
            return Object.assign(Object.assign({}, state), { loading: true });
        case HIDE_LOADING:
            return Object.assign(Object.assign({}, state), { loading: false });
        case API_LISTENER_CONNECTED: {
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { apiConnected: true }) });
        }
        case LOGIN_REQUESTED:
            return Object.assign(Object.assign({}, state), { loggedIn: false, loading: true, user: Object.assign({}, state.user) });
        case LOGIN_SUCCESS:
            return Object.assign(Object.assign({}, state), { loggedIn: true, loading: false });
        case SET_DEMO_LOGIN_FLAG:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { demoAccountExists: true }) });
        case UNSET_DEMO_LOGIN_FLAG:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { demoAccountExists: false }) });
        case SET_SOUNDBANK_LOADING:
            return Object.assign(Object.assign({}, state), { soundbankLoading: action.payload.loading });
        case SET_SOUNDBANK_LOADING_PERCENTAGE:
            return Object.assign(Object.assign({}, state), { soundbankLoadingPercentage: action.payload.percentage });
        case apiActions.LOGIN:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { id: action.payload.user.id, name: action.payload.user.name, referralURL: action.payload.user.referralURL, token: action.payload.token, activePackage: action.payload.user.activePackage ? action.payload.user.activePackage : null, avatarUrl: action.payload.user.avatarUrl, supportedFeatures: action.payload.supportedFeatures ? action.payload.supportedFeatures : null, communicationLanguage: isSet(action.payload.user.communicationLanguage) ? action.payload.user.communicationLanguage : state.user.communicationLanguage, mtLogin: action.payload.user.mtAccountId, wallet: action.payload.wallet, loyaltyLevel: action.payload.user.loyaltyLevel }) });
        case SET_OAUTH_TOKENS:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { token: action.payload.accessToken, refreshToken: action.payload.refreshToken }) });
        case UPDATE_USER_SUCCESS:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { communicationLanguage: isSet(action.payload.communicationLanguage) ? action.payload.communicationLanguage : state.user.communicationLanguage }) });
        case UPDATE_USER_LOYALTY_LEVEL:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { loyaltyLevel: action.payload.loyaltyLevel }) });
        case SET_RELEASE_VERSION: {
            return Object.assign(Object.assign({}, state), { releaseVersion: action.payload.version });
        }
        case FETCH_GREY_LABEL_LOGO_SUCCESS: {
            return Object.assign(Object.assign({}, state), { greyLabelLogo: {
                    data: action.payload.logos,
                    isLoading: false
                } });
        }
        case FETCH_GREY_LABEL_LOGO_REQUESTED: {
            return Object.assign(Object.assign({}, state), { greyLabelLogo: {
                    isLoading: true,
                    data: null
                } });
        }
        case FETCH_GREY_LABEL_LOGO_REJECTED: {
            return Object.assign(Object.assign({}, state), { greyLabelLogo: {
                    data: null,
                    isLoading: false
                } });
        }
        default:
            return state;
    }
}
