import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import config from 'config';
import { PageLayout } from '../global/PageLayout';
import _t from 'counterpart';
import { CashierIframeWrapper } from '../CashierIframeWrapper';
import { useAppSelector } from '../../../storeHooks';
import { useHistory } from 'react-router-dom';
const PageWithdraw = () => {
    const user = useAppSelector((state) => state.user);
    const { withdrawURL } = config.cashier;
    const history = useHistory();
    const handleReceivedMessage = useCallback((e) => {
        if (e.data && ['WT_WITHDRAW_SUCCESS', 'WT_WITHDRAW_FAILURE'].includes(e.data)) {
            history.push('/');
        }
    }, [history]);
    useEffect(() => {
        window.addEventListener('message', handleReceivedMessage);
        return () => {
            window.removeEventListener('message', handleReceivedMessage);
        };
    });
    return (_jsx(PageLayout, Object.assign({ className: "deposit", isEmbeddedCashier: true }, { children: _jsx(CashierIframeWrapper, { url: withdrawURL(user, _t.getLocale()) }) })));
};
export default React.memo(PageWithdraw);
