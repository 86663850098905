var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './TextField.module.scss';
import { isSet } from '../../helpers';
import classNames from 'classnames';
import FormError from 'components/FormError/FormError';
const TextField = React.forwardRef((_a, forwardRef) => {
    var _b;
    var { name, type, label, value, onChange, onBlur, onFocus, required = false, disabled = false, error, className, iconStart, iconEnd, autocomplete = ['email', 'password'].includes(type !== null && type !== void 0 ? type : ''), monospace = false, placeholder, copyPlaceholderToValueOnFocus } = _a, props = __rest(_a, ["name", "type", "label", "value", "onChange", "onBlur", "onFocus", "required", "disabled", "error", "className", "iconStart", "iconEnd", "autocomplete", "monospace", "placeholder", "copyPlaceholderToValueOnFocus"]);
    const [isLabelFloating, setIsLabelFloating] = useState(isSet(value) && value.toString().length > 0);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    useImperativeHandle(forwardRef, () => inputRef.current);
    useEffect(() => {
        if (inputRef.current && inputRef.current.value.length > 0) {
            setIsLabelFloating(true);
        }
    }, [(_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.value]);
    useEffect(() => {
        if (placeholder && placeholder.trim().length > 0) {
            setIsLabelFloating(true);
        }
    }, [placeholder]);
    const handleOnFocus = (e) => {
        setIsLabelFloating(true);
        setIsFocused(true);
        if (copyPlaceholderToValueOnFocus) {
            if (inputRef.current && inputRef.current.value.length === 0) {
                inputRef.current.value = placeholder !== null && placeholder !== void 0 ? placeholder : '';
            }
        }
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
    };
    const handleOnBlur = (e) => {
        if ((!inputRef.current || inputRef.current.value.trim().length === 0) &&
            (!placeholder || placeholder.trim().length === 0)) {
            setIsLabelFloating(false);
        }
        setIsFocused(false);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    };
    const handleOnChange = (e) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
        if (e.target.value.length > 0) {
            setIsLabelFloating(true);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames(styles.textField, className, {
                    [styles.textFieldFloating]: isLabelFloating && isSet(label),
                    [styles.textFieldFocused]: isFocused,
                    [styles.textFieldHasError]: isSet(error),
                    [styles.textFieldWithStartIcon]: isSet(iconStart),
                    [styles.textFieldWithEndIcon]: isSet(iconEnd),
                    [styles.disabled]: disabled,
                }) }, { children: [isSet(iconStart) && _jsx("div", Object.assign({ className: classNames(styles.icon, styles.iconStart) }, { children: iconStart })), isSet(label) && (_jsx("label", Object.assign({ htmlFor: name, className: classNames(styles.label) }, { children: label }))), _jsx("input", Object.assign({ ref: inputRef, id: name, name: name, type: type, value: value, className: classNames(styles.input, {
                            [styles.inputMonospace]: monospace,
                        }), onChange: handleOnChange, onFocus: handleOnFocus, onBlur: handleOnBlur, required: required, disabled: disabled, autoComplete: autocomplete ? 'on' : 'off', placeholder: placeholder }, props)), isSet(iconEnd) && _jsx("div", Object.assign({ className: classNames(styles.icon, styles.iconEnd) }, { children: iconEnd }))] })), isSet(error) && _jsx(FormError, { error: error, margin: 'top' })] }));
});
export default React.memo(TextField);
