import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable */
import React, { useMemo } from 'react';
import styles from './MarketSentiment.module.scss';
import Spacer from 'components/Spacer/Spacer';
import _t from 'counterpart';
const MarketSentiment = ({ value }) => {
    const labels = [
        _t('instrument.strong_sell'),
        _t('instrument.normal_sell'),
        _t('instrument.neutral'),
        _t('instrument.normal_buy'),
        _t('instrument.strong_buy'),
    ];
    const parsedValue = useMemo(() => {
        const transformedValue = (value + 1) / 2;
        return parseFloat(transformedValue.toFixed(2));
    }, [value]);
    const getLabelFromValue = (value) => {
        const index = Math.floor(value * (labels.length - 1));
        const labelForValue = labels[index];
        if (labelForValue === undefined) {
            throw new Error(`No label for value ${value}`);
        }
        return labelForValue;
    };
    const degreesPerLabel = 180 / (labels.length - 1);
    const radius = parsedValue * 100;
    const startAngle = 180 * parsedValue;
    return (_jsxs("div", Object.assign({ className: `${styles.content}` }, { children: [_jsx("h3", Object.assign({ className: styles.title }, { children: _t('instrument.market_sentiment').toUpperCase() })), _jsx(Spacer, { y: "extraLarge" }), _jsx("div", Object.assign({ className: styles.flexCenter }, { children: _jsxs("div", Object.assign({ className: styles.chartWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.chartOverflow }, { children: [_jsxs("svg", Object.assign({ id: "chart", xmlns: "http://www.w3.org/2000/svg", width: "218", height: "109", viewBox: "0 0 218 109", fill: "none" }, { children: [_jsx("path", { d: "M5.00373 108.893C5.00373 81.381 15.9097 54.9963 35.3224 35.5427C54.735 16.0891 81.0643 5.16017 108.518 5.16017C135.972 5.16017 162.301 16.0891 181.714 35.5427C201.126 54.9963 212.032 81.381 212.032 108.893", stroke: "#2B2E38", strokeWidth: "10" }), _jsx("path", { d: "M5.00333 108.893A103 103 0 0 1 211.014 108.893", stroke: "url(#paint0_linear_4615_28506)", strokeWidth: "10" }), _jsx("defs", { children: _jsxs("linearGradient", Object.assign({ id: "paint0_linear_4615_28506", x1: "0%", y1: "0%", x2: "100%", y2: "0%" }, { children: [_jsx("stop", { stopColor: "#E10000" }), _jsx("stop", { offset: "0.223958", stopColor: "#C401E4" }), _jsx("stop", { offset: "0.515625", stopColor: "#4462FE" }), _jsx("stop", { offset: "1", stopColor: "#0192E4" })] })) })] })), _jsx("svg", Object.assign({ className: styles.chartBlack, xmlns: "http://www.w3.org/2000/svg", width: "218", height: "109", viewBox: "0 0 218 109", fill: "none", style: {
                                        transform: `translateX(-50%) rotate(${startAngle + 0.5}deg)`,
                                    } }, { children: _jsx("path", { d: `M5.00333 108.893A103 103 0 0 1 211.014 108.893`, stroke: "#2B2E38", strokeWidth: "13", fill: "none" }) }))] })), _jsx("svg", Object.assign({ className: styles.labels }, { children: labels.map((label, index) => {
                                const angle = index * degreesPerLabel;
                                const radius = 218 / 2 + 15; // Adjust the radius for label positioning, last number is offest to compensate for the arc
                                const startPositionX = 108;
                                const startingPositionY = index === 0 || index === labels.length - 1 ? 100 : 108; // if first or last in the array push them up a little
                                const x = startPositionX - Math.cos((angle * Math.PI) / 180) * (radius + 25);
                                const y = startingPositionY - Math.sin((angle * Math.PI) / 180) * radius;
                                const words = label.split(' ');
                                const firstLine = words.slice(0, Math.ceil(words.length / 2)).join(' ');
                                const secondLine = words.slice(Math.ceil(words.length / 2)).join(' ');
                                return (_jsx("text", Object.assign({ x: x, y: y, className: styles.speedLabel, textAnchor: "middle", alignmentBaseline: "middle" }, { children: secondLine !== '' ? (_jsxs(_Fragment, { children: [_jsx("tspan", Object.assign({ x: x, dy: "-1.2em" }, { children: firstLine })), _jsx("tspan", Object.assign({ x: x, dy: "1.2em" }, { children: secondLine }))] })) : (firstLine) }), index));
                            }) })), _jsxs("svg", Object.assign({ id: "needle", className: styles.needle, xmlns: "http://www.w3.org/2000/svg", width: "11", height: "83", viewBox: "0 0 11 83", fill: "none", style: {
                                transform: `translateX(-50%) rotate(calc(${radius}*1.8deg - 90deg))`,
                            } }, { children: [_jsx("ellipse", { cx: "5.51751", cy: "77.6983", rx: "5.17571", ry: "5.18662", fill: "#fff" }), _jsx("path", { d: "M4.48239 0.935547H6.55268L8.62296 77.6975H2.41211L4.48239 0.935547Z", fill: "#fff" })] })), _jsx("span", Object.assign({ className: styles.caption }, { children: getLabelFromValue(parsedValue) }))] })) })), _jsx(Spacer, { y: "large" })] })));
};
export default React.memo(MarketSentiment);
