var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './SelectItem.module.scss';
import * as SelectPrimitive from '@radix-ui/react-select';
import { FiCheck } from 'react-icons/fi';
import Spacer from 'components/Spacer/Spacer';
const SelectItem = React.forwardRef((_a, forwardedRef) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsxs(SelectPrimitive.Item, Object.assign({}, props, { ref: forwardedRef, className: styles.item }, { children: [_jsx(SelectPrimitive.ItemText, { children: children }), _jsx(Spacer, { x: "extraSmall" }), _jsx(SelectPrimitive.ItemIndicator, { children: _jsx(FiCheck, { size: 16 }) })] })));
});
export default React.memo(SelectItem);
