import React from 'react';

export default function InlineLoader() {
	return (
		<div className="inline-loader">
			<div className="lds-ring">
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
}
