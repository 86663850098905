import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import styles from './InstrumentsAndWatchlist.module.scss';
import _t from 'counterpart';
import { useAppSelector } from '../../storeHooks';
import InstrumentsList from 'components/InstrumentsList/InstrumentsList';
import Tabs from 'components/Tabs/Tabs';
import { crmService } from 'wintrado-api';
import { useQuery } from '@tanstack/react-query';
import { isSet } from '../../helpers';
import Alert from 'components/Alert/Alert';
import Button from 'ui/components/Button/Button';
const InstrumentsAndWatchlist = () => {
    const instruments = useAppSelector((state) => state.instruments);
    const instrumentsArray = useMemo(() => Object.values(instruments), [instruments]);
    const walletUsername = useAppSelector((state) => { var _a; return (_a = state.user.wallet) === null || _a === void 0 ? void 0 : _a.username; });
    if (!walletUsername) {
        throw new Error('Wallet username not found');
    }
    const [activeTab, setActiveTab] = useState('instruments');
    const switchToInstrumentsTab = () => setActiveTab('instruments');
    const watchlistSymbols = useQuery({
        queryKey: ['watchlist', walletUsername],
        queryFn: () => crmService.getWatchlist(walletUsername),
    });
    const watchlistInstruments = useMemo(() => {
        if (!isSet(watchlistSymbols.data) || watchlistSymbols.data.length < 1) {
            return [];
        }
        return watchlistSymbols.data.map((w) => instruments[w.symbol]).filter(isSet);
    }, [instruments, watchlistSymbols.data]);
    return (_jsx(Tabs, { value: activeTab, onValueChange: setActiveTab, listClassName: styles.header, content: [
            {
                value: 'instruments',
                label: _t('main.instruments'),
                content: _jsx(InstrumentsList, { instruments: instrumentsArray, showSearch: true }),
            },
            {
                value: 'watchlist',
                label: _t('main.watchlist'),
                content: (_jsx(InstrumentsList, { instruments: watchlistInstruments, emptyPlaceholder: _jsx(Alert, { title: _t('main.no_instruments'), description: _t('main.empty_watchlist_description'), variant: 'warning', fillParent: true, actions: _jsx(Button, Object.assign({ variant: 'primary', onClick: switchToInstrumentsTab, flexible: true }, { children: _t('main.instruments_list') })) }) })),
            },
        ] }));
};
export default React.memo(InstrumentsAndWatchlist);
