import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
/**
 * Navigates back to the previous location, but only if the current location was
 * navigated to through in-app routing. If the current location was accessed directly or
 * from an external source, replaces the current location with a fallback URL.
 */
export const useGoBackWithInternalFallback = (fallbackUrl) => {
    const history = useHistory();
    const location = useLocation();
    return useCallback(() => {
        if (location.key) {
            history.goBack();
        }
        else {
            history.replace(fallbackUrl);
        }
    }, [fallbackUrl, history, location.key]);
};
