import { StakeMode } from "../reducers/instruments/types";
import moment from "moment";
import { store } from "../store";
import { isNumeric } from "./helpers";
function getDatePossibilitiesFromDateInfo(dateString) {
    var _a, _b;
    const positionDurations = (_b = (_a = store.getState().config) === null || _a === void 0 ? void 0 : _a.positionDurations) !== null && _b !== void 0 ? _b : [50];
    if (!positionDurations) {
        throw Error('positionDurations have to be set to getDatePossibilitiesFromDateInfo');
    }
    const minDuration = Math.min(...positionDurations);
    const dateCurrent = moment().add(minDuration, 'seconds');
    const dayOfWeek = {
        SUNDAY: 'Sunday',
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday'
    };
    const [dayString, hourString, minuteString] = dateString.split(':');
    if (dayString === undefined || hourString === undefined || minuteString === undefined) {
        throw Error(`Error while reading dateString: ${dateString}`);
    }
    const dateInfo = {
        dayOfWeek: dayOfWeek[dayString],
        hour: Number(hourString),
        minute: Number(minuteString)
    };
    if (dateInfo.dayOfWeek === undefined) {
        throw Error(`Error while mapping ${dayString} to ${dayOfWeek}`);
    }
    let dateBeforeCurrent = moment()
        .utc()
        .locale('en')
        .hours(dateInfo.hour)
        .minutes(dateInfo.minute)
        .seconds(0)
        .millisecond(0)
        .day(dateInfo.dayOfWeek);
    if (dateBeforeCurrent.isAfter(dateCurrent)) {
        dateBeforeCurrent.subtract(7, 'days');
    }
    return {
        before: dateBeforeCurrent,
        after: moment(dateBeforeCurrent).add(7, 'days')
    };
}
function convertTradingHoursToInterval(startTime, endTime) {
    const dateStarts = getDatePossibilitiesFromDateInfo(startTime);
    const dateEnds = getDatePossibilitiesFromDateInfo(endTime);
    let result = {
        current: {
            start: dateStarts.before.toDate(),
            end: dateEnds.after.toDate()
        },
        next: {
            start: moment(dateStarts.before)
                .add(7, 'days')
                .toDate(),
            end: moment(dateEnds.after)
                .add(7, 'days')
                .toDate()
        }
    };
    // start and end are identical. trading in NO TIME :D
    // the calc date start before is before the date before end. so we have no active interval
    if (dateStarts.before.isSame(dateEnds.before) || dateStarts.before.isBefore(dateEnds.before)) {
        result = {
            current: undefined,
            next: {
                start: dateStarts.after.toDate(),
                end: dateEnds.after.toDate()
            }
        };
    }
    return result;
}
const getIntervalsFromTradingHours = (tradingHours) => {
    return tradingHours.map((tradingHour) => {
        return convertTradingHoursToInterval(tradingHour.startTime, tradingHour.endTime);
    });
};
export const sanitizeBaseInstrument = (data) => {
    const symbol = data.symbol;
    const category = data.category;
    const decimals = data.decimals;
    const contractSize = data.contractSize;
    const sizes = data.sizes;
    const stakeMode = StakeMode[data.stakeMode];
    const tradingIntervals = getIntervalsFromTradingHours(data.tradingHours);
    const currency = data.currency;
    const minOrderSize = Number(data.minOrderSize);
    const maxOrderSize = Number(data.maxOrderSize);
    const stepOrderSize = Number(data.stepOrderSize);
    const swapMode = data.swapMode;
    const swapLong = isNumeric(data.swapLong) ? Number(data.swapLong) : null;
    const swapShort = isNumeric(data.swapShort) ? Number(data.swapShort) : null;
    const iconUrl = data.iconUrl;
    const precision = Math.pow(10, (decimals * -1));
    return {
        symbol,
        category,
        precision,
        contractSize,
        sizes,
        stakeMode,
        decimals,
        tradingIntervals,
        currency,
        minOrderSize,
        maxOrderSize,
        stepOrderSize,
        swapMode,
        swapLong,
        swapShort,
        iconUrl
    };
};
export const addCurrentIntervalsToInstrument = (instrument) => {
    const intervals = getValidTradingIntervalForInstrument(instrument);
    return Object.assign(Object.assign({}, instrument), { parsedTradingIntervals: intervals });
};
export const getValidTradingIntervalForInstrument = (instrument) => {
    const { tradingIntervals } = instrument;
    return tradingIntervals.reduce(function (acc, tradingInterval) {
        if (tradingInterval.current !== undefined) {
            acc.current = tradingInterval.current;
        }
        if (acc.next === undefined || moment(acc.next.start).isAfter(tradingInterval.next.start)) {
            acc.next = tradingInterval.next;
        }
        return acc;
    }, {
        current: undefined,
        next: undefined
    });
};
export const isValidTradingDurationForInstrument = (instrument, duration) => {
    var _a, _b;
    const endTime = (_a = instrument.validTradingIntervals.current) === null || _a === void 0 ? void 0 : _a.end;
    const nextIntervalStartTime = (_b = instrument.validTradingIntervals.next) === null || _b === void 0 ? void 0 : _b.start;
    if (!endTime)
        return false;
    if (nextIntervalStartTime && endTime.getTime() === nextIntervalStartTime.getTime())
        return true;
    let timeUntilMarketCloses = endTime.getTime() - new Date().getTime();
    return timeUntilMarketCloses > duration * 1000;
};
