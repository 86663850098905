export function removeEmptyValuesFromObject(obj) {
    return Object.entries(obj)
        .filter(([_, v]) => v != null)
        .reduce((acc, [k, v]) => (Object.assign(Object.assign({}, acc), { [k]: v })), {});
}
export function isSet(object) {
    return object !== undefined && object !== null;
}
export function clamp(input, min, max) {
    return input < min ? min : input > max ? max : input;
}
// converts input number from one range to another
export function map(current, in_min, in_max, out_min, out_max) {
    const mapped = ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    return clamp(mapped, out_min, out_max);
}
export function isNumeric(input) {
    return (input != null) && (input !== '') && !isNaN(Number(input));
}
const createPenultimateDecimalModifier = (operation) => {
    return (input) => {
        if (!input) {
            return '';
        }
        // Convert the number to a string to manipulate decimal places
        const inputString = input.toString();
        const [integerPart, decimalPart] = inputString.split('.');
        const operator = operation === 'increment' ? 1 : -1;
        // If there is no decimal point or the number is an integer, return the original number + operator
        if (!decimalPart) {
            return (Number(input) + operator).toFixed();
        }
        // If the decimal part has only one digit, increase/reduce it by one and return the updated number
        if (decimalPart.length === 1) {
            return (Number(inputString) + 0.1 * operator).toFixed(decimalPart.length);
        }
        // Otherwise, remove the last decimal place, then increase/reduce the second to last by one and return the updated number
        const shortenedDecimals = decimalPart.slice(0, -1);
        let modifiedDecimalPart = Number(shortenedDecimals) + operator;
        if (operation === 'decrement' && modifiedDecimalPart < 0) {
            modifiedDecimalPart = 0;
        }
        const paddedModifiedDecimalPart = modifiedDecimalPart.toString().padStart(shortenedDecimals.length, '0');
        // Reconstruct the updated number string
        return Number(`${integerPart}.${paddedModifiedDecimalPart}`).toFixed(decimalPart.length);
    };
};
export const incrementPenultimateDecimal = createPenultimateDecimalModifier('increment');
export const decrementPenultimateDecimal = createPenultimateDecimalModifier('decrement');
