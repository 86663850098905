import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import styles from './PoorConnection.module.scss';
import _t from 'counterpart';
import Button from '../Button/Button';
import poorConnectionIcon from '../../icons/poor-connection.svg';
const PoorConnection = () => {
    const handleRetry = useCallback(() => {
        window.location.reload();
    }, []);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles.imageWrapper }, { children: _jsx("img", { className: styles.image, src: poorConnectionIcon, alt: "poor-connection" }) })), _jsxs("div", Object.assign({ className: styles.contentWrapper }, { children: [_jsx("h2", Object.assign({ className: styles.title }, { children: _t('poor_connection.title') })), _jsxs("p", Object.assign({ className: styles.description }, { children: [_t('poor_connection.description'), _jsx("br", {}), _t('main.please_try_again_later')] })), _jsx(Button, Object.assign({ className: styles.button, green: true, onClick: handleRetry }, { children: _t('main.retry') }))] }))] })));
};
export default React.memo(PoorConnection);
