import getFullConfigFromBrandConfig from './base';
let brandDistConfig;
try {
    brandDistConfig = require(`./${__BRAND__}`).default;
}
catch (e) {
    console.error(e);
    throw Error(`Could not load brand config: ./${__BRAND__}.ts`);
}
const baseBrandConfig = getFullConfigFromBrandConfig(brandDistConfig, 'dist');
const config = baseBrandConfig;
export default config;
