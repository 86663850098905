import { all, delay, race, take, takeLatest, put } from 'redux-saga/effects';
import { AUTO_LOGIN_REQUESTED, LOGIN_LOADER_HIDDEN, LOGIN_LOADER_SHOWN, LOGIN_REJECT, LOGIN_REQUESTED, LOGIN_SUCCESS, REFRESH_TOKEN_AND_LOGIN, REQUEST_OAUTH_TOKENS, setLoggingIn, setLoginTimedOut, unsetLoggingIn } from "../actions";
const LOGIN_LOADER_TIMEOUT = 10000;
function* handleLoginLoaderTiming() {
    const { hidden, timeout } = yield race({
        hidden: take(LOGIN_LOADER_HIDDEN),
        timeout: delay(LOGIN_LOADER_TIMEOUT),
    });
    if (!hidden) {
        yield put(setLoginTimedOut(true));
    }
}
function* setLoggingInSaga() {
    yield put(setLoggingIn());
}
function* unsetLoggingInSaga() {
    yield put(unsetLoggingIn());
}
function* eventsSaga() {
    yield all([
        takeLatest(LOGIN_LOADER_SHOWN, handleLoginLoaderTiming),
        takeLatest([LOGIN_REQUESTED, REFRESH_TOKEN_AND_LOGIN, AUTO_LOGIN_REQUESTED, REQUEST_OAUTH_TOKENS], setLoggingInSaga),
        takeLatest([LOGIN_SUCCESS, LOGIN_REJECT], unsetLoggingInSaga),
    ]);
}
export default eventsSaga;
