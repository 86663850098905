import { all, fork } from 'redux-saga/effects';
import quotesSaga from './quotesSaga';
import positionSaga from './positionSaga';
import historySaga from './historySaga';
import loginSaga from './loginSaga';
import tradingSaga from './tradingSaga';
import brokerSaga from './brokerSaga';
import accountHistorySaga from './accountHistorySaga';
import apiListenerSaga from './apiListenerSaga';
import instrumentsSaga from './instrumentsSaga';
import errorSaga from './errorSaga';
import connectionSaga from './connectionSaga';
import leaderboardSaga from './leaderboardSaga';
import continuousSaga from './continuousSaga';
import eventsSaga from './eventsSaga';
import timeoutSaga from './timeoutSaga';
import candlesSaga from "./candlesSaga";
function* rootSaga() {
    yield all([
        fork(quotesSaga),
        fork(positionSaga),
        fork(historySaga),
        fork(candlesSaga),
        fork(loginSaga),
        fork(tradingSaga),
        fork(brokerSaga),
        fork(accountHistorySaga),
        fork(instrumentsSaga),
        fork(errorSaga),
        fork(connectionSaga),
        fork(leaderboardSaga),
        fork(continuousSaga),
        fork(eventsSaga),
        fork(timeoutSaga),
        fork(apiListenerSaga),
    ]);
}
export default rootSaga;
