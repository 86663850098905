import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { FETCH_COINS_EXCHANGE_RATE_REQUESTED, FETCH_MESSAGES_NEXT_PAGE_REQUESTED, FETCH_MESSAGES_REQUESTED, UPDATE_USER_REQUESTED, FETCH_MESSAGES_UNREAD_COUNT_REQUESTED, USER_SAVE_LANGUAGE, FETCH_ACTIVE_BANNER_REQUESTED, fetchCoinsExchangeRateReject, fetchCoinsExchangeRateSuccess, fetchMessagesNextPageReject, fetchMessagesNextPageSuccess, fetchMessagesReject, fetchMessagesSuccess, updateUserReject, updateUserSuccess, fetchMessagesUnreadCountSuccess, fetchMessagesUnreadCountReject, updateUser as updateUserAction, fetchActiveBannerReject, fetchActiveBannerSuccess, setGreyLabelLogo, displayError, FETCH_GREY_LABEL_LOGO_REQUESTED, handleGreyLabelLogoError, } from '../actions';
import { getActiveBanner, getCoinsExchangeRate, getGreyLabelLogo, getMessages, getUnreadMessagesCount, updateUser } from '../services/crmService';
import { selectors } from '../index';
function* updateUserSaga(action) {
    const { standalone, communicationLanguage } = action.payload;
    try {
        const { user } = yield call(updateUser, { standalone, communicationLanguage });
        yield put(updateUserSuccess(user.communicationLanguage));
    }
    catch (e) {
        yield put(updateUserReject(e.message));
    }
}
function* fetchGreyLabelLogo() {
    try {
        const { logos } = yield call(getGreyLabelLogo);
        yield put(setGreyLabelLogo(logos));
    }
    catch (e) {
        yield put(handleGreyLabelLogoError());
        yield put(displayError("Failed fetching affiliate logo", e.message));
    }
}
function* updateUserLanguageSaga(action) {
    const { language, skipUserUpdate } = action.payload;
    const supportsUserUpdate = yield select(selectors.supportsUserUpdateSelector);
    if (supportsUserUpdate && !skipUserUpdate) {
        yield put(updateUserAction({ communicationLanguage: language }));
    }
}
function* fetchExchangeRate() {
    try {
        const { effectiveRate, rate, starsBonus } = yield call(getCoinsExchangeRate);
        yield put(fetchCoinsExchangeRateSuccess(rate, effectiveRate, starsBonus));
    }
    catch (e) {
        console.error(e);
        yield put(fetchCoinsExchangeRateReject(e.message));
    }
}
function* fetchMessagesSaga() {
    try {
        const { messages, pagination } = yield call(getMessages);
        yield put(fetchMessagesSuccess(messages, pagination));
    }
    catch (e) {
        console.error(e);
        yield put(fetchMessagesReject(e.message));
    }
}
function* fetchMessagesNextPageSaga() {
    try {
        const { offset, limit } = yield select(state => state.broker.messages.pagination);
        const { messages: newMessages, pagination } = yield call(getMessages, offset + limit);
        yield put(fetchMessagesNextPageSuccess(newMessages, pagination));
    }
    catch (e) {
        console.error(e);
        yield put(fetchMessagesNextPageReject(e.message));
    }
}
function* fetchMessagesUnreadCountSaga() {
    try {
        const { unreadCount } = yield call(getUnreadMessagesCount);
        yield put(fetchMessagesUnreadCountSuccess(unreadCount));
    }
    catch (e) {
        console.error(e);
        yield put(fetchMessagesUnreadCountReject(e.message));
    }
}
function* fetchActiveBannerSaga() {
    try {
        const activeBanner = yield call(getActiveBanner);
        yield put(fetchActiveBannerSuccess(activeBanner));
    }
    catch (e) {
        console.error(e);
        yield put(fetchActiveBannerReject(e.message));
    }
}
function* brokerSaga() {
    yield all([
        takeLatest(UPDATE_USER_REQUESTED, updateUserSaga),
        takeLatest(FETCH_COINS_EXCHANGE_RATE_REQUESTED, fetchExchangeRate),
        takeLatest(FETCH_MESSAGES_REQUESTED, fetchMessagesSaga),
        takeEvery(FETCH_MESSAGES_NEXT_PAGE_REQUESTED, fetchMessagesNextPageSaga),
        takeLatest(FETCH_MESSAGES_UNREAD_COUNT_REQUESTED, fetchMessagesUnreadCountSaga),
        takeLatest(USER_SAVE_LANGUAGE, updateUserLanguageSaga),
        takeLatest(FETCH_ACTIVE_BANNER_REQUESTED, fetchActiveBannerSaga),
        takeLatest(FETCH_GREY_LABEL_LOGO_REQUESTED, fetchGreyLabelLogo)
    ]);
}
export default brokerSaga;
