import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './FormError.module.scss';
import _t from 'counterpart';
import classNames from 'classnames';
import { isSet } from '../../helpers';
const FormError = ({ error, margin = 'none' }) => {
    const translatedError = useMemo(() => {
        if (!isSet(error)) {
            return null;
        }
        return _t.translate(`error.${error.toLowerCase()}`, {
            fallback: error,
        });
    }, [error]);
    if (!isSet(error)) {
        return null;
    }
    return (_jsx("span", Object.assign({ className: classNames(styles.formError, {
            [styles.formErrorMarginTop]: margin === 'top',
            [styles.formErrorMarginBottom]: margin === 'bottom',
        }) }, { children: translatedError })));
};
export default React.memo(FormError);
