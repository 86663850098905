import { OPEN_POSITIONS_LIST_UPDATE, POSITION_ADDED, POSITION_CLOSED, POSITION_CLOSING_REVERT, POSITION_OPENED, POSITION_UPDATED, POSITIONS_CLEAR_ACTIVE, POSITIONS_CLOSE_ALL_FINISHED, POSITIONS_CLOSE_ALL_REQUESTED, POSITIONS_CLOSE_SINGLE_FINISHED, POSITIONS_CLOSE_SINGLE_REQUESTED, POSITIONS_SET_ACTIVE } from "../../actions";
import { PositionStatusCode } from "./types";
export default function positionReducer(state, action) {
    var _a, _b;
    switch (action.type) {
        case POSITION_UPDATED: {
            return Object.assign(Object.assign({}, state), { positions: Object.assign(Object.assign({}, state.positions), { [action.payload.position.positionId]: action.payload.position }) });
        }
        case POSITION_ADDED: {
            const position = action.payload.position;
            return Object.assign(Object.assign({}, state), { positions: Object.assign(Object.assign({}, state.positions), { [position.positionId]: position }) });
        }
        case POSITION_OPENED:
            return Object.assign(Object.assign({}, state), { positions: Object.assign(Object.assign({}, state.positions), { [action.payload.position.positionId]: action.payload.position }) });
        case POSITIONS_SET_ACTIVE: {
            const activePosition = state.positions[action.payload];
            if (!activePosition) {
                throw new Error(`Position with id ${action.payload} does not exist`);
            }
            return Object.assign(Object.assign({}, state), { activePositionId: action.payload, activePosition });
        }
        case POSITIONS_CLEAR_ACTIVE: {
            return Object.assign(Object.assign({}, state), { activePositionId: null, activePosition: null });
        }
        case POSITION_CLOSED: {
            const closedPosition = state.positions[action.payload.positionId];
            if (!closedPosition) {
                return state;
            }
            // Closing all positions
            if (state.closingAllPositions) {
                const newTotalPL = state.closedPositionsTotalPL + ((_a = closedPosition.profitLoss) !== null && _a !== void 0 ? _a : 0);
                const newPendingCloses = state.pendingPositionCloses - 1;
                return Object.assign(Object.assign({}, state), { closingAllPositions: true, pendingPositionCloses: newPendingCloses, closedPositionsTotalPL: newTotalPL, showCloseAllProfitLoss: newPendingCloses < 1 });
                // Closing single position
            }
            else {
                let newState = Object.assign(Object.assign({}, state), { closedPositionsTotalPL: (_b = closedPosition.profitLoss) !== null && _b !== void 0 ? _b : 0, closingSinglePosition: true, pendingPositionCloses: 0 });
                // in case position was closed due to time duration expired and we have not gone through
                // the position close requested step
                if (!state.closingSinglePosition && action.payload.positionId === state.activePositionId) {
                    newState = Object.assign(Object.assign({}, newState), { closingSinglePosition: true, pendingPositionCloses: 0, showSinglePositionProfitLoss: action.payload.positionId === state.activePositionId, closingPositionsIds: [state.activePositionId] });
                }
                return newState;
            }
        }
        case POSITIONS_CLOSE_ALL_REQUESTED: {
            const openPositions = Object.values(state.positions)
                .filter(p => !!p && p.status === PositionStatusCode.OPEN);
            const closingPositionsIds = openPositions.map(position => position.positionId);
            return Object.assign(Object.assign({}, state), { closingAllPositions: true, pendingPositionCloses: openPositions.length, closingPositionsIds, closedPositionsTotalPL: 0, activePosition: null, activePositionId: null });
        }
        case POSITIONS_CLOSE_ALL_FINISHED: {
            return Object.assign(Object.assign({}, state), { closingAllPositions: false, closingPositionsIds: [], pendingPositionCloses: 0, closedPositionsTotalPL: 0, showCloseAllProfitLoss: false, user: Object.assign(Object.assign({}, state.user), { balanceAdjustmentFromPL: 0 }) });
        }
        case POSITIONS_CLOSE_SINGLE_REQUESTED: {
            return Object.assign(Object.assign({}, state), { pendingPositionCloses: 1, closingPositionsIds: [action.payload] });
        }
        case POSITION_CLOSING_REVERT: {
            return Object.assign(Object.assign({}, state), { pendingPositionCloses: state.pendingPositionCloses - 1, closingPositionsIds: state.closingPositionsIds.filter((item) => item !== action.payload.positionId) });
        }
        case POSITIONS_CLOSE_SINGLE_FINISHED: {
            return Object.assign(Object.assign({}, state), { showSinglePositionProfitLoss: false, pendingPositionCloses: 0, closingSinglePosition: false, user: Object.assign(Object.assign({}, state.user), { balanceAdjustmentFromPL: 0 }), closingPositionsIds: [] });
        }
        case OPEN_POSITIONS_LIST_UPDATE: {
            return Object.assign(Object.assign({}, state), { positions: Object.assign(Object.assign({}, state.positions), action.payload.openPositions) });
        }
        default:
            return state;
    }
}
