/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.PositionStatus = (function() {

    /**
     * Properties of a PositionStatus.
     * @exports IPositionStatus
     * @interface IPositionStatus
     * @property {number|Long} positionId PositionStatus positionId
     * @property {number|null} [profitLoss] PositionStatus profitLoss
     * @property {string|null} [status] PositionStatus status
     * @property {number|null} [openPrice] PositionStatus openPrice
     * @property {number|null} [commission] PositionStatus commission
     * @property {number|null} [margin] PositionStatus margin
     * @property {number|null} [swap] PositionStatus swap
     * @property {string|null} [stopLoss] PositionStatus stopLoss
     * @property {string|null} [takeProfit] PositionStatus takeProfit
     * @property {string|null} [reason] PositionStatus reason
     */

    /**
     * Constructs a new PositionStatus.
     * @exports PositionStatus
     * @classdesc Represents a PositionStatus.
     * @implements IPositionStatus
     * @constructor
     * @param {IPositionStatus=} [properties] Properties to set
     */
    function PositionStatus(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PositionStatus positionId.
     * @member {number|Long} positionId
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.positionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * PositionStatus profitLoss.
     * @member {number|null|undefined} profitLoss
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.profitLoss = null;

    /**
     * PositionStatus status.
     * @member {string|null|undefined} status
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.status = null;

    /**
     * PositionStatus openPrice.
     * @member {number|null|undefined} openPrice
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.openPrice = null;

    /**
     * PositionStatus commission.
     * @member {number|null|undefined} commission
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.commission = null;

    /**
     * PositionStatus margin.
     * @member {number|null|undefined} margin
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.margin = null;

    /**
     * PositionStatus swap.
     * @member {number|null|undefined} swap
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.swap = null;

    /**
     * PositionStatus stopLoss.
     * @member {string|null|undefined} stopLoss
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.stopLoss = null;

    /**
     * PositionStatus takeProfit.
     * @member {string|null|undefined} takeProfit
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.takeProfit = null;

    /**
     * PositionStatus reason.
     * @member {string|null|undefined} reason
     * @memberof PositionStatus
     * @instance
     */
    PositionStatus.prototype.reason = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * PositionStatus _profitLoss.
     * @member {"profitLoss"|undefined} _profitLoss
     * @memberof PositionStatus
     * @instance
     */
    Object.defineProperty(PositionStatus.prototype, "_profitLoss", {
        get: $util.oneOfGetter($oneOfFields = ["profitLoss"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * PositionStatus _status.
     * @member {"status"|undefined} _status
     * @memberof PositionStatus
     * @instance
     */
    Object.defineProperty(PositionStatus.prototype, "_status", {
        get: $util.oneOfGetter($oneOfFields = ["status"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * PositionStatus _openPrice.
     * @member {"openPrice"|undefined} _openPrice
     * @memberof PositionStatus
     * @instance
     */
    Object.defineProperty(PositionStatus.prototype, "_openPrice", {
        get: $util.oneOfGetter($oneOfFields = ["openPrice"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * PositionStatus _commission.
     * @member {"commission"|undefined} _commission
     * @memberof PositionStatus
     * @instance
     */
    Object.defineProperty(PositionStatus.prototype, "_commission", {
        get: $util.oneOfGetter($oneOfFields = ["commission"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * PositionStatus _margin.
     * @member {"margin"|undefined} _margin
     * @memberof PositionStatus
     * @instance
     */
    Object.defineProperty(PositionStatus.prototype, "_margin", {
        get: $util.oneOfGetter($oneOfFields = ["margin"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * PositionStatus _swap.
     * @member {"swap"|undefined} _swap
     * @memberof PositionStatus
     * @instance
     */
    Object.defineProperty(PositionStatus.prototype, "_swap", {
        get: $util.oneOfGetter($oneOfFields = ["swap"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * PositionStatus _stopLoss.
     * @member {"stopLoss"|undefined} _stopLoss
     * @memberof PositionStatus
     * @instance
     */
    Object.defineProperty(PositionStatus.prototype, "_stopLoss", {
        get: $util.oneOfGetter($oneOfFields = ["stopLoss"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * PositionStatus _takeProfit.
     * @member {"takeProfit"|undefined} _takeProfit
     * @memberof PositionStatus
     * @instance
     */
    Object.defineProperty(PositionStatus.prototype, "_takeProfit", {
        get: $util.oneOfGetter($oneOfFields = ["takeProfit"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * PositionStatus _reason.
     * @member {"reason"|undefined} _reason
     * @memberof PositionStatus
     * @instance
     */
    Object.defineProperty(PositionStatus.prototype, "_reason", {
        get: $util.oneOfGetter($oneOfFields = ["reason"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new PositionStatus instance using the specified properties.
     * @function create
     * @memberof PositionStatus
     * @static
     * @param {IPositionStatus=} [properties] Properties to set
     * @returns {PositionStatus} PositionStatus instance
     */
    PositionStatus.create = function create(properties) {
        return new PositionStatus(properties);
    };

    /**
     * Encodes the specified PositionStatus message. Does not implicitly {@link PositionStatus.verify|verify} messages.
     * @function encode
     * @memberof PositionStatus
     * @static
     * @param {IPositionStatus} message PositionStatus message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PositionStatus.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.positionId);
        if (message.profitLoss != null && Object.hasOwnProperty.call(message, "profitLoss"))
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.profitLoss);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
        if (message.openPrice != null && Object.hasOwnProperty.call(message, "openPrice"))
            writer.uint32(/* id 4, wireType 1 =*/33).double(message.openPrice);
        if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
            writer.uint32(/* id 5, wireType 1 =*/41).double(message.commission);
        if (message.margin != null && Object.hasOwnProperty.call(message, "margin"))
            writer.uint32(/* id 6, wireType 1 =*/49).double(message.margin);
        if (message.swap != null && Object.hasOwnProperty.call(message, "swap"))
            writer.uint32(/* id 7, wireType 1 =*/57).double(message.swap);
        if (message.stopLoss != null && Object.hasOwnProperty.call(message, "stopLoss"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.stopLoss);
        if (message.takeProfit != null && Object.hasOwnProperty.call(message, "takeProfit"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.takeProfit);
        if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
            writer.uint32(/* id 20, wireType 2 =*/162).string(message.reason);
        return writer;
    };

    /**
     * Encodes the specified PositionStatus message, length delimited. Does not implicitly {@link PositionStatus.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PositionStatus
     * @static
     * @param {IPositionStatus} message PositionStatus message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PositionStatus.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PositionStatus message from the specified reader or buffer.
     * @function decode
     * @memberof PositionStatus
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PositionStatus} PositionStatus
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PositionStatus.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PositionStatus();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.positionId = reader.int64();
                    break;
                }
            case 2: {
                    message.profitLoss = reader.double();
                    break;
                }
            case 3: {
                    message.status = reader.string();
                    break;
                }
            case 4: {
                    message.openPrice = reader.double();
                    break;
                }
            case 5: {
                    message.commission = reader.double();
                    break;
                }
            case 6: {
                    message.margin = reader.double();
                    break;
                }
            case 7: {
                    message.swap = reader.double();
                    break;
                }
            case 8: {
                    message.stopLoss = reader.string();
                    break;
                }
            case 9: {
                    message.takeProfit = reader.string();
                    break;
                }
            case 20: {
                    message.reason = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("positionId"))
            throw $util.ProtocolError("missing required 'positionId'", { instance: message });
        return message;
    };

    /**
     * Decodes a PositionStatus message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PositionStatus
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PositionStatus} PositionStatus
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PositionStatus.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PositionStatus message.
     * @function verify
     * @memberof PositionStatus
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PositionStatus.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        var properties = {};
        if (!$util.isInteger(message.positionId) && !(message.positionId && $util.isInteger(message.positionId.low) && $util.isInteger(message.positionId.high)))
            return "positionId: integer|Long expected";
        if (message.profitLoss != null && message.hasOwnProperty("profitLoss")) {
            properties._profitLoss = 1;
            if (typeof message.profitLoss !== "number")
                return "profitLoss: number expected";
        }
        if (message.status != null && message.hasOwnProperty("status")) {
            properties._status = 1;
            if (!$util.isString(message.status))
                return "status: string expected";
        }
        if (message.openPrice != null && message.hasOwnProperty("openPrice")) {
            properties._openPrice = 1;
            if (typeof message.openPrice !== "number")
                return "openPrice: number expected";
        }
        if (message.commission != null && message.hasOwnProperty("commission")) {
            properties._commission = 1;
            if (typeof message.commission !== "number")
                return "commission: number expected";
        }
        if (message.margin != null && message.hasOwnProperty("margin")) {
            properties._margin = 1;
            if (typeof message.margin !== "number")
                return "margin: number expected";
        }
        if (message.swap != null && message.hasOwnProperty("swap")) {
            properties._swap = 1;
            if (typeof message.swap !== "number")
                return "swap: number expected";
        }
        if (message.stopLoss != null && message.hasOwnProperty("stopLoss")) {
            properties._stopLoss = 1;
            if (!$util.isString(message.stopLoss))
                return "stopLoss: string expected";
        }
        if (message.takeProfit != null && message.hasOwnProperty("takeProfit")) {
            properties._takeProfit = 1;
            if (!$util.isString(message.takeProfit))
                return "takeProfit: string expected";
        }
        if (message.reason != null && message.hasOwnProperty("reason")) {
            properties._reason = 1;
            if (!$util.isString(message.reason))
                return "reason: string expected";
        }
        return null;
    };

    /**
     * Creates a PositionStatus message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PositionStatus
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PositionStatus} PositionStatus
     */
    PositionStatus.fromObject = function fromObject(object) {
        if (object instanceof $root.PositionStatus)
            return object;
        var message = new $root.PositionStatus();
        if (object.positionId != null)
            if ($util.Long)
                (message.positionId = $util.Long.fromValue(object.positionId)).unsigned = false;
            else if (typeof object.positionId === "string")
                message.positionId = parseInt(object.positionId, 10);
            else if (typeof object.positionId === "number")
                message.positionId = object.positionId;
            else if (typeof object.positionId === "object")
                message.positionId = new $util.LongBits(object.positionId.low >>> 0, object.positionId.high >>> 0).toNumber();
        if (object.profitLoss != null)
            message.profitLoss = Number(object.profitLoss);
        if (object.status != null)
            message.status = String(object.status);
        if (object.openPrice != null)
            message.openPrice = Number(object.openPrice);
        if (object.commission != null)
            message.commission = Number(object.commission);
        if (object.margin != null)
            message.margin = Number(object.margin);
        if (object.swap != null)
            message.swap = Number(object.swap);
        if (object.stopLoss != null)
            message.stopLoss = String(object.stopLoss);
        if (object.takeProfit != null)
            message.takeProfit = String(object.takeProfit);
        if (object.reason != null)
            message.reason = String(object.reason);
        return message;
    };

    /**
     * Creates a plain object from a PositionStatus message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PositionStatus
     * @static
     * @param {PositionStatus} message PositionStatus
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PositionStatus.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.positionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.positionId = options.longs === String ? "0" : 0;
        if (message.positionId != null && message.hasOwnProperty("positionId"))
            if (typeof message.positionId === "number")
                object.positionId = options.longs === String ? String(message.positionId) : message.positionId;
            else
                object.positionId = options.longs === String ? $util.Long.prototype.toString.call(message.positionId) : options.longs === Number ? new $util.LongBits(message.positionId.low >>> 0, message.positionId.high >>> 0).toNumber() : message.positionId;
        if (message.profitLoss != null && message.hasOwnProperty("profitLoss")) {
            object.profitLoss = options.json && !isFinite(message.profitLoss) ? String(message.profitLoss) : message.profitLoss;
            if (options.oneofs)
                object._profitLoss = "profitLoss";
        }
        if (message.status != null && message.hasOwnProperty("status")) {
            object.status = message.status;
            if (options.oneofs)
                object._status = "status";
        }
        if (message.openPrice != null && message.hasOwnProperty("openPrice")) {
            object.openPrice = options.json && !isFinite(message.openPrice) ? String(message.openPrice) : message.openPrice;
            if (options.oneofs)
                object._openPrice = "openPrice";
        }
        if (message.commission != null && message.hasOwnProperty("commission")) {
            object.commission = options.json && !isFinite(message.commission) ? String(message.commission) : message.commission;
            if (options.oneofs)
                object._commission = "commission";
        }
        if (message.margin != null && message.hasOwnProperty("margin")) {
            object.margin = options.json && !isFinite(message.margin) ? String(message.margin) : message.margin;
            if (options.oneofs)
                object._margin = "margin";
        }
        if (message.swap != null && message.hasOwnProperty("swap")) {
            object.swap = options.json && !isFinite(message.swap) ? String(message.swap) : message.swap;
            if (options.oneofs)
                object._swap = "swap";
        }
        if (message.stopLoss != null && message.hasOwnProperty("stopLoss")) {
            object.stopLoss = message.stopLoss;
            if (options.oneofs)
                object._stopLoss = "stopLoss";
        }
        if (message.takeProfit != null && message.hasOwnProperty("takeProfit")) {
            object.takeProfit = message.takeProfit;
            if (options.oneofs)
                object._takeProfit = "takeProfit";
        }
        if (message.reason != null && message.hasOwnProperty("reason")) {
            object.reason = message.reason;
            if (options.oneofs)
                object._reason = "reason";
        }
        return object;
    };

    /**
     * Converts this PositionStatus to JSON.
     * @function toJSON
     * @memberof PositionStatus
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PositionStatus.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PositionStatus
     * @function getTypeUrl
     * @memberof PositionStatus
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PositionStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PositionStatus";
    };

    return PositionStatus;
})();

module.exports = $root;
