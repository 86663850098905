var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './Select.module.scss';
import * as SelectPrimitive from '@radix-ui/react-select';
import { isSet } from '../../helpers';
import SelectValue from 'components/Select/SelectValue';
import classNames from 'classnames';
import { FaChevronDown } from "react-icons/fa";
const Select = React.forwardRef((_a, forwardedRef) => {
    var { children, trigger } = _a, props = __rest(_a, ["children", "trigger"]);
    return (_jsxs(SelectPrimitive.Root, Object.assign({}, props, { children: [_jsxs(SelectPrimitive.Trigger, Object.assign({ ref: forwardedRef, asChild: isSet(trigger), className: classNames({
                    [styles.trigger]: !isSet(trigger),
                }) }, { children: [isSet(trigger) ? trigger : _jsx(SelectValue, { style: { flex: 1 } }), !isSet(trigger) && (_jsx(_Fragment, { children: _jsx(SelectPrimitive.Icon, Object.assign({ className: styles.dropdownIcon }, { children: _jsx(FaChevronDown, { size: 12 }) })) }))] })), _jsx(SelectPrimitive.Portal, { children: _jsxs(SelectPrimitive.Content, Object.assign({ className: styles.content, position: "popper" }, { children: [_jsx(SelectPrimitive.Arrow, { className: styles.arrow }), _jsx(SelectPrimitive.Viewport, Object.assign({ className: styles.viewport }, { children: children }))] })) })] })));
});
export default React.memo(Select);
