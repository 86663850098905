import { all, takeLatest, put } from 'redux-saga/effects';
import { ACCOUNT_HISTORY_REQUEST, requestGetAccountHistory } from '../actions';
function* requestAccountHistory() {
    yield put(requestGetAccountHistory());
}
function* accountHistorySaga() {
    yield all([
        takeLatest(ACCOUNT_HISTORY_REQUEST, requestAccountHistory),
    ]);
}
export default accountHistorySaga;
