import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { MobilePageHeader } from './MobilePageHeader';
import { PageTitle } from './PageTitle';
import { PageSubtitle } from './PageSubtitle';
import classNames from 'classnames';
import GenericErrorComponent from './GenericErrorComponent';
import { BackButton } from '../BackButton';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import Loader from 'components/Loader/Loader';
export const PageLayout = ({ children, title, subtitle, className = '', containerClassName, wrapperClassName, loading = false, error, showDesktopBackButton = false, actionItem, isEmbeddedCashier = false, }) => {
    const handleRenderContent = useMemo(() => {
        if (loading) {
            return _jsx(Loader, { transparentBackground: true });
        }
        if (error) {
            return _jsx(GenericErrorComponent, { error: error.toString() });
        }
        return children;
    }, [loading, error, children]);
    return (_jsx("div", Object.assign({ className: "main_content" }, { children: _jsxs("div", Object.assign({ className: classNames('page-container', {
                'page-container--embedded-cashier': isEmbeddedCashier,
                'page-container--with-mobile-action-item': Boolean(actionItem),
            }, className) }, { children: [_jsx(MobilePageHeader, { isEmbeddedCashier: isEmbeddedCashier }), _jsxs("div", Object.assign({ className: classNames('container', containerClassName) }, { children: [_jsxs("div", Object.assign({ className: "title-bar" }, { children: [_jsx(Desktop, { children: _jsx("div", Object.assign({ className: "back-button-wrapper" }, { children: showDesktopBackButton && _jsx(BackButton, { useRouterBack: true }) })) }), _jsxs("div", Object.assign({ className: "title-subtitle-wrapper" }, { children: [Boolean(title) && _jsx(PageTitle, { children: title }), Boolean(subtitle) && _jsx(PageSubtitle, { children: subtitle })] })), _jsx(Desktop, { children: _jsx("div", Object.assign({ className: "title-append-wrapper" }, { children: Boolean(actionItem) && actionItem })) })] })), _jsx("div", Object.assign({ className: classNames('content-wrapper', wrapperClassName) }, { children: handleRenderContent })), Boolean(actionItem) && (_jsx(Mobile, { children: _jsx("div", Object.assign({ className: "mobile-action-item" }, { children: actionItem })) }))] }))] })) })));
};
