import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './SettingsScreen.module.scss';
import Card from 'components/Card/Card';
import _t from 'counterpart';
import LanguageSelect from 'components/LanguageSelect/LanguageSelect';
import { releaseVersion, webpackHash } from '../../helpers';
import config from 'config';
import { NavLink } from 'react-router-dom';
import AnyLink from 'components/AnyLink/AnyLink';
import { wintradoHomeUrl } from '../../constants';
import Spacer from 'components/Spacer/Spacer';
const SettingsScreen = () => {
    var _a;
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs(Card, Object.assign({ title: _t.translate('main.settings') }, { children: [_jsx(LanguageSelect, {}), _jsx(Spacer, { y: 'small' }), Boolean((_a = config.cashier) === null || _a === void 0 ? void 0 : _a.profileURL) && (_jsxs("div", Object.assign({ className: styles.userProfileMessage }, { children: [_jsxs("span", { children: [_t('main.looking_for_profile_page'), " \u00A0"] }), _jsx(NavLink, Object.assign({ to: "/settings/profile" }, { children: _jsx("span", { children: _t('main.profile_page') }) }))] })))] })), _jsxs("div", Object.assign({ className: styles.poweredBy }, { children: [_jsxs("span", { children: [_t('main.powered_by_the'), " \u00A0"] }), _jsx(AnyLink, Object.assign({ to: wintradoHomeUrl, target: '_blank' }, { children: "Wintrado" })), _jsxs("span", { children: ["\u00A0 ", _t('main.trading_platform')] })] })), _jsx("div", Object.assign({ className: styles.versionContainer }, { children: _jsxs("span", { children: [_jsx("span", { children: `v${releaseVersion()}` }), _jsx("span", { children: webpackHash() })] }) }))] })));
};
export default React.memo(SettingsScreen);
