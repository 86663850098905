import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './MobileBalanceBar.module.scss';
import BalanceStat from 'components/BalanceStat/BalanceStat';
import CoinsStat from 'components/CoinsStat/CoinsStat';
import BalanceBar from 'components/BalanceBar/BalanceBar';
const MobileBalanceBar = () => {
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.stats }, { children: [_jsx(BalanceStat, { flexible: true, dark: true }), _jsx(CoinsStat, { flexible: true, dark: true })] })), _jsx(BalanceBar, {})] })));
};
export default React.memo(MobileBalanceBar);
