import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './Badge.module.scss';
import classNames from 'classnames';
const Badge = ({ value }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.container, {
            [styles.containerPadded]: value.toString().length > 1,
        }) }, { children: value })));
};
export default React.memo(Badge);
