import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useCallback } from 'react';
import Toast from 'components/Toast/Toast';
const ToastContext = createContext(() => {
    throw Error('ToastContext not initialized');
});
export default ToastContext;
export const ToastContextProvider = ({ children }) => {
    const [toasts, setToasts] = React.useState([]);
    const addToast = useCallback((toast) => {
        setToasts((prevToasts) => [
            ...prevToasts,
            Object.assign({ id: new Date().getTime() }, toast),
        ]);
    }, []);
    const handleToastOpenChange = useCallback((open, id) => {
        if (!open) {
            setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
        }
    }, []);
    return (_jsxs(ToastContext.Provider, Object.assign({ value: addToast }, { children: [children, toasts.map((toast) => (_jsx(Toast, { id: toast.id, defaultOpen: true, title: toast.title, description: toast.description, variant: toast.variant, onOpenChange: handleToastOpenChange }, toast.id)))] })));
};
