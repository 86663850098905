let notificationContainer = null;
let notificationQueue = [];

export default class PopupNotificationManager {
	static registerContainer(container) {
		notificationContainer = container;
		this.showQueuedNotificationsIfExist();
	}

	static unregisterContainer() {
		notificationContainer = null;
	}

	static showNotification(notification) {
		if (notificationContainer) {
			notificationContainer.showNotification(notification);
		} else {
			notificationQueue.push(notification);
		}
	}

	static showQueuedNotificationsIfExist() {
		if (notificationQueue.length > 0) {
			notificationQueue.forEach((notification) => {
				notificationContainer.showNotification(notification);
			});
			notificationQueue = [];
		}
	}

	static hideNotification(notificationId) {
		if (notificationContainer) {
			notificationContainer.hideNotification(notificationId);
		}
	}
}
