import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './AnimatedSwitcher.module.scss';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
const AnimatedSwitcher = ({ children, className, activeIndex, onEnter }) => {
    const styledChild = useMemo(() => React.cloneElement(children[activeIndex], {
        className: `${children[activeIndex].props.className} ${styles.page}`,
    }), [activeIndex, children]);
    return (_jsx("div", Object.assign({ className: classNames(styles.container, className) }, { children: _jsx(TransitionGroup, Object.assign({ component: null }, { children: _jsx(CSSTransition, Object.assign({ timeout: 450, classNames: activeIndex === 0 ? 'reverse-page-slide' : 'page-slide', onEnter: onEnter, unmountOnExit: true }, { children: styledChild }), activeIndex) })) })));
};
export default React.memo(AnimatedSwitcher);
