let alertContainer = null;
export default class PopupAlertManager {
    static registerContainer(container) {
        alertContainer = container;
    }
    static unregisterContainer() {
        alertContainer = null;
    }
    static showAlert(alert) {
        if (alertContainer) {
            alertContainer.showAlert(alert);
        }
    }
    static hideAlert() {
        if (alertContainer) {
            alertContainer.hideAlert();
        }
    }
}
