var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import styles from './ResizableHandle.module.scss';
import classNames from 'classnames';
const ResizableHandle = forwardRef((_a, ref) => {
    var { resizeHandle, hidden = false } = _a, rest = __rest(_a, ["resizeHandle", "hidden"]);
    return (_jsx("div", Object.assign({ ref: ref, className: classNames(styles.resizeHandle, {
            [styles.resizeHandleHidden]: hidden,
            [styles.resizeHandleLeft]: resizeHandle === 'w',
            [styles.resizeHandleRight]: resizeHandle === 'e',
            [styles.resizeHandleTop]: resizeHandle === 'n',
            [styles.resizeHandleBottom]: resizeHandle === 's',
        }) }, rest)));
});
export default React.memo(ResizableHandle);
