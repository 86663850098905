var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './Button.module.scss';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import classNames from 'classnames';
import { isSet } from '../../helpers';
import Loader from 'components/Loader/Loader';
const Button = React.forwardRef((_a, ref) => {
    var { className, children, to, onClick, loading = false, variant, condensed = 'none', flexible = false, endIcon, alignStart = false, textSize = 'normal', disabled } = _a, props = __rest(_a, ["className", "children", "to", "onClick", "loading", "variant", "condensed", "flexible", "endIcon", "alignStart", "textSize", "disabled"]);
    const renderEndIcon = useMemo(() => {
        if (!isSet(endIcon)) {
            return null;
        }
        return _jsx("div", Object.assign({ className: styles.endIconWrapper }, { children: endIcon }));
    }, [endIcon]);
    return (_jsx(ButtonBase, Object.assign({ className: classNames(styles.button, className, {
            [styles.buttonPrimary]: variant === 'primary',
            [styles.buttonSecondary]: variant === 'secondary',
            [styles.buttonText]: variant === 'text',
            [styles.buttonDanger]: variant === 'danger',
            [styles.buttonCondensedAll]: condensed === 'all',
            [styles.buttonCondensedHorizontal]: condensed === 'horizontal',
            [styles.buttonFlexible]: flexible,
            [styles.buttonWithEndIcon]: isSet(endIcon),
            [styles.buttonAlignStart]: alignStart,
            [styles.buttonTextSizeSmall]: textSize === 'small',
            [styles.buttonDisabled]: disabled === true,
        }), to: to, onClick: onClick, ref: ref, loading: loading, disabled: disabled }, props, { children: loading ? (_jsx(Loader, { inline: true })) : (_jsxs(_Fragment, { children: [children, renderEndIcon] })) })));
});
export default React.memo(Button);
