import { useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useRef } from 'react';
import * as queryString from 'query-string';
import ToastContext from 'components/Toast/ToastContext';
export const useQueryParams = () => {
    const { search } = useLocation();
    return React.useMemo(() => queryString.parse(search), [search]);
};
export const useToastContext = () => {
    return useContext(ToastContext);
};
export const useForwardRef = (ref, initialValue = null) => {
    const targetRef = useRef(initialValue);
    useEffect(() => {
        if (!ref)
            return;
        if (typeof ref === 'function') {
            ref(targetRef.current);
        }
        else {
            ref.current = targetRef.current;
        }
    }, [ref]);
    return targetRef;
};
