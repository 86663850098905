import { put, select, takeEvery } from "redux-saga/effects";
import { BALANCE_UPDATED, INSTRUMENTS_SET_ACTIVE, RECEIVED_TRADING_OPTIONS, requestSetActiveSymbol, saveInstrumentProfitLoss, SELECT_TRADE_PROFIT_LOSS, selectTradeInvest, SET_ACTIVE_SYMBOL, setActiveSymbol, setTradeProfitLoss } from "../actions";
function getMaximumMaxRiskForBalance(maxRisks, balance) {
    const sortedStopLosses = [...maxRisks].sort((a, b) => b - a);
    for (const risk of sortedStopLosses) {
        if (risk < balance) {
            return risk;
        }
    }
    return sortedStopLosses[sortedStopLosses.length - 1];
}
function* setTradingAmount(action) {
    const activeInstrument = yield select((state) => state.activeInstrument);
    if (!activeInstrument) {
        throw new Error("Active instrument is not set in setTradingAmount saga");
    }
    const activeInstrumentSymbol = activeInstrument.symbol;
    const sizesForActiveInstrument = Object.keys(activeInstrument.sizes).reduce((sizes, size) => {
        if (!Number.isNaN(parseFloat(size))) {
            sizes.push(parseFloat(size));
        }
        return sizes;
    }, []);
    const userSizeForActiveInstrument = yield select((state) => state.tradingScreen.profitLossPerInstrument[activeInstrumentSymbol]);
    yield put(setActiveSymbol(activeInstrumentSymbol));
    if (!sizesForActiveInstrument) {
        throw new Error(`Sizes configuration for instrument ${activeInstrumentSymbol} is missing.`);
    }
    if (userSizeForActiveInstrument) {
        yield put(setTradeProfitLoss(userSizeForActiveInstrument));
    }
    else {
        const lowestOption = Math.min(...(sizesForActiveInstrument));
        yield put(setTradeProfitLoss(lowestOption));
    }
}
function* handleSetActiveSymbol(action) {
    const { symbol } = action.payload;
    yield put(requestSetActiveSymbol(symbol));
}
function* setInvestIfNeeded(action) {
    let { balance, currency } = action.payload;
    const investSelected = yield select((state) => state.tradingScreen.investSelected);
    const maxRisks = yield select((state) => { var _a; return (_a = state.config) === null || _a === void 0 ? void 0 : _a.maxRisks; });
    if (!investSelected) {
        return;
    }
    if (!!maxRisks && balance < investSelected) {
        yield put(selectTradeInvest(getMaximumMaxRiskForBalance(maxRisks, balance)));
    }
}
function* handleSetInvestIfOverBalance() {
    const balance = yield select((state) => state.user.balance);
    const investSelected = yield select((state) => state.tradingScreen.investSelected);
    const maxRisks = yield select((state) => { var _a; return (_a = state.config) === null || _a === void 0 ? void 0 : _a.maxRisks; });
    if (!balance || !investSelected) {
        return;
    }
    if (!!maxRisks && balance < investSelected) {
        yield put(selectTradeInvest(getMaximumMaxRiskForBalance(maxRisks, balance)));
    }
}
function* saveInstrumentProfitLossSaga(action) {
    const { profitLoss } = action.payload;
    const activeInstrumentSymbol = yield select((state) => { var _a; return (_a = state.activeInstrument) === null || _a === void 0 ? void 0 : _a.symbol; });
    if (!activeInstrumentSymbol) {
        throw new Error("Active instrument is not set in saveInstrumentProfitLossSaga saga");
    }
    yield put(saveInstrumentProfitLoss(profitLoss, activeInstrumentSymbol));
}
function* tradingSaga() {
    yield takeEvery(INSTRUMENTS_SET_ACTIVE, setTradingAmount);
    yield takeEvery(SET_ACTIVE_SYMBOL, handleSetActiveSymbol);
    yield takeEvery(BALANCE_UPDATED, setInvestIfNeeded);
    yield takeEvery(SELECT_TRADE_PROFIT_LOSS, saveInstrumentProfitLossSaga);
    yield takeEvery(RECEIVED_TRADING_OPTIONS, handleSetInvestIfOverBalance);
}
export default tradingSaga;
