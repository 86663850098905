import { call, delay, put, race, select, take } from 'redux-saga/effects';
import { getValidTradingIntervalForInstrument } from '../utils/instrumentsHelpers';
import { API_DISCONNECTED, INSTRUMENTS_UPDATED, LOGIN_SUCCESS, POSITION_OPENED, setInstrumentsValidTradingIntervals, setPositionsTimeLeft, setTradeDuration } from '../actions';
import { openPositionsWithClosingSelector } from '../selectors';
import positionsHelpers from '../utils/positionsHelpers';
import { isSet } from '../utils/helpers';
import { logActivity } from "../services/crmService";
import * as Sentry from "@sentry/react";
function* instrumentsValidTradingIntervalsSaga() {
    yield take(INSTRUMENTS_UPDATED);
    while (true) {
        const instruments = yield select((state) => state.instruments);
        if (instruments && Object.values(instruments).length > 0) {
            const instrumentsTradingIntervals = Object.values(instruments).reduce((accumulator, instrument) => {
                accumulator[instrument.symbol] = getValidTradingIntervalForInstrument(instrument);
                return accumulator;
            }, {});
            yield put(setInstrumentsValidTradingIntervals(instrumentsTradingIntervals));
        }
        yield race([
            delay(30 * 1000),
            take(INSTRUMENTS_UPDATED),
        ]);
    }
}
function* positionsTimeLeftSaga() {
    while (true) {
        const openPositions = yield select(openPositionsWithClosingSelector);
        const closingPositionsIds = yield select((state) => state.closingPositionsIds);
        const positionsTimeLeft = yield select((state) => state.timeCalculations.positionsTimeLeft);
        if (openPositions.length > 0) {
            const newPositionsTimeLeft = openPositions.reduce((accumulator, position) => {
                var _a, _b;
                if (position.positionId) {
                    const currentPositionTimeLeft = (_b = (_a = positionsTimeLeft[position.positionId]) === null || _a === void 0 ? void 0 : _a.timeLeft) !== null && _b !== void 0 ? _b : position.duration;
                    if (closingPositionsIds.includes(position.positionId) && isSet(currentPositionTimeLeft)) {
                        accumulator[position.positionId] = {
                            timeLeft: currentPositionTimeLeft,
                            timeLeftPercentage: 100,
                        };
                    }
                    else {
                        accumulator[position.positionId] = positionsHelpers.getTimeLeft(position);
                    }
                    return accumulator;
                }
                else {
                    throw Error('positionId missing on open position');
                }
            }, {});
            yield put(setPositionsTimeLeft(newPositionsTimeLeft));
        }
        yield race([
            delay(1000),
            take(POSITION_OPENED),
        ]);
    }
}
function* tradeDurationSaga() {
    while (true) {
        const selectedDuration = yield select((state) => state.tradingScreen.durationSelected);
        const activeInstrument = yield select((state) => state.activeInstrument);
        const supportedDurations = yield select((state) => { var _a; return (_a = state.config) === null || _a === void 0 ? void 0 : _a.positionDurations; });
        if (activeInstrument && selectedDuration !== Infinity && selectedDuration !== null) {
            const activeInstrumentIntervals = getValidTradingIntervalForInstrument(activeInstrument);
            if (activeInstrumentIntervals.current) {
                const endTime = activeInstrumentIntervals.current.end.getTime();
                const currentTime = new Date().getTime();
                const timeUntilMarketCloses = endTime - currentTime;
                if (isSet(supportedDurations) && timeUntilMarketCloses - (selectedDuration * 1000) < 0) {
                    const sortedDurations = [...supportedDurations].sort(((a, b) => b - a));
                    for (let duration of sortedDurations) {
                        if (duration * 1000 <= timeUntilMarketCloses) {
                            yield put(setTradeDuration(duration));
                            break;
                        }
                    }
                }
            }
            yield delay(30 * 1000);
        }
        else {
            yield delay(2000);
        }
    }
}
function* logActivitySaga() {
    while (true) {
        try {
            yield call(logActivity, 'PING', '');
        }
        catch (e) {
            Sentry.captureException(e);
        }
        yield delay(10000);
    }
}
function* continuousSaga() {
    while (true) {
        yield take(LOGIN_SUCCESS);
        yield race([
            call(tradeDurationSaga),
            call(instrumentsValidTradingIntervalsSaga),
            call(positionsTimeLeftSaga),
            call(logActivitySaga),
            take(API_DISCONNECTED),
        ]);
    }
}
export default continuousSaga;
