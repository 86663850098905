import React from 'react';

const _t = require('counterpart');

export const OfflineNotice = ({ visible }) => {
	return (
		<div className={`offline-notice ${visible ? 'active' : ''}`}>
			<span>{_t.translate('main.offline_notice')}</span>
		</div>
	);
};
