import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './TitleHeader.module.scss';
import IconButton from 'components/IconButton/IconButton';
import { FiArrowLeft, FiX } from 'react-icons/fi';
import { isSet } from '../../helpers';
const TitleHeader = ({ title, onBackClick, endElements, backIconType = 'arrow' }) => {
    const titleElement = useMemo(() => {
        if (typeof title === 'string') {
            return _jsx("h6", Object.assign({ className: styles.title }, { children: title }));
        }
        else {
            return title;
        }
    }, [title]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [isSet(onBackClick) && (_jsx(IconButton, Object.assign({ size: "small", onClick: onBackClick, className: styles.backButton }, { children: backIconType === 'arrow' ? _jsx(FiArrowLeft, {}) : _jsx(FiX, {}) }))), _jsx("div", Object.assign({ className: styles.titleWrapper }, { children: titleElement })), isSet(endElements) && _jsx("div", Object.assign({ className: styles.endElements }, { children: endElements }))] })));
};
export default React.memo(TitleHeader);
