import React from 'react';
import { isAndroid, isIos, isStandalone } from '../../../helpers';
import config from 'config';
import PopupNotificationManager from '../PopupNotification/PopupNotificationManager';
import _t from 'counterpart';
import Button from '../Button/Button';
import { bindActionCreators } from 'redux';
import { actions } from 'wintrado-api';
import { connect } from 'react-redux';

const DELAY_SHOW_BY = 4000;

class InstallCTA extends React.Component {
	state = {
		showInstallCTA: false,
	};

	componentDidMount() {
		window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.pwaInstallPrompt !== this.props.pwaInstallPrompt && !this.props.pwaInstallPrompt) {
			PopupNotificationManager.hideNotification('install-cta');
		}
		if (prevProps.loggedIn !== this.props.loggedIn && this.props.loggedIn) {
			setTimeout(() => {
				this.setState({
					showInstallCTA: true,
				});
			}, DELAY_SHOW_BY);
		}
	}

	handleBeforeInstallPrompt = (e) => {
		e.preventDefault();
		this.props.setPWAInstallPrompt(e);
	};

	onInstallClicked = (e) => {
		const { pwaInstallPrompt, setPWAInstallPrompt } = this.props;
		e.preventDefault();
		if (pwaInstallPrompt.prompt) {
			pwaInstallPrompt.prompt();
			pwaInstallPrompt.userChoice.then((choiceResult) => {
				setPWAInstallPrompt(null);
			});
		}
		this.closePopup();
	};

	closePopup = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.props.setInstallCTAHiddenAt(new Date().getTime());
	};

	dontShowInstallCTAAgain = (e) => {
		e.preventDefault();
		this.props.setDontShowInstallCTA();
	};

	getBrandedInstallationInstructionImageOrFallback = () => {
		try {
			return require(`../../images/brands/${__BRAND__}/android-install-instructions.png`);
		} catch (e) {
			return require('../../images/generic-android-install-instructions.png');
		}
	};

	render() {
		const { pwaInstallPrompt, dontShowInstallCTA, installCTAHiddenAt } = this.props;
		const { showInstallCTA } = this.state;
		const shouldShow = !installCTAHiddenAt ? true : new Date().getTime() > installCTAHiddenAt + 12 * 60 * 60 * 1000;

		if (isStandalone() || !showInstallCTA) {
			return null;
		}
		if (isIos()) {
			return (
				<>
					{shouldShow && (
						<div id="iosInstallNotification">
							<div className="holder">
								<span className="close-popup" onClick={this.closePopup}>
									<i className="material-icons">close</i>
								</span>

								<p className="title">{_t.translate('installcta.how_to')}</p>
								<div className="description">
									{_t.translate('installcta.ios_before')}
									<span className="tapicon">
										<img src={require('/src/old/images/ios_share.jpg')} width="14" height="14" />
									</span>
									<span
										className="homescreen"
										dangerouslySetInnerHTML={{ __html: _t.translate('installcta.ios_after') }}
									/>
								</div>
							</div>
						</div>
					)}
				</>
			);
		}
		if (isAndroid() && pwaInstallPrompt) {
			return (
				<>
					{shouldShow && (
						<div id="androidInstallNotification">
							<div>
								<span className="close-popup" onClick={this.closePopup}>
									<i className="material-icons">close</i>
								</span>
								<div className="cta-header">
									<h2 dangerouslySetInnerHTML={{ __html: _t.translate('installcta.android_title') }} />
									<p dangerouslySetInnerHTML={{ __html: _t.translate('installcta.android_instructions') }} />
								</div>
								<div className="image-wrapper">
									<img src={this.getBrandedInstallationInstructionImageOrFallback()} alt="install-instructions" />
								</div>
								<div className="install-button-wrapper">
									<Button green onClick={this.onInstallClicked}>
										{_t.translate('installcta.android_button')}
									</Button>
								</div>
							</div>
						</div>
					)}
				</>
			);
		}
		if (pwaInstallPrompt) {
			if (!dontShowInstallCTA) {
				PopupNotificationManager.showNotification({
					id: 'install-cta',
					primaryButtonTitleKey: 'installcta.desktop_button',
					onPrimaryButtonClicked: this.onInstallClicked,
					secondaryButtonTitleKey: 'main.do_not_show_again',
					onSecondaryButtonClicked: this.dontShowInstallCTAAgain,
					descriptionKey: 'installcta.desktop_description',
					descriptionExtra: { appName: config.appName },
				});
			} else {
				PopupNotificationManager.hideNotification('install-cta');
			}
			return null;
		}
		return null;
	}
}

const mapStateToProps = (state) => {
	return {
		pwaInstallPrompt: state.settings.pwaInstallPrompt,
		dontShowInstallCTA: state.settings.dontShowInstallCTA,
		installCTAHiddenAt: state.events.installCTAHiddenAt,
		loggedIn: state.loggedIn && state.connection.ready,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setPWAInstallPrompt: actions.setPWAInstallPrompt,
			setDontShowInstallCTA: actions.setDontShowInstallCTA,
			setInstallCTAHiddenAt: actions.setInstallCTAHiddenAt,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallCTA);
