import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './MarketStatus.module.scss';
import { useAppSelector } from '../../storeHooks';
import { FiClock } from 'react-icons/fi';
import Spacer from 'components/Spacer/Spacer';
import { isSet } from '../../helpers';
import _t from 'counterpart';
import classNames from 'classnames';
const MarketStatus = ({ instrumentSymbol, withBackground = false, onlyWhenClosed = false }) => {
    const validTradingIntervals = useAppSelector((state) => state.timeCalculations.instrumentsValidTradingIntervals[instrumentSymbol]);
    if (onlyWhenClosed && isSet(validTradingIntervals === null || validTradingIntervals === void 0 ? void 0 : validTradingIntervals.current)) {
        return null;
    }
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, {
            [styles.containerWithBackground]: withBackground,
            [styles.containerMarketClosed]: !isSet(validTradingIntervals === null || validTradingIntervals === void 0 ? void 0 : validTradingIntervals.current),
            [styles.containerMarketOpen]: isSet(validTradingIntervals === null || validTradingIntervals === void 0 ? void 0 : validTradingIntervals.current),
        }) }, { children: [_jsx(FiClock, { size: 16 }), _jsx(Spacer, { x: "extraSmall" }), isSet(validTradingIntervals === null || validTradingIntervals === void 0 ? void 0 : validTradingIntervals.current) ? _t('instrument.market_open') : _t('instrument.market_closed')] })));
};
export default React.memo(MarketStatus);
