const config = {
    soundstyle: 'classic',
    appName: 'Wintrado Pro Staging',
    appTitle: 'Wintrado - WebTrader Pro Staging',
    feedApi: {
        url: 'wss://gateway.staging.wintrado.com',
    },
    baseURL: 'https://gateway.staging.wintrado.com',
    cashier: {
        useIframe: true,
        baseURL: 'https://cashier.staging.wintrado.com',
    },
    oAuth: {
        clientId: 'webtrader-pro-staging',
        clientSecret: 'qFaAPOfGIgBmUzxx',
    },
    affiliatePortalUrl: 'https://affiliate.staging.wintrado.com',
};
export default config;
