import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './ErrorScreen.module.scss';
import _t from 'counterpart';
import * as Sentry from '@sentry/react';
import { isOperaMini, releaseVersion, webpackHash } from '../../helpers';
import Button from '../Button/Button';
import Spacer from 'components/Spacer/Spacer';
const ErrorScreen = () => {
    const onRefreshClicked = () => {
        window.location.reload();
    };
    const lastSentryId = Sentry.lastEventId();
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("h2", Object.assign({ className: styles.title }, { children: _t.translate('error.oops') })), _jsx("p", Object.assign({ className: styles.description }, { children: _t.translate('error.generic') })), isOperaMini() && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx("p", Object.assign({ className: styles.description }, { children: _t.translate('error.opera') }))] })), _jsx("p", Object.assign({ className: styles.description }, { children: _t.translate('error.refresh_to_continue') })), Boolean(lastSentryId) && _jsx("p", { children: `ID: ${lastSentryId}` }), _jsx(Spacer, { y: 'small' }), _jsx(Button, Object.assign({ variant: "primary", onClick: onRefreshClicked }, { children: _t.translate('main.refresh') })), _jsx("div", Object.assign({ className: styles.versionContainer }, { children: _jsxs("span", { children: [_jsx("span", { children: `v${releaseVersion()}` }), _jsx("span", { children: webpackHash() })] }) }))] })));
};
export default ErrorScreen;
