import { all, put, select, takeLatest } from "redux-saga/effects";
import { QUOTE_RECEIVED, setCandlesData } from "../actions";
import { addQuoteToCandles } from "../utils/aggregateCandles";
function* updateCandleFromQuote(action) {
    const quote = action.payload;
    const candlesSymbol = yield select((state) => state.candles.symbol);
    const candlesData = yield select((state) => state.candles.data);
    const chartResolution = yield select((state) => state.settings.chartResolution);
    const activeInstrumentDecimals = yield select((state) => { var _a; return (_a = state.activeInstrument) === null || _a === void 0 ? void 0 : _a.decimals; });
    if (quote.symbol !== candlesSymbol || Number.isNaN(activeInstrumentDecimals)) {
        return;
    }
    const updatedCandleData = addQuoteToCandles([...candlesData], quote, chartResolution, activeInstrumentDecimals);
    yield put(setCandlesData(updatedCandleData));
}
function* candlesSaga() {
    yield all([
        takeLatest(QUOTE_RECEIVED, updateCandleFromQuote)
    ]);
}
export default candlesSaga;
