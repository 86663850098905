import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import noMessagesIcon from '../icons/no_messages.svg';
import { PageTitle } from './global/PageTitle';
import _t from 'counterpart';
import { PageSubtitle } from './global/PageSubtitle';
const NoMessages = () => {
    return (_jsxs("div", Object.assign({ className: "no-messages" }, { children: [_jsx("img", { src: noMessagesIcon, alt: "", className: "no-messages__icon" }), _jsx(PageTitle, { children: _t.translate('messages.no-messages.title') }), _jsx(PageSubtitle, { children: _t.translate('messages.no-messages.description') })] })));
};
export default React.memo(NoMessages);
