import { AUTO_LOGIN_REQUESTED, CLEAR_STOP_OUT_OCCURRED, LOGIN_REQUESTED, LOGOFF, SET_INSTALL_CTA_HIDDEN_AT, SET_LOGGING_IN, SET_LOGIN_TIMED_OUT, SET_LOW_MARGIN_MODAL_SHOWN, SET_SIDE_DRAWER_SHOWN, SET_STOP_OUT_OCCURRED, UNSET_LOGGING_IN, } from '../../actions';
function eventsReducer(state, action) {
    switch (action.type) {
        case LOGIN_REQUESTED:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { userRequestedLogoff: false }) }));
        case AUTO_LOGIN_REQUESTED:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { userRequestedLogoff: false }) }));
        case SET_INSTALL_CTA_HIDDEN_AT:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { installCTAHiddenAt: action.payload.hiddenAt }) }));
        case SET_SIDE_DRAWER_SHOWN:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { sideDrawerShown: action.payload }) }));
        case LOGOFF:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { userRequestedLogoff: action.payload.userRequested }) }));
        case SET_LOW_MARGIN_MODAL_SHOWN:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { lowMarginModalShown: true }) }));
        case SET_LOGGING_IN:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { loggingIn: true }) }));
        case UNSET_LOGGING_IN:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { loggingIn: false }) }));
        case SET_STOP_OUT_OCCURRED:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { stopOutOccurred: true }) }));
        case CLEAR_STOP_OUT_OCCURRED:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { stopOutOccurred: false }) }));
        case SET_LOGIN_TIMED_OUT:
            return (Object.assign(Object.assign({}, state), { events: Object.assign(Object.assign({}, state.events), { loginTimedOut: action.payload.value }) }));
        default:
            return state;
    }
}
export default eventsReducer;
