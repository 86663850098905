var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './Tooltip.module.scss';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
const Tooltip = (_a) => {
    var { children, content } = _a, props = __rest(_a, ["children", "content"]);
    return (_jsxs(TooltipPrimitive.Root, { children: [_jsx(TooltipPrimitive.Trigger, Object.assign({ asChild: true }, { children: children })), _jsxs(TooltipPrimitive.Content, Object.assign({ className: styles.content, side: "bottom", align: "center" }, props, { children: [content, _jsx(TooltipPrimitive.Arrow, { className: styles.arrow, width: 11, height: 5 })] }))] }));
};
export default React.memo(Tooltip);
