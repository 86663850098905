import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styles from './MobileTradingScreen.module.scss';
import MobileBalanceBar from 'components/MobileBalanceBar/MobileBalanceBar';
import MobileTradingFooter from 'components/MobileTradingFooter/MobileTradingFooter';
import AnalysisChart from 'components/AnalysisChart/AnalysisChart';
import { Route, Switch } from 'react-router-dom';
import MobilePlaceOrderSheet from '../../sheets/MobilePlaceOrderSheet/MobilePlaceOrderSheet';
import MobileEditOrderScreen from '../MobileEditOrderScreen/MobileEditOrderScreen';
import MobileEditPositionScreen from '../MobileEditPositionScreen/MobileEditPositionScreen';
import MobilePositionsOrdersScreen from '../MobilePositionsOrdersScreen/MobilePositionsOrdersScreen';
import MobileInstrumentScreen from '../MobileInstrumentScreen/MobileInstrumentScreen';
import MobileWatchlistScreen from '../MobileWatchlistScreen/MobileWatchlistScreen';
import MobileInstrumentsScreen from '../MobileInstrumentsScreen/MobileInstrumentsScreen';
import { isIos } from "../../helpers";
const MobileTradingScreen = () => {
    return (_jsx(_Fragment, { children: _jsxs(Switch, { children: [_jsx(Route, Object.assign({ exact: true, path: "/trading/orders/:orderId" }, { children: _jsx(MobileEditOrderScreen, {}) })), _jsx(Route, Object.assign({ exact: true, path: "/trading/positions/:positionId" }, { children: _jsx(MobileEditPositionScreen, {}) })), _jsx(Route, Object.assign({ exact: true, path: ['/trading/positions', '/trading/orders'] }, { children: _jsx(MobilePositionsOrdersScreen, {}) })), _jsx(Route, Object.assign({ exact: true, path: "/trading/instruments/:symbol" }, { children: _jsx(MobileInstrumentScreen, {}) })), _jsx(Route, Object.assign({ exact: true, path: "/trading/watchlist" }, { children: _jsx(MobileWatchlistScreen, {}) })), _jsx(Route, Object.assign({ exact: true, path: "/trading/instruments" }, { children: _jsx(MobileInstrumentsScreen, {}) })), _jsxs(Route, Object.assign({ path: "/trading" }, { children: [_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(MobileBalanceBar, {}), _jsx("div", Object.assign({ className: styles.chartContainer }, { children: _jsx(AnalysisChart, { showSymbolSelectButton: true, hideHeaderFullscreenbutton: isIos() }) })), _jsx(MobileTradingFooter, {})] })), _jsx(Route, Object.assign({ exact: true, path: "/trading/place-order" }, { children: _jsx(MobilePlaceOrderSheet, {}) }))] }))] }) }));
};
export default React.memo(MobileTradingScreen);
