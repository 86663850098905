import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './Header.module.scss';
import CustomBanner from 'components/CustomBanner/CustomBanner';
import BalanceStat from 'components/BalanceStat/BalanceStat';
import CoinsStat from 'components/CoinsStat/CoinsStat';
import Spacer from 'components/Spacer/Spacer';
import IconButton from 'components/IconButton/IconButton';
import { FiMessageCircle, FiSettings } from 'react-icons/fi';
import { useIsMobile, useIsTablet } from 'utils/responsiveHelpers';
import MobileSidebar from 'components/MobileSidebar/MobileSidebar';
import AccountInfo from 'components/AccountInfo/AccountInfo';
const Header = () => {
    const isTablet = useIsTablet();
    const isMobile = useIsMobile();
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [!isMobile && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.bannerWrapper }, { children: _jsx(CustomBanner, {}) })), _jsx(BalanceStat, {}), _jsx(Spacer, { x: "small" }), _jsx(CoinsStat, {}), _jsx(Spacer, { x: "small" })] })), _jsx(MobileSidebar, { className: styles.mobileSidebar }), _jsx(AccountInfo, {}), _jsx(Spacer, { x: "small" }), _jsx(IconButton, Object.assign({ to: "/messages", size: isTablet ? 'small' : 'normal' }, { children: _jsx(FiMessageCircle, {}) })), _jsx(Spacer, { x: "small" }), _jsx(IconButton, Object.assign({ to: "/settings", exact: true, size: isTablet ? 'small' : 'normal' }, { children: _jsx(FiSettings, {}) }))] })));
};
export default React.memo(Header);
