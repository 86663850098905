import { BALANCE_ADJUSTMENT_FROM_PL_UPDATED, BALANCE_UPDATED, COINS_BALANCE_UPDATED, EXECUTE_PENDING_BALANCE_UPDATE, } from '../../actions';
export default function balanceReducer(state, action) {
    switch (action.type) {
        case BALANCE_UPDATED:
            const { balance, credit, currency } = action.payload;
            // If a position is currently closing, we defer the balance update until after
            // the closing animation (step-wise balance update) has finished.
            if (state.closingAllPositions || state.closingSinglePosition || state.pendingPositionCloses > 0) {
                return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { pendingBalanceUpdate: balance + credit, wallet: state.user.wallet ? Object.assign(Object.assign({}, state.user.wallet), { balance: balance, credit: credit }) : state.user.wallet }) });
            }
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { balance: balance + credit, accountCurrency: currency ? currency : state.user.accountCurrency, wallet: state.user.wallet ? Object.assign(Object.assign({}, state.user.wallet), { balance: balance, credit: credit }) : state.user.wallet }) });
        case COINS_BALANCE_UPDATED:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { coinsBalance: action.payload.balance }) });
        case BALANCE_ADJUSTMENT_FROM_PL_UPDATED: {
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { balanceAdjustmentFromPL: action.payload }) });
        }
        case EXECUTE_PENDING_BALANCE_UPDATE:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { balance: state.user.pendingBalanceUpdate, pendingBalanceUpdate: null }) });
        default:
            return state;
    }
}
