import { createSelector } from "reselect";
import { PositionSide, PositionStatusCode } from "../reducers/positions/types";
import { decrementPenultimateDecimal, incrementPenultimateDecimal, isSet, map } from "../utils/helpers";
import { SupportedFeatures } from "../reducers/coreApi/types";
import { OrderStatus } from "../reducers/orders/types";
export const openPositionsSelector = createSelector((state) => state.positions, (positions) => Object.values(positions).filter(p => p.status === PositionStatusCode.OPEN));
export const newPositionsSelector = createSelector((state) => state.positions, (positions) => Object.values(positions).filter(p => p.status === PositionStatusCode.NEW));
export const positionTimeLeftSelector = createSelector([
    (state) => state.timeCalculations.positionsTimeLeft,
    (state, positionId) => positionId
], (positionsTimeLeft, positionId) => {
    return positionsTimeLeft[positionId];
});
export const openPositionsWithClosingSelector = createSelector(openPositionsSelector, (state) => state.closingPositionsIds ? Object.values(state.positions).filter(position => isSet(position.positionId) && state.closingPositionsIds.includes(position.positionId)) : [], (positions, closing) => {
    if (closing.length !== 0) {
        const openPositionsIds = positions.map(position => position.positionId);
        const nonExistingClosingPositions = closing.filter(position => !openPositionsIds.includes(position.positionId));
        return positions.concat(nonExistingClosingPositions).sort((a, b) => a.positionId - b.positionId);
    }
    else {
        return positions;
    }
});
export const openPositionsWithClosingAndNewSelector = createSelector(openPositionsWithClosingSelector, newPositionsSelector, (openPositions, newPositions) => {
    return [...openPositions, ...newPositions].sort((a, b) => a.positionId - b.positionId);
});
export const activePositionSelector = createSelector((state) => state.positions, (state) => state.activePositionId, (positions, activePositionId) => {
    if (!!activePositionId) {
        return positions[activePositionId];
    }
    else {
        return undefined;
    }
});
export const isActivePositionCloseable = createSelector(activePositionSelector, (activePosition) => {
    if (!activePosition) {
        return false;
    }
    return activePosition.status === PositionStatusCode.OPEN;
});
export const isActivePositionClosing = createSelector((state) => state.activePositionId, (state) => state.closingPositionsIds, (activePositionId, closingPositionsIds) => {
    return activePositionId && closingPositionsIds.includes(activePositionId);
});
export const bubbleValueSelector = createSelector(activePositionSelector, (state) => state.closingSinglePosition || state.closingAllPositions, (state) => state.closedPositionsTotalPL, (state) => state.user.balanceAdjustmentFromPL, (activePos, closing, closedPL, adjust = 0) => {
    var _a;
    if (closing) {
        return closedPL - adjust;
    }
    else {
        return (_a = activePos === null || activePos === void 0 ? void 0 : activePos.profitLoss) !== null && _a !== void 0 ? _a : 0;
    }
});
export const activePositionInstrumentSelector = createSelector(activePositionSelector, (state) => state.instruments, (activePos, instruments) => activePos && activePos.symbol ? instruments[activePos.symbol] : null);
export const activePositionQuotesSelector = createSelector(activePositionInstrumentSelector, (state) => state.quotes, (activeIns, quotes) => activeIns ? quotes[activeIns.symbol] : null);
export const activeInstrumentQuotesSelector = createSelector((state) => state.activeInstrument, (state) => state.quotes, (activeIns, quotes) => {
    return activeIns ? quotes[activeIns.symbol] : null;
});
export const latestQuotesSelector = createSelector((state) => state.quotes, (quotes) => {
    let latestQuotes = {};
    for (const symbol in quotes) {
        const symbolQuotes = quotes[symbol];
        if (symbolQuotes !== undefined && symbolQuotes.length > 0) {
            latestQuotes[symbol] = symbolQuotes[symbolQuotes.length - 1];
        }
    }
    return latestQuotes;
});
export const latestQuoteForSymbolSelector = (symbol) => createSelector((state) => state.quotes, (quotes) => {
    const symbolQuotes = quotes[symbol];
    if (symbolQuotes !== undefined && symbolQuotes.length > 0) {
        return symbolQuotes[symbolQuotes.length - 1];
    }
    return null;
});
export const activeInstrumentWithIntervalsSelector = createSelector((state) => state.activeInstrument, (state) => state.timeCalculations.instrumentsValidTradingIntervals, (activeInstrument, instrumentsValidTradingIntervals) => {
    if (!activeInstrument || !instrumentsValidTradingIntervals[activeInstrument.symbol]) {
        return null;
    }
    else {
        return Object.assign(Object.assign({}, activeInstrument), { validTradingIntervals: instrumentsValidTradingIntervals[activeInstrument.symbol] });
    }
});
export const activePositionPLPoints = createSelector(activePositionSelector, activePositionInstrumentSelector, activePositionQuotesSelector, (activePos, activeIns, activeQuotes) => {
    if (!activePos || !activeIns || !activeQuotes) {
        return null;
    }
    if (!activePos.openPrice) {
        return 0;
    }
    const currentQuoteForSymbol = activeQuotes[activeQuotes.length - 1];
    if (!currentQuoteForSymbol) {
        return null;
    }
    let currentPriceForPos;
    if (activePos.side === PositionSide.BUY) {
        currentPriceForPos = currentQuoteForSymbol.bid;
    }
    else if (activePos.side === PositionSide.SELL) {
        currentPriceForPos = currentQuoteForSymbol.ask;
    }
    return Math.floor((currentPriceForPos - activePos.openPrice) / activeIns.precision);
});
export const equitySelector = createSelector(openPositionsWithClosingSelector, (state) => state.user.balance, (positions, balance) => {
    if (balance === null) {
        return null;
    }
    return positions.reduce((acc, position) => Number(acc) + Number((position.profitLoss || 0)), balance);
});
export const usedMarginSelector = createSelector(openPositionsSelector, (positions) => {
    return positions.reduce((acc, position) => acc + (position.margin || 0), 0);
});
export const freeMarginSelector = createSelector(equitySelector, usedMarginSelector, (equity, usedMargin) => {
    if (equity === null) {
        return null;
    }
    return equity - usedMargin;
});
export const marginLevelSelector = createSelector(equitySelector, usedMarginSelector, (equity, usedMargin) => {
    if (equity === null) {
        return null;
    }
    if (usedMargin === 0) {
        return 0;
    }
    return (equity / usedMargin);
});
/**
 * Returns true if we have a margin call situation, i.e. the user is running out of free margin
 */
export const marginCallSelector = createSelector(equitySelector, usedMarginSelector, (state) => state.config.marginCallLevel, (equity, usedMargin, marginCallLevel) => {
    if (!equity || usedMargin === 0 || marginCallLevel === null || marginCallLevel === 0) {
        return false;
    }
    return (equity / usedMargin * 100) <= marginCallLevel;
});
export const totalProfitLossSelector = createSelector(openPositionsSelector, (openPositions) => {
    if (openPositions.length < 1) {
        return null;
    }
    return openPositions.reduce((profitLoss, position) => profitLoss + (position.profitLoss || 0), 0);
});
export const activeInstrumentTrend = createSelector((state) => state.activeInstrument, (state) => state.instrumentsTrend, (activeInstrument, instrumentsTrend) => {
    if (!activeInstrument || !isSet(instrumentsTrend[activeInstrument.symbol])) {
        return null;
    }
    const activeInstrumentTrend = instrumentsTrend[activeInstrument.symbol];
    const buyPercentage = Math.round(map(activeInstrumentTrend, -1, 1, 0, 100));
    const sellPercentage = 100 - buyPercentage;
    return {
        buyPercentage,
        sellPercentage
    };
});
const instrumentByCategorySelectorFactory = (category) => createSelector((state) => state.instruments, (instruments) => Object.values(instruments).filter((i) => i.category === category));
export const currenciesSelector = instrumentByCategorySelectorFactory("currencies");
export const indicesSelector = instrumentByCategorySelectorFactory("indices");
export const commoditiesSelector = instrumentByCategorySelectorFactory("commodities");
export const cryptoSelector = instrumentByCategorySelectorFactory("crypto");
export const equitiesSelector = instrumentByCategorySelectorFactory("equities");
const supportedFeaturesSelectorFactory = (feature) => createSelector((state) => state.user.supportedFeatures, (supportedFeatures) => {
    if (!supportedFeatures) {
        return false;
    }
    else
        return supportedFeatures.includes(feature);
});
export const supportsCoinsSelector = supportedFeaturesSelectorFactory(SupportedFeatures.Coins.toString());
export const supportsMessagesSelector = supportedFeaturesSelectorFactory(SupportedFeatures.Messages.toString());
export const supportsUserUpdateSelector = supportedFeaturesSelectorFactory(SupportedFeatures.UserUpdate.toString());
export const supportsCompetitionsSelector = supportedFeaturesSelectorFactory(SupportedFeatures.Competitions.toString());
export const supportsBannersSelector = supportedFeaturesSelectorFactory(SupportedFeatures.Banners.toString());
export const supportsCopytrading = supportedFeaturesSelectorFactory(SupportedFeatures.Copytrading.toString());
export const pendingOrdersSelector = createSelector((state) => state.orders, (orders) => {
    return Object.values(orders).filter((o) => [OrderStatus.PENDING, OrderStatus.NEW, OrderStatus.ACCEPTED].includes(o.status));
});
export const suggestedTradePricesSelector = (symbol) => createSelector(latestQuoteForSymbolSelector(symbol), (state) => state.instruments[symbol], (quote, instrument) => {
    if (!quote || !instrument) {
        return {
            limitAndStop: null,
            stopLoss: null,
            takeProfit: null
        };
    }
    const decimals = instrument.decimals;
    const fixedBid = quote.bid.toFixed(decimals);
    const fixedAsk = quote.ask.toFixed(decimals);
    return {
        limitAndStop: {
            buy: decrementPenultimateDecimal(fixedAsk),
            sell: incrementPenultimateDecimal(fixedBid)
        },
        stopLoss: {
            buy: decrementPenultimateDecimal(fixedBid),
            sell: incrementPenultimateDecimal(fixedAsk)
        },
        takeProfit: {
            buy: incrementPenultimateDecimal(fixedBid),
            sell: decrementPenultimateDecimal(fixedAsk)
        }
    };
});
