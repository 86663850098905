import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from 'react';
import styles from './PositionsOrdersSection.module.scss';
import Badge from 'components/Badge/Badge';
import Spacer from 'components/Spacer/Spacer';
import _t from 'counterpart';
import { useAppSelector } from '../../storeHooks';
import { selectors } from 'wintrado-api';
import PositionsList from 'components/PositionsList/PositionsList';
import IconButton from 'components/IconButton/IconButton';
import PendingOrdersList from 'components/PendingOrdersList/PendingOrdersList';
import Tabs from 'components/Tabs/Tabs';
import Collapsible from 'components/Collapsible/Collapsible';
import CollapsibleTrigger from 'components/Collapsible/CollapsibleTrigger';
import CollapsibleContent from 'components/Collapsible/CollapsibleContent';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Resizable } from 'react-resizable';
import ResizableHandle from 'components/ResizableHandle/ResizableHandle';
const EXPANDED_COLLAPSIBLE_CONTENT_HEIGHT = 150;
const getMaxHeightFromWindowHeight = (windowHeight) => {
    if (windowHeight > 1000) {
        return windowHeight * 0.5;
    }
    else if (windowHeight > 800) {
        return windowHeight * 0.4;
    }
    return windowHeight * 0.3;
};
const PositionsOrdersSection = ({ onResizeStart, onResizeStop }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState('');
    const [height, setHeight] = React.useState(EXPANDED_COLLAPSIBLE_CONTENT_HEIGHT);
    const [maxHeight, setMaxHeight] = React.useState(getMaxHeightFromWindowHeight(window.innerHeight));
    const lastSelectedTabRef = useRef(null);
    useEffect(() => {
        function handleResize() {
            const newMaxHeight = getMaxHeightFromWindowHeight(window.innerHeight);
            setMaxHeight(newMaxHeight);
            if (height > newMaxHeight) {
                setHeight(newMaxHeight);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    const handleIsExpandedChange = (open) => {
        setSelectedTab((prevSelectedTab) => {
            var _a;
            if (open) {
                return (_a = lastSelectedTabRef.current) !== null && _a !== void 0 ? _a : 'open-positions';
            }
            else {
                if (prevSelectedTab !== '') {
                    lastSelectedTabRef.current = prevSelectedTab;
                }
                return '';
            }
        });
        setIsExpanded(open);
    };
    // this is intentionally custom callback rather than using onValueChange
    const handleTabClicked = React.useCallback((name) => {
        setSelectedTab(name);
        if (!isExpanded && name !== selectedTab) {
            setIsExpanded(true);
        }
        if (isExpanded && name === selectedTab) {
            handleIsExpandedChange(false);
        }
    }, [isExpanded, selectedTab]);
    const onResize = useCallback((e, { size }) => {
        setHeight(size.height);
    }, []);
    const openPositions = useAppSelector(selectors.openPositionsSelector);
    const pendingOrders = useAppSelector(selectors.pendingOrdersSelector);
    return (_jsx(Resizable, Object.assign({ className: styles.resizableContainer, width: Infinity, height: height, onResize: onResize, onResizeStart: onResizeStart, onResizeStop: onResizeStop, minConstraints: [0, EXPANDED_COLLAPSIBLE_CONTENT_HEIGHT], maxConstraints: [Infinity, maxHeight], handle: (resizeHandle, ref) => _jsx(ResizableHandle, { ref: ref, resizeHandle: resizeHandle, hidden: !isExpanded }), resizeHandles: ['n'] }, { children: _jsx(Collapsible, Object.assign({ open: isExpanded, onOpenChange: handleIsExpandedChange }, { children: _jsx(Tabs, { value: selectedTab, className: styles.container, contentClassName: styles.content, contentWrapper: CollapsibleContent, contentWrapperClassName: styles.collapsibleContent, contentHeight: height, tabsListSuffix: _jsx(_Fragment, { children: _jsx(CollapsibleTrigger, Object.assign({ asChild: true }, { children: _jsx(IconButton, Object.assign({ className: styles.expandButton, size: 'small', onClick: () => handleIsExpandedChange(!isExpanded) }, { children: isExpanded ? _jsx(FiChevronDown, {}) : _jsx(FiChevronUp, {}) })) })) }), content: [
                    {
                        value: 'open-positions',
                        label: (_jsxs(_Fragment, { children: [_t('main.open_positions'), openPositions.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Spacer, { x: "extraSmall" }), _jsx(Badge, { value: openPositions.length })] }))] })),
                        content: _jsx(PositionsList, { positions: openPositions }),
                        onTabClick: handleTabClicked,
                    },
                    {
                        value: 'pending-orders',
                        label: (_jsxs(_Fragment, { children: [_t('main.pending_orders'), pendingOrders.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Spacer, { x: "extraSmall" }), _jsx(Badge, { value: pendingOrders.length })] }))] })),
                        content: _jsx(PendingOrdersList, { pendingOrders: pendingOrders }),
                        onTabClick: handleTabClicked,
                    },
                ] }) })) })));
};
export default React.memo(PositionsOrdersSection);
