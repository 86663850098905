import { takeEvery, put } from 'redux-saga/effects';
import { INSTRUMENTS_RECEIVED, setActiveInstrument, subscribeToQuotesForSymbols, updateInstruments } from '../actions';
import * as instrumentsHelpers from '../utils/instrumentsHelpers';
import { activePositionSelector } from '../selectors';
import { select } from 'redux-saga/effects';
function* handleReceiveInstruments(action) {
    const { instruments, timezone, defaultInstrument } = action.payload;
    const activeInstrumentSymbol = yield select((state) => state.activeInstrumentSymbol);
    const activePosition = yield select(activePositionSelector);
    const instrumentToPreselect = yield select((state) => state.settings.instrumentToPreselect);
    const cleanInstruments = instruments.reduce((acc, dataEntry) => {
        if (dataEntry.symbol === undefined) {
            return acc;
        }
        acc[dataEntry.symbol] = instrumentsHelpers.sanitizeBaseInstrument(dataEntry);
        return acc;
    }, {});
    yield put(subscribeToQuotesForSymbols(Object.keys(cleanInstruments)));
    yield put(updateInstruments(cleanInstruments, timezone));
    let instrumentToSetActive = null;
    if (activePosition === null || activePosition === void 0 ? void 0 : activePosition.symbol) {
        instrumentToSetActive = activePosition.symbol;
    }
    else if (instrumentToPreselect && cleanInstruments[instrumentToPreselect]) {
        instrumentToSetActive = instrumentToPreselect;
    }
    else if (activeInstrumentSymbol && cleanInstruments[activeInstrumentSymbol]) {
        instrumentToSetActive = activeInstrumentSymbol;
    }
    else if (defaultInstrument) {
        instrumentToSetActive = defaultInstrument;
    }
    if (instrumentToSetActive) {
        yield put(setActiveInstrument(instrumentToSetActive));
    }
    else {
        throw Error('Received instruments, none set to active as none of the following are set - activePosition, defaultInstrument, instrumentToPreselect');
    }
}
function* instrumentsSaga() {
    yield takeEvery(INSTRUMENTS_RECEIVED, handleReceiveInstruments);
}
export default instrumentsSaga;
