import { FETCH_ACTIVE_BANNER_REJECT, FETCH_ACTIVE_BANNER_SUCCESS, FETCH_COINS_EXCHANGE_RATE_REJECT, FETCH_COINS_EXCHANGE_RATE_REQUESTED, FETCH_COINS_EXCHANGE_RATE_SUCCESS, FETCH_MESSAGES_NEXT_PAGE_SUCCESS, FETCH_MESSAGES_REJECT, FETCH_MESSAGES_REQUESTED, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_UNREAD_COUNT_SUCCESS, } from '../../actions';
export default function brokerReducer(state, action) {
    switch (action.type) {
        case FETCH_COINS_EXCHANGE_RATE_REQUESTED:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { coinsExchangeRate: {
                        loading: true,
                        error: false,
                        rate: null,
                        effectiveRate: null,
                        starsBonus: null,
                    } }) });
        case FETCH_COINS_EXCHANGE_RATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { coinsExchangeRate: {
                        loading: false,
                        error: false,
                        rate: action.payload.rate,
                        effectiveRate: action.payload.effectiveRate,
                        starsBonus: action.payload.starsBonus,
                    } }) });
        case FETCH_COINS_EXCHANGE_RATE_REJECT:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { coinsExchangeRate: {
                        loading: false,
                        error: true,
                        rate: null,
                        effectiveRate: null,
                        starsBonus: null,
                    } }) });
        case FETCH_MESSAGES_REQUESTED:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { messages: Object.assign(Object.assign({}, state.broker.messages), { loading: true, error: false, pagination: null }) }) });
        case FETCH_MESSAGES_SUCCESS:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { messages: Object.assign(Object.assign({}, state.broker.messages), { loading: false, error: false, data: action.payload.messages, pagination: action.payload.pagination }) }) });
        case FETCH_MESSAGES_REJECT:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { messages: Object.assign(Object.assign({}, state.broker.messages), { loading: false, error: true, data: null, pagination: null }) }) });
        case FETCH_MESSAGES_NEXT_PAGE_SUCCESS:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { messages: Object.assign(Object.assign({}, state.broker.messages), { loading: false, error: false, data: [...state.broker.messages.data, ...action.payload.newMessages], pagination: action.payload.pagination }) }) });
        case FETCH_MESSAGES_UNREAD_COUNT_SUCCESS:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { messages: Object.assign(Object.assign({}, state.broker.messages), { unreadCount: action.payload.unreadCount }) }) });
        case FETCH_ACTIVE_BANNER_SUCCESS:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { activeBanner: action.payload.activeBanner }) });
        case FETCH_ACTIVE_BANNER_REJECT:
            return Object.assign(Object.assign({}, state), { broker: Object.assign(Object.assign({}, state.broker), { activeBanner: null }) });
        default:
            return state;
    }
}
