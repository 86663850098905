import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './Tabs.module.scss';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { isSet } from '../../helpers';
const Tabs = ({ content, className, value, onValueChange, defaultValue, contentClassName, contentWrapper, contentWrapperClassName, tabsListSuffix, listClassName, contentHeight, }) => {
    const ContentWrapper = useMemo(() => (isSet(contentWrapper) ? contentWrapper : React.Fragment), [contentWrapper]);
    return (_jsxs(TabsPrimitive.Root, Object.assign({ className: classNames(styles.container, className), defaultValue: defaultValue, value: value, onValueChange: onValueChange }, { children: [_jsxs(TabsPrimitive.List, Object.assign({ className: classNames(styles.list, listClassName) }, { children: [content.map((tabContent) => (_jsx(TabsPrimitive.Trigger, Object.assign({ value: tabContent.value, className: styles.trigger, onMouseDown: () => { var _a; return (_a = tabContent.onTabClick) === null || _a === void 0 ? void 0 : _a.call(tabContent, tabContent.value); } }, { children: tabContent.label }), tabContent.value))), isSet(tabsListSuffix) && tabsListSuffix] })), _jsx(ContentWrapper, Object.assign({}, (isSet(contentWrapper)
                ? {
                    className: contentWrapperClassName,
                    style: { height: contentHeight },
                }
                : undefined), { children: content.map((tabContent) => (_jsx(TabsPrimitive.Content, Object.assign({ value: tabContent.value, className: classNames(styles.content, contentClassName) }, { children: tabContent.content }), tabContent.value))) }))] })));
};
export default React.memo(Tabs);
