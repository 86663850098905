import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import styles from './LoyaltyStat.module.scss';
import classNames from 'classnames';
import _t from 'counterpart';
import moment from 'moment';
import { isSet, numberToCompactNotation } from '../../helpers';
import { NavLink } from 'react-router-dom';
import { LoyaltyLevelName } from 'wintrado-api';
import { useAppSelector } from '../../storeHooks';
import PopupAlertManager from '../../old/components/PopupAlert/PopupAlertManager';
import { usePrevious } from '../../hooks/usePrevious';
const LoyaltyStat = ({ isCollapsed = false }) => {
    const loyaltyLevel = useAppSelector((state) => state.user.loyaltyLevel);
    const prevLoyaltyLevelName = usePrevious(loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.currentLevelName);
    useEffect(() => {
        if (!prevLoyaltyLevelName ||
            !(loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.currentLevelName) ||
            prevLoyaltyLevelName === (loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.currentLevelName)) {
            return;
        }
        PopupAlertManager.showAlert({
            type: 'success',
            title: _t.translate(`loyalty.levels.${loyaltyLevel.currentLevelName}`),
            subtitle: _t.translate(`loyalty.reached_level_subtitle.${loyaltyLevel.currentLevelName}`),
            buttonLabel: _t.translate('modal.close'),
            customIcon: require(`/src/old/icons/reached-${loyaltyLevel.currentLevelName}.svg`),
        });
    }, [loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.currentLevelName, prevLoyaltyLevelName]);
    const crownIcon = useMemo(() => {
        if (!(loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.currentLevelName)) {
            return null;
        }
        return require(`/src/old/icons/${loyaltyLevel.currentLevelName}-crown.svg`);
    }, [loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.currentLevelName]);
    const starsIcon = useMemo(() => {
        if (!(loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.currentLevelName)) {
            return null;
        }
        return require(`/src/old/icons/${loyaltyLevel.currentLevelName}-stars.svg`);
    }, [loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.currentLevelName]);
    const coinsForNextLevel = useMemo(() => {
        if (isSet(loyaltyLevel) && isSet(loyaltyLevel.coinsUntilNextLevel) && isSet(loyaltyLevel.totalCoinsMonth)) {
            return loyaltyLevel.totalCoinsMonth + loyaltyLevel.coinsUntilNextLevel;
        }
        else {
            return null;
        }
    }, [loyaltyLevel]);
    const daysUntilReset = useMemo(() => {
        if (!(loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.validUntil)) {
            return null;
        }
        const validUntilDate = moment(loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.validUntil);
        return validUntilDate.diff(moment(), 'days');
    }, [loyaltyLevel === null || loyaltyLevel === void 0 ? void 0 : loyaltyLevel.validUntil]);
    if (!loyaltyLevel) {
        return null;
    }
    const { currentLevelName, totalCoinsMonth } = loyaltyLevel;
    return (_jsx(NavLink, Object.assign({ className: styles.link, to: "/loyalty" }, { children: _jsxs("div", Object.assign({ className: classNames(styles.container, {
                [styles.containerBronze]: currentLevelName === LoyaltyLevelName.BRONZE,
                [styles.containerSilver]: currentLevelName === LoyaltyLevelName.SILVER,
                [styles.containerGold]: currentLevelName === LoyaltyLevelName.GOLD,
                [styles.containerPlatinum]: currentLevelName === LoyaltyLevelName.PLATINUM,
                [styles.containerDiamond]: currentLevelName === LoyaltyLevelName.DIAMOND,
                [styles.containerCollapsed]: isCollapsed,
            }) }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("img", { src: crownIcon, alt: currentLevelName, className: styles.icon }), _jsxs("div", Object.assign({ className: styles.labelsWrapper }, { children: [_jsx("span", Object.assign({ className: styles.title }, { children: _t(`loyalty.levels.${currentLevelName}`) })), isSet(daysUntilReset) && (_jsx("span", Object.assign({ className: styles.subtitle }, { children: _t('loyalty.days_until_reset', { days: daysUntilReset }) })))] }))] })), _jsxs("div", Object.assign({ className: styles.progressWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.starsAndProgressLabelWrapper }, { children: [_jsx("span", Object.assign({ className: styles.progressText }, { children: _t('main.progress') })), _jsx("img", { src: starsIcon, alt: currentLevelName, className: styles.progressStars }), _jsxs("div", Object.assign({ className: styles.progressLabelWrapper }, { children: [_jsx("span", Object.assign({ className: classNames(styles.progressLabel, styles.progressLabelAccent) }, { children: numberToCompactNotation(totalCoinsMonth) })), isSet(coinsForNextLevel) && (_jsx("span", Object.assign({ className: styles.progressLabel }, { children: `/${numberToCompactNotation(coinsForNextLevel)}` })))] }))] })), _jsx("div", Object.assign({ className: styles.progressBarTrack }, { children: _jsx("div", { className: styles.progressBar, style: { width: coinsForNextLevel ? `${(totalCoinsMonth / coinsForNextLevel) * 100}%` : '100%' } }) }))] }))] })) })));
};
export default React.memo(LoyaltyStat);
