import moment from "moment/moment";
import * as Sentry from "@sentry/react";
export function aggregateCandles(candles, startMs, bucketSizeMins) {
    const result = [];
    const bucketSizeMs = bucketSizeMins * 60 * 1000;
    // Create buckets of bucketSizeMs
    for (let windowMs = startMs; windowMs < Date.now(); windowMs += bucketSizeMs) {
        const candlesForBucket = candles.filter(c => c.timestamp >= windowMs && c.timestamp < windowMs + bucketSizeMs - 1);
        const candle = aggregateIntoOneCandle(candlesForBucket, windowMs, windowMs + bucketSizeMs - 1);
        if (candle !== null) {
            result.push(candle);
        }
    }
    return result;
}
export function aggregateIntoOneCandle(candles, start, end) {
    if (candles.length < 1) {
        return null;
    }
    const firstCandle = candles[0];
    const lastCandle = candles[candles.length - 1];
    if (firstCandle === undefined) {
        throw new Error("First candle is undefined");
    }
    if (lastCandle === undefined) {
        throw new Error("Last candle is undefined");
    }
    const open = firstCandle.open;
    const close = lastCandle.close;
    let low = open;
    let high = open;
    candles.forEach(c => {
        if (c.low !== null && low !== null && c.low < low) {
            low = c.low;
        }
        if (c.high !== null && high !== null && c.high > high) {
            high = c.high;
        }
    });
    const timestamp = start;
    return { open, close, high, low, timestamp, start, end };
}
export function addQuoteToCandles(candles, quote, chartResolution, decimals) {
    var _a, _b;
    if (candles.length < 1) {
        return candles;
    }
    const lastCandle = candles[candles.length - 1];
    if (lastCandle === undefined) {
        throw new Error("Last candle is undefined");
    }
    const bucketSizeMs = resolutionToBucketSizeSeconds(chartResolution) * 1000;
    const value = (quote.ask + quote.bid) / 2;
    const formattedValue = value.toFixed(decimals);
    const endOfCurrentBucket = lastCandle.end;
    if (quote.timestamp >= endOfCurrentBucket) {
        if (quote.timestamp < endOfCurrentBucket + bucketSizeMs) {
            return [...candles, {
                    high: formattedValue,
                    low: formattedValue,
                    open: formattedValue,
                    close: formattedValue,
                    start: endOfCurrentBucket,
                    end: endOfCurrentBucket + bucketSizeMs
                }];
        }
        else if (bucketSizeMs === 0) {
            return [...candles, {
                    high: formattedValue,
                    low: formattedValue,
                    open: formattedValue,
                    close: formattedValue,
                    start: quote.timestamp,
                    end: quote.timestamp
                }];
        }
        else {
            Sentry.captureException(new Error("Quote received outside of current bucket & next bucket, not appending to candles"));
            console.log("Quote received outside of current bucket & next bucket, not appending to candles");
            return candles;
        }
    }
    else {
        const oldCandlesWithoutLast = candles.slice(0, candles.length - 1);
        const lastCandle = candles[candles.length - 1];
        if (lastCandle === undefined) {
            throw new Error("Last candle is undefined");
        }
        return [...oldCandlesWithoutLast, {
                high: lastCandle.high === null ? formattedValue : Math.max(Number(lastCandle.high), value).toFixed(decimals),
                low: lastCandle.low === null ? formattedValue : Math.min(Number(lastCandle.low), value).toFixed(decimals),
                open: (_a = lastCandle.open) !== null && _a !== void 0 ? _a : formattedValue,
                close: formattedValue,
                start: (_b = lastCandle.start) !== null && _b !== void 0 ? _b : formattedValue,
                end: lastCandle.end
            }];
    }
}
export function resolutionToBucketSizeSeconds(chartResolution) {
    if (chartResolution === "1D") {
        return 24 * 60 * 60;
    }
    return Number(chartResolution) * 60;
}
export function resolutionToStartDate(chartResolution) {
    switch (chartResolution) {
        case "0":
            return moment().subtract(0.5, "hour").toDate();
        case "1":
            return moment().subtract(2, "day").toDate();
        case "5":
            return moment().subtract(10, "day").toDate();
        case "15":
            return moment().subtract(15, "day").toDate();
        case "30":
            return moment().subtract(1, "month").toDate();
        case "60":
            return moment().subtract(2, "month").toDate();
        case "240":
            return moment().subtract(4, "month").toDate();
        case "1D":
            return moment().subtract(1, "year").toDate();
        default:
            throw Error(`Unsupported resolution ${chartResolution}`);
    }
}
