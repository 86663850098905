import { useEffect } from 'react';
const useScript = (scriptContent) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = scriptContent;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    });
};
export default useScript;
