import { CURRENT_COMPETITION_REQUESTED, currentCompetitionReject, currentCompetitionSuccess, getLeaderboardRankings, JOIN_COMPETITION_REQUESTED, joinCompetitionReject, joinCompetitionSuccess, LEADERBOARD_RANKING_REFRESH_REQUEST, LEADERBOARD_RANKING_REQUEST, leaderboardRankingReject, leaderboardRankingSuccess, RECEIVED_COMPETITION_RANK_UPDATE, setLeaderboardUserDetails } from '../actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getCurrentCompetition, getRanks, joinCompetition } from '../services/gatewayService';
function* joinCompetitionSaga(action) {
    const { nickname } = action.payload;
    try {
        // @ts-ignore
        const data = yield call(joinCompetition, nickname);
        const { rank, points } = data.participation;
        yield put(joinCompetitionSuccess(data));
        yield put(setLeaderboardUserDetails(rank, nickname, points, null));
    }
    catch (e) {
        yield put(joinCompetitionReject(e));
    }
}
function* currentCompetitionSaga(action) {
    try {
        // @ts-ignore
        const data = yield call(getCurrentCompetition);
        yield put(currentCompetitionSuccess(data));
        if (data.participation) {
            const { rank, nickname, points } = data.participation;
            yield put(setLeaderboardUserDetails(rank, nickname, points, null));
        }
    }
    catch (e) {
        console.log(e);
        yield put(currentCompetitionReject(e));
    }
}
function* ranksSaga(action) {
    try {
        // @ts-ignore
        const data = yield call(getRanks);
        yield put(leaderboardRankingSuccess(data.ranks));
    }
    catch (e) {
        yield put(leaderboardRankingReject(e));
    }
}
function* competitionRankSaga(action) {
    const { rank, previousRank, nickname, points } = action.payload;
    let change = null;
    if (!!previousRank && previousRank > rank) {
        change = 'up';
    }
    else if (!!previousRank && previousRank < rank) {
        change = 'down';
    }
    yield put(setLeaderboardUserDetails(rank, nickname, points, change));
    const rightSideWidget = yield select((state) => state.tradingScreen.rightSideWidget);
    const isLeaderboardWidgetActive = rightSideWidget === 'leaderboard';
    const isLeaderboardRouteActive = window.location.pathname.includes('leaderboard');
    if (isLeaderboardRouteActive || isLeaderboardWidgetActive) {
        yield put(getLeaderboardRankings());
    }
}
function* leaderboardSaga() {
    yield all([
        takeLatest(JOIN_COMPETITION_REQUESTED, joinCompetitionSaga),
        takeLatest(CURRENT_COMPETITION_REQUESTED, currentCompetitionSaga),
        takeLatest([LEADERBOARD_RANKING_REQUEST, LEADERBOARD_RANKING_REFRESH_REQUEST], ranksSaga),
        takeLatest(RECEIVED_COMPETITION_RANK_UPDATE, competitionRankSaga)
    ]);
}
export default leaderboardSaga;
