import { useMediaQuery } from 'react-responsive';

export const useIsDesktop = () => {
	return useMediaQuery({ minWidth: 992 });
};

export const useIsTablet = () => {
	return useMediaQuery({ maxWidth: 991 });
};

export const isTabletOrDesktop = () => {
	return useMediaQuery({ minWidth: 768 });
};

export const useIsMobile = () => {
	return useMediaQuery({ maxWidth: 767 });
};

export const useIsTallScreen = () => {
	return useMediaQuery({ minHeight: 800 });
};

export const Desktop = ({ children }) => {
	const isDesktop = useIsDesktop();
	return isDesktop ? children : null;
};

export const Mobile = ({ children }) => {
	const isMobile = useIsMobile();
	return isMobile ? children : null;
};

export const TabletDown = ({ children }) => {
	const isTablet = useIsTablet();
	return isTablet ? children : null;
};

export const TabletUp = ({ children }) => {
	const isTabletOrDesktop = useMediaQuery({ minWidth: 768 });
	return isTabletOrDesktop ? children : null;
};

export const TallScreen = ({ children }) => {
	const isTallScreen = useIsTallScreen();
	return isTallScreen ? children : null;
};
