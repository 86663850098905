var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './ResizableContainer.module.scss';
import { ResizableBox } from 'react-resizable';
import classNames from 'classnames';
import ResizableHandle from 'components/ResizableHandle/ResizableHandle';
const ResizableContainer = (_a) => {
    var { children, handlePlacement, className, disabled = false, onResize, onResizeStart, onResizeStop, resizeHandles, handle } = _a, rest = __rest(_a, ["children", "handlePlacement", "className", "disabled", "onResize", "onResizeStart", "onResizeStop", "resizeHandles", "handle"]);
    if (disabled) {
        return children;
    }
    return (_jsx(ResizableBox, Object.assign({ className: classNames(styles.resizableContainer, className, {
            [styles.resizableContainerLeft]: handlePlacement === 'w',
            [styles.resizableContainerRight]: handlePlacement === 'e',
            [styles.resizableContainerTop]: handlePlacement === 'n',
            [styles.resizableContainerBottom]: handlePlacement === 's',
        }), handle: (resizeHandle, ref) => _jsx(ResizableHandle, { ref: ref, resizeHandle: resizeHandle }), onResize: onResize, onResizeStart: onResizeStart, onResizeStop: onResizeStop }, rest, { resizeHandles: [handlePlacement] }, { children: children })));
};
export default React.memo(ResizableContainer);
