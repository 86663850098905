import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './MobileScreenLayout.module.scss';
import classNames from 'classnames';
import TitleHeader from 'components/TitleHeader/TitleHeader';
const MobileScreenLayout = ({ children, title, onBackClick, endElements, className, padded }) => {
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, className, {
            [styles.containerPadded]: padded,
        }) }, { children: [title !== undefined && _jsx(TitleHeader, { title: title, onBackClick: onBackClick, endElements: endElements }), _jsx("div", Object.assign({ className: styles.contentWrapper }, { children: children }))] })));
};
export default React.memo(MobileScreenLayout);
