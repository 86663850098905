import { all, put, select, take, takeLatest } from "redux-saga/effects";
import { HISTORY_QUOTES_RECEIVED, HISTORY_UPDATED, INSTRUMENTS_SET_ACTIVE, requestHistoryQuotes, SET_CHART_RESOLUTION, setHistoryQuotesLoading, updateHistory, USER_SAVE_CHART } from "../actions";
import quotesHelpers from "../utils/quotesHelpers";
import { resolutionToStartDate } from "../utils/aggregateCandles";
function* fetchHistory(action) {
    const symbol = yield select((state) => { var _a; return (_a = state.activeInstrument) === null || _a === void 0 ? void 0 : _a.symbol; });
    const chartResolution = yield select((state) => state.settings.chartResolution);
    const startDate = resolutionToStartDate(chartResolution);
    if (!symbol) {
        throw new Error('No active instrument in fetchHistory saga');
    }
    yield put(requestHistoryQuotes(symbol, startDate.toISOString(), new Date().toISOString(), chartResolution));
    yield put(setHistoryQuotesLoading());
    yield take(HISTORY_UPDATED);
}
function* updateHistoryQuotes(action) {
    const data = action.payload;
    const chartResolution = yield select(state => state.settings.chartResolution);
    const activeInstrumentSymbol = yield select(state => { var _a; return (_a = state.activeInstrument) === null || _a === void 0 ? void 0 : _a.symbol; });
    // this is to prevent a race condition where the user changes the chart resolution or instrument while the history is loading
    if (data.symbol !== activeInstrumentSymbol || data.resolution !== chartResolution) {
        return;
    }
    const sanitizedData = quotesHelpers.sanitizeQuotesDates(data);
    yield put(updateHistory(sanitizedData));
}
function* historySaga() {
    yield all([
        takeLatest([
            INSTRUMENTS_SET_ACTIVE,
            SET_CHART_RESOLUTION,
            USER_SAVE_CHART
        ], fetchHistory),
        takeLatest(HISTORY_QUOTES_RECEIVED, updateHistoryQuotes)
    ]);
}
export default historySaga;
