var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gatewayService, store } from 'wintrado-api';
const subscriptions = {};
export const analysisChartDatafeed = {
    onReady: (callback) => {
        const configurationData = {
            supports_search: false,
            supports_group_request: false,
            supports_marks: false,
            supports_timescale_marks: false,
            supports_time: false,
            exchanges: [{ value: '', name: 'All Exchanges', desc: '' }],
            symbols_types: [
                { name: 'All types', value: '' },
                { name: 'Stock', value: 'equities' },
                { name: 'Crypto', value: 'cryptos' },
                { name: 'Forex', value: 'currencies' },
                { name: 'Index', value: 'indices' },
                { name: 'Commodity', value: 'commodities' },
            ],
            supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D'],
        };
        setTimeout(() => callback(configurationData));
    },
    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield gatewayService.getInstruments(userInput, symbolType, exchange);
            onResultReadyCallback(response.instruments);
        }
        catch (error) {
            onResultReadyCallback([]);
        }
    }),
    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield gatewayService.getInstrument(symbolName);
            onSymbolResolvedCallback(response.instrument);
        }
        catch (error) {
            onResolveErrorCallback(error.message);
        }
    }),
    getBars: (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => __awaiter(void 0, void 0, void 0, function* () {
        const start = new Date(periodParams.from * 1000).toISOString();
        const end = new Date(periodParams.to * 1000).toISOString();
        try {
            const response = yield gatewayService.getCandlesForSymbol(symbolInfo.name, start, end, resolution, periodParams.countBack);
            const bars = sanitizeAndConvertCandlesToBars(response.candles);
            onHistoryCallback(bars, { noData: response.noData, nextTime: response.nextTime });
        }
        catch (e) {
            onErrorCallback(e.message);
        }
        /*let dataSent = false;

    const unsubscribe = store.subscribe(() => {
      const state = store.getState();

      if (!state.candles.loading && !dataSent) {
        const bars = state.candles.data.map(convertCandleToBar);
        onHistoryCallback(bars);
        dataSent = true;
      }
    });

    setTimeout(() => {
      unsubscribe()
    }, 5000);*/
    }),
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        let lastSymbolName = null;
        let lastBar = null;
        const unsubscribe = store.subscribe(() => {
            const state = store.getState();
            // If the symbol changes or lastBar is null, clear the last bar and return the last candle.
            if ((lastSymbolName !== null && lastSymbolName !== symbolInfo.name) || lastBar === null) {
                lastBar = null;
                lastSymbolName = symbolInfo.name;
                const lastCandle = state.candles.data[state.candles.data.length - 1];
                if (lastCandle !== undefined) {
                    lastBar = convertCandleToBar(lastCandle);
                    // nasty hotfix - replace this ASAP
                    if (lastBar.high !== 0 && lastBar.low !== 0 && lastBar.open !== 0 && lastBar.close !== 0) {
                        onRealtimeCallback(lastBar);
                    }
                }
            }
            else {
                // If the symbol does not change, return only the ones with the same or newer 'start' value than the ones saved in the last bar.
                const newCandles = state.candles.data.filter((candle) => candle.start >= lastBar.time);
                if (newCandles.length > 0) {
                    lastBar = convertCandleToBar(newCandles[newCandles.length - 1]);
                    newCandles.forEach((candle) => {
                        const bar = convertCandleToBar(candle);
                        // nasty hotfix - replace this ASAP
                        if (bar.high !== 0 && bar.low !== 0 && bar.open !== 0 && bar.close !== 0) {
                            onRealtimeCallback(bar);
                        }
                    });
                }
            }
        });
        // Remember the symbol
        lastSymbolName = symbolInfo.name;
        subscriptions[subscriberUID] = unsubscribe;
    },
    unsubscribeBars: (subscriberUID) => {
        var _a;
        if (subscriptions[subscriberUID]) {
            (_a = subscriptions[subscriberUID]) === null || _a === void 0 ? void 0 : _a.call(subscriptions);
        }
    },
};
const sanitizeAndConvertCandlesToBars = (candles) => {
    return candles.reduce((acc, candle) => {
        if (candle.open !== null && candle.high !== null && candle.low !== null && candle.close !== null) {
            acc.push(convertCandleToBar(candle));
        }
        return acc;
    }, []);
};
const convertCandleToBar = (candle) => {
    return {
        time: candle.start,
        open: Number(candle.open),
        high: Number(candle.high),
        low: Number(candle.low),
        close: Number(candle.close),
    };
};
