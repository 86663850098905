import { ADD_PRICE_ALERT, REMOVE_PRICE_ALERT_AT_INDEX } from "../../actions";
function priceAlertReducer(state, action) {
    switch (action.type) {
        case ADD_PRICE_ALERT:
            return (Object.assign(Object.assign({}, state), { priceAlerts: [
                    ...state.priceAlerts,
                    action.payload.priceAlertMessage,
                ] }));
        case REMOVE_PRICE_ALERT_AT_INDEX:
            return (Object.assign(Object.assign({}, state), { priceAlerts: state.priceAlerts.filter((_, index) => index !== action.payload.index) }));
        default:
            return state;
    }
}
export default priceAlertReducer;
