import { INSTRUMENTS_SET_ACTIVE, INSTRUMENTS_UPDATED } from '../../actions';
export default function instrumentsReducer(state, action) {
    switch (action.type) {
        case INSTRUMENTS_UPDATED:
            return Object.assign(Object.assign({}, state), { instruments: action.payload.instruments, instrumentsTimezone: action.payload.timezone });
        case INSTRUMENTS_SET_ACTIVE: {
            const activeInstrument = state.instruments[action.payload];
            if (!activeInstrument) {
                throw new Error('Failed to set active instrument because it cannot be found by symbol ' + action.payload);
            }
            const activeInstrumentQuotes = state.quotes[activeInstrument.symbol];
            return Object.assign(Object.assign({}, state), { activeInstrument, activeInstrumentSymbol: action.payload, tradingEnabled: !!activeInstrumentQuotes });
        }
        default:
            return state;
    }
}
