import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import config from 'config';
import { loadLiveChat, requestOAuthLogin } from '../../../helpers';
import _t from 'counterpart';
import { useAppSelector } from '../../../storeHooks';
import Button from '../Button/Button';
import { OpenAccountButton } from '../main/OpenAccountButton';
const LoginForm = () => {
    const userLanguage = useAppSelector((state) => state.settings.language);
    const handleLogin = (event) => {
        event.preventDefault();
        event.stopPropagation();
        requestOAuthLogin(userLanguage);
    };
    const handleHelp = (event) => {
        event.preventDefault();
        loadLiveChat();
    };
    return (_jsxs("div", Object.assign({ className: "close-login login__form form" }, { children: [_jsx("div", Object.assign({ className: "login__title" }, { children: _jsx("h1", { children: _t.translate('login.title') }) })), _jsxs("div", Object.assign({ className: "login__submit" }, { children: [_jsx(Button, Object.assign({ green: true, fullWidth: true, onClick: handleLogin }, { children: _t.translate('login.submit') })), Boolean(config.cashier.registrationLink) && (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "login__button_separator_label" }, { children: _t.translate('main.or') })), _jsx(OpenAccountButton, { fullWidth: true })] }))] })), _jsx("div", Object.assign({ className: "login__links" }, { children: Boolean(config.zendeskChatKey) && (_jsx("div", Object.assign({ className: "login__help" }, { children: _jsx("a", Object.assign({ href: "apps/webtrader-pro/src/old/components/login#", className: "help", onClick: handleHelp }, { children: _t.translate('main.help') })) }))) }))] })));
};
export default LoginForm;
