import { SET_INSTRUMENTS_VALID_TRADING_INTERVALS, SET_POSITIONS_TIME_LEFT } from '../../actions';
export default function timeCalculationsReducer(state, action) {
    switch (action.type) {
        case SET_INSTRUMENTS_VALID_TRADING_INTERVALS:
            return Object.assign(Object.assign({}, state), { timeCalculations: Object.assign(Object.assign({}, state.timeCalculations), { instrumentsValidTradingIntervals: action.payload.instrumentsValidTradingIntervals }) });
        case SET_POSITIONS_TIME_LEFT:
            return Object.assign(Object.assign({}, state), { timeCalculations: Object.assign(Object.assign({}, state.timeCalculations), { positionsTimeLeft: action.payload.positionsTimeLeft }) });
        default:
            return state;
    }
}
