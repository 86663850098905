var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InstallCTA from './old/components/main/InstallCTA';
import styles from './Main.module.scss';
import _t from 'counterpart';
import $ from 'jquery';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import config from 'config';
import LoginGrid from './old/components/LoginGrid.tsx';
import { actions } from 'wintrado-api';
import ErrorMessage from './old/components/main/ErrorMessage';
import ModalForceLogout from './old/components/modal/ForceLogout';
import { hideZendeskBubble, injectZendeskScript, isNotChrome, isStandalone, releaseVersion, requestOAuthLogin, setReferralParam, } from './helpers';
import { OfflineNotice } from './old/components/global/OfflineNotice';
import * as Sentry from '@sentry/react';
import PopupNotifications from './old/components/PopupNotification/PopupNotifications';
import PopupNotificationManager from './old/components/PopupNotification/PopupNotificationManager';
import DebugPopup from './old/components/main/DebugPopup';
import PopupAlert from './old/components/PopupAlert/PopupAlert';
import PopupAlertManager from './old/components/PopupAlert/PopupAlertManager';
import LoginLoader from './old/components/LoginLoader';
import { MaintenanceScreen } from './old/components/global/MaintenanceScreen';
import { setMomentLocale } from 'utils/locales';
import PoorConnection from './old/components/PoorConnection/PoorConnection';
import { useAppDispatch, useAppSelector } from './storeHooks';
import { useQueryParams } from './hooks';
import MainLayout from './layout/MainLayout/MainLayout';
import { ToastProvider, ToastViewport } from '@radix-ui/react-toast';
import PriceAlertToasts from 'components/PriceAlertToasts/PriceAlertToasts';
import { toastDefaultDuration } from 'utils/contants';
import { setDefaultLocale } from 'react-datepicker';
import { ToastContextProvider } from 'components/Toast/ToastContext';
import ErrorScreen from 'components/ErrorScreen/ErrorScreen';
import { setSagaErrorLogger } from 'wintrado-api/src/utils/logger';
import { ErrorBoundary } from '@sentry/react';
require('normalize.css/normalize.css');
require('sass/v3/app.scss');
require(`sass/themes/${__BRAND__}.scss`);
const Main = () => {
    const [locale, setLocale] = useState(_t.getLocale());
    const [hasError, setHasError] = useState(false);
    const queryParams = useQueryParams();
    const error = useAppSelector((state) => state.error);
    const autoLogin = useAppSelector((state) => state.settings.autoLogin);
    const loggedIn = useAppSelector((state) => state.loggedIn);
    const token = useAppSelector((state) => state.user.token);
    const refreshToken = useAppSelector((state) => state.user.refreshToken);
    const loggingIn = useAppSelector((state) => state.events.loggingIn);
    const dontShowChromeInstallCTA = useAppSelector((state) => state.settings.dontShowChromeInstallCTA);
    const language = useAppSelector((state) => state.settings.language);
    const isOnline = useAppSelector((state) => state.connection.online);
    const remember = useAppSelector((state) => state.settings.remember);
    const apiError = useAppSelector((state) => state.connection.apiError);
    const apiErrorReason = useAppSelector((state) => state.connection.apiErrorReason);
    const balance = useAppSelector((state) => state.user.balance);
    const maintenanceMode = useAppSelector((state) => state.maintenanceMode);
    const loginTimedOut = useAppSelector((state) => state.events.loginTimedOut);
    const connectionReady = useAppSelector((state) => state.connection.ready);
    const loading = useAppSelector((state) => state.loading);
    const dispatch = useAppDispatch();
    const logError = (error, errorInfo) => {
        if (process.env.NODE_ENV === 'development') {
            console.error(error);
            console.error(errorInfo);
        }
        else {
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo);
                Sentry.captureException(error);
            });
        }
    };
    const handleErrorReceived = () => {
        let showError = true;
        if (error.type !== null && error.type.toLowerCase() === 'outside_of_trading_hours') {
            showMarketClosedError();
            showError = false;
        }
        return (_jsx(ErrorMessage, { visible: error.type !== null && showError, title: error.type, message: error.message, extra: error.extra }));
    };
    const showMarketClosedError = () => {
        const modal = $('#market_closed_error_modal');
        modal.modal('show');
    };
    const shouldAutoLogin = useMemo(() => {
        return (!loggingIn &&
            autoLogin === true &&
            (token !== null || refreshToken !== null) &&
            !queryParams.demo &&
            !loggedIn &&
            !(queryParams.code && queryParams.state));
    }, [autoLogin, loggedIn, loggingIn, queryParams.code, queryParams.demo, queryParams.state, refreshToken, token]);
    const autoLoginIfNeeded = useCallback(() => {
        if (shouldAutoLogin) {
            dispatch(actions.attemptAutoLogin());
        }
    }, [dispatch, shouldAutoLogin]);
    const reload = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (navigator && navigator.serviceWorker) {
            const registrations = yield navigator.serviceWorker.getRegistrations();
            registrations.forEach((registration) => {
                registration.unregister();
            });
        }
        // @ts-ignore
        window.location.reload(true);
    });
    const openChromeDownloadPage = (e) => {
        e.preventDefault();
        window.open('https://google.com/chrome');
    };
    const setDontShowChromeInstallCTA = (e) => {
        e.preventDefault();
        dispatch(actions.setDontShowChromeInstallCTA());
    };
    useEffect(() => {
        if (isNotChrome() && !dontShowChromeInstallCTA) {
            PopupNotificationManager.showNotification({
                id: 'chrome',
                primaryButtonTitleKey: 'main.download',
                onPrimaryButtonClicked: openChromeDownloadPage,
                secondaryButtonTitleKey: 'main.do_not_show_again',
                onSecondaryButtonClicked: setDontShowChromeInstallCTA,
                descriptionKey: 'main.chrome_installcta_description',
                descriptionExtra: { appName: config.appName },
            });
        }
        else {
            PopupNotificationManager.hideNotification('chrome');
        }
    });
    useEffect(() => {
        setSagaErrorLogger((error, info) => {
            logError(error, info);
            setHasError(true);
        });
    }, []);
    useEffect(() => {
        const handleVisibilityChange = (e) => {
            if (!e.target.hidden) {
                autoLoginIfNeeded();
            }
            if (isStandalone()) {
                if (e.target.hidden) {
                    if (!['withdraw', 'deposit'].some((item) => window.location.pathname.includes(item))) {
                        dispatch(actions.logoff());
                    }
                }
                else {
                    autoLoginIfNeeded();
                }
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [autoLoginIfNeeded, dispatch]);
    useEffect(() => {
        dispatch(actions.setReleaseVersion(releaseVersion()));
        if (queryParams.demo) {
            Sentry.captureException(new Error('Currently ignoring demo query param'));
        }
        if (queryParams.ref && !Array.isArray(queryParams.ref)) {
            setReferralParam(queryParams.ref);
        }
        if (queryParams.request_login) {
            requestOAuthLogin(language);
        }
        if (queryParams.code && queryParams.state && !Array.isArray(queryParams.code)) {
            if (window.sessionStorage.getItem('state') === queryParams.state) {
                dispatch(actions.requestOAuthTokens(queryParams.code, window.location.origin));
                history.replaceState({}, document.title, '/');
            }
        }
        if (queryParams.instrument && !Array.isArray(queryParams.instrument)) {
            dispatch(actions.setInstrumentToPreselect(queryParams.instrument));
        }
        autoLoginIfNeeded();
        if (isStandalone()) {
            dispatch(actions.updateUser({ standalone: isStandalone() }));
        }
        if (Boolean(config.zendeskChatKey) && !queryParams.login && !shouldAutoLogin) {
            injectZendeskScript();
            hideZendeskBubble();
        }
        return () => {
            PopupNotificationManager.unregisterContainer();
        };
        // We intentionally don't want to add deps here - we want this effect to effectively run as componentDidMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!remember) {
            dispatch(actions.userClearCredentials());
        }
        if (!loggedIn) {
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');
        }
    }, [dispatch, loggedIn, remember]);
    useEffect(() => {
        const setLanguage = (lang) => {
            if (locale !== lang) {
                _t.setLocale(lang);
                setLocale(_t.getLocale());
                setMomentLocale(lang);
                setDefaultLocale(lang);
            }
        };
        setLanguage(language);
    }, [language, locale]);
    useEffect(() => {
        if (apiError && apiErrorReason) {
            logError(apiErrorReason, { type: 'ApiError' });
            setHasError(true);
        }
    }, [apiError, apiErrorReason]);
    if (hasError) {
        return _jsx(ErrorScreen, {});
    }
    if (maintenanceMode) {
        return _jsx(MaintenanceScreen, {});
    }
    if (loginTimedOut) {
        return _jsx(PoorConnection, {});
    }
    if (loggingIn || (loggedIn && !connectionReady)) {
        return _jsx(LoginLoader, {});
    }
    return (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(ErrorScreen, {}) }, { children: _jsx(ToastProvider, Object.assign({ duration: toastDefaultDuration }, { children: _jsxs(ToastContextProvider, { children: [_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(InstallCTA, {}), loggedIn && connectionReady ? _jsx(MainLayout, {}, locale) : _jsx(LoginGrid, {}, locale), _jsx(ModalForceLogout, {}), _jsx("div", { id: "modal-container" }), loading && _jsx(LoginLoader, {}), handleErrorReceived(), _jsx(OfflineNotice, { visible: !isOnline }), _jsx(DebugPopup, {}), _jsx(PopupNotifications, { ref: (ref) => {
                                    if (ref) {
                                        PopupNotificationManager.registerContainer(ref);
                                    }
                                } }), _jsx(PopupAlert, { ref: (ref) => {
                                    if (ref) {
                                        PopupAlertManager.registerContainer(ref);
                                    }
                                } })] })), _jsx(ToastViewport, { className: styles.toastViewport }), _jsx(PriceAlertToasts, {})] }) })) })));
};
export default React.memo(Main);
