import { DEFAULT_VERSION } from 'redux-persist/es/constants';
export default function customCreateMigrate(migrations, config) {
    let { debug } = config || {};
    return function (state, currentVersion) {
        if (!state) {
            if (process.env.NODE_ENV !== 'production' && debug)
                console.log('redux-persist: no inbound state, skipping migration');
            // @ts-ignore
            return Promise.resolve(undefined);
        }
        let inboundVersion = state._persist && state._persist.version !== undefined
            ? state._persist.version
            : DEFAULT_VERSION;
        if (inboundVersion === currentVersion) {
            if (process.env.NODE_ENV !== 'production' && debug)
                console.log('redux-persist: versions match, noop migration');
            return Promise.resolve(state);
        }
        if (inboundVersion > currentVersion) {
            if (process.env.NODE_ENV !== 'production')
                console.error('redux-persist: downgrading version is not supported');
            return Promise.resolve(state);
        }
        let migrationKeys = Object.keys(migrations)
            .map(ver => parseInt(ver))
            .filter(key => currentVersion >= key && key > inboundVersion)
            .sort((a, b) => a - b);
        if (process.env.NODE_ENV !== 'production' && debug)
            console.log('redux-persist: migrationKeys', migrationKeys);
        try {
            return migrationKeys.reduce((promiseState, versionKey) => {
                if (process.env.NODE_ENV !== 'production' && debug)
                    console.log('redux-persist: running migration for versionKey', versionKey);
                return promiseState.then(state => Promise.resolve(migrations[versionKey](state)));
            }, Promise.resolve(state));
        }
        catch (err) {
            return Promise.reject(err);
        }
    };
}
