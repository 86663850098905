import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './SidebarNavItem.module.scss';
import AnyLink from 'components/AnyLink/AnyLink';
import Spacer from 'components/Spacer/Spacer';
import _t from 'counterpart';
import classNames from 'classnames';
const SidebarNavItem = ({ to, exact = false, icon, labelKey, isCollapsed = false, onClick }) => {
    return (_jsxs(AnyLink, Object.assign({ exact: exact, to: to, onClick: onClick, className: classNames(styles.link, {
            [styles.linkCollapsed]: isCollapsed,
        }), activeClassName: styles.linkActive }, { children: [icon, _jsx(Spacer, { x: "medium" }), _t(labelKey)] })));
};
export default React.memo(SidebarNavItem);
