import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
const _t = require('counterpart');
import { formatCurrency } from 'utils/currency';
import { formatDateTime } from '../../../../helpers';
import styles from './AccountStatement.module.scss';
const TransactionCard = ({ createdAt, type, comment, amount }) => {
    return (_jsx("table", Object.assign({ className: styles.transactionCardTable }, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", Object.assign({ className: styles.cardLabel }, { children: _t('account_statement.created-at') })), _jsx("td", Object.assign({ className: styles.cardValue }, { children: formatDateTime(createdAt) }))] }), _jsxs("tr", { children: [_jsx("td", Object.assign({ className: styles.cardLabel }, { children: _t('account_statement.type') })), _jsx("td", Object.assign({ className: styles.cardValue }, { children: type }))] }), _jsxs("tr", { children: [_jsx("td", Object.assign({ className: styles.cardLabel }, { children: _t('account_statement.description') })), _jsx("td", Object.assign({ className: styles.cardValue }, { children: comment }))] }), _jsxs("tr", { children: [_jsx("td", Object.assign({ className: styles.cardLabel }, { children: _t('account_statement.amount') })), _jsx("td", Object.assign({ className: styles.cardValue }, { children: formatCurrency(Number(amount), 'USD') }))] })] }) })));
};
export default React.memo(TransactionCard);
