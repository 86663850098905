import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import styles from './ToggleGroup.module.scss';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import classNames from 'classnames';
import { isSet } from '../../helpers';
import FormLabel from 'components/FormLabel/FormLabel';
const ToggleGroup = React.forwardRef(({ items, value, defaultValue, onValueChange, label, className, variant, flexible = false, disabled = false }, ref) => {
    const handleValueChange = useCallback((value) => {
        // makes sure that something is selected
        if (value) {
            onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(value);
        }
    }, [onValueChange]);
    return (_jsxs(_Fragment, { children: [isSet(label) && _jsx(FormLabel, { children: label }), _jsx(ToggleGroupPrimitive.Root, Object.assign({ ref: ref, className: classNames(styles.container, className, {
                    [styles.containerPrimary]: variant === 'primary',
                    [styles.containerSecondary]: variant === 'secondary',
                    [styles.containerBuySell]: variant === 'buy-sell',
                    [styles.containerFlexible]: flexible,
                    [styles.containerDisabled]: disabled,
                }), type: "single", defaultValue: defaultValue, value: value, onValueChange: handleValueChange, "aria-label": label, disabled: disabled }, { children: items.map((item) => (_jsx(ToggleGroupPrimitive.Item, Object.assign({ className: classNames(styles.item, item.className), value: item.value, "aria-label": item.label }, { children: item.label.toUpperCase() }), item.value))) }))] }));
});
export default React.memo(ToggleGroup);
