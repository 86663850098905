import React from 'react';
import _t from 'counterpart';
import * as Sentry from '@sentry/react';
import { useAppSelector } from '../../../storeHooks';

const DebugPopup = () => {
	const debugEnabled = useAppSelector((state) => state.settings.features.debugEnabled);
	const reportToSentry = () => {
		Sentry.captureMessage('Debug report');
		alert(_t.translate('main.report_sent'));
	};

	if (!debugEnabled) {
		return null;
	}

	return (
		<>
			<div className="debug-popup-overlay" />
			<div className="debug-popup">
				<div className="button" onClick={reportToSentry}>
					<i className="material-icons">error_outline</i>
					<span>{_t.translate('main.report')}</span>
				</div>
			</div>
		</>
	);
};

export default React.memo(DebugPopup);
