import devLocal from './devlocal';
import dev from './dev';
import dist from './dist';
import staging from './staging';
const configMap = {
    'devlocal': devLocal,
    dev,
    dist,
    staging,
};
const config = configMap[WEBPACK_ENV];
if (!config) {
    throw Error(`Invalid WEBPACK_ENV: ${WEBPACK_ENV}`);
}
export default config;
