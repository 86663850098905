import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import _t from 'counterpart';
import IconButton from 'components/IconButton/IconButton';
import { FiCrosshair } from 'react-icons/fi';
import Tooltip from 'components/Tooltip/Tooltip';
const CrosshairToggleButton = ({ isActive, fieldId, onCrosshairClickedForField }) => {
    const handleCrosshairClickedForField = () => {
        onCrosshairClickedForField(fieldId);
    };
    return (_jsx(Tooltip, Object.assign({ content: _t('order.crosshair_mode_tooltip') }, { children: _jsx(IconButton, Object.assign({ onClick: handleCrosshairClickedForField, size: 'small', type: 'button', isActive: isActive }, { children: _jsx(FiCrosshair, {}) })) })));
};
export default React.memo(CrosshairToggleButton);
