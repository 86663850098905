var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './Toast.module.scss';
import * as ToastPrimitive from '@radix-ui/react-toast';
import IconButton from 'components/IconButton/IconButton';
import { FiAlertCircle, FiCheckCircle, FiX, FiXCircle } from 'react-icons/fi';
import classNames from 'classnames';
const TOAST_ANIMATION_DURATION = 200;
const Toast = (_a) => {
    var { id, title, description, children, actionAltText, open, defaultOpen, onOpenChange, variant = 'success', icon } = _a, props = __rest(_a, ["id", "title", "description", "children", "actionAltText", "open", "defaultOpen", "onOpenChange", "variant", "icon"]);
    const timeoutRef = React.useRef(null);
    const iconBasedOnVariant = useMemo(() => {
        if (variant === 'custom') {
            return icon;
        }
        if (variant === 'success') {
            return _jsx(FiCheckCircle, { className: styles.icon });
        }
        if (variant === 'error') {
            return _jsx(FiXCircle, { className: styles.icon });
        }
        if (variant === 'warning') {
            return _jsx(FiAlertCircle, { className: styles.icon });
        }
    }, [icon, variant]);
    const handleOpenChange = useCallback((open) => {
        timeoutRef.current = setTimeout(() => {
            if (id !== undefined) {
                onOpenChange === null || onOpenChange === void 0 ? void 0 : onOpenChange(open, id);
            }
            else {
                onOpenChange === null || onOpenChange === void 0 ? void 0 : onOpenChange(open);
            }
        }, TOAST_ANIMATION_DURATION);
    }, [id, onOpenChange]);
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);
    return (_jsx(_Fragment, { children: _jsxs(ToastPrimitive.Root, Object.assign({ defaultOpen: defaultOpen, open: open, onOpenChange: handleOpenChange, className: classNames(styles.toast, {
                [styles.toastSuccess]: variant === 'success',
                [styles.toastError]: variant === 'error',
                [styles.toastWarning]: variant === 'warning',
                [styles.toastCustom]: variant === 'custom',
            }) }, props, { children: [_jsx("div", Object.assign({ className: styles.iconWrapper }, { children: iconBasedOnVariant })), _jsxs("div", Object.assign({ className: styles.titleDescriptionWrapper }, { children: [_jsx(ToastPrimitive.Title, Object.assign({ className: styles.title }, { children: title })), description && (_jsx(ToastPrimitive.Description, Object.assign({ className: styles.description }, { children: description })))] })), children && (_jsx(ToastPrimitive.Action, Object.assign({ altText: actionAltText, className: styles.action }, { children: children }))), _jsx(ToastPrimitive.Close, Object.assign({ "aria-label": "Close", asChild: true }, { children: _jsx(IconButton, { children: _jsx(FiX, {}) }) }))] })) }));
};
export default React.memo(Toast);
