import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Analytics } from 'wintrado-api';
const usePageTracking = () => {
    const location = useLocation();
    useEffect(() => {
        Analytics.captureEvent('$pageview');
    }, [location]);
};
export default usePageTracking;
