import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './MobilePlaceOrderSheet.module.scss';
import _t from 'counterpart';
import PlaceOrderForm from 'components/PlaceOrderForm/PlaceOrderForm';
import { useGoBackWithInternalFallback } from '../../hooks/useGoBackWithInternalFallback';
import MobileSheetLayout from '../../layout/MobileSheetLayout/MobileSheetLayout';
const MobilePlaceOrderSheet = () => {
    const goBack = useGoBackWithInternalFallback('/trading');
    return (_jsx(MobileSheetLayout, Object.assign({ className: styles.container, title: _t('main.place_order'), onClosed: goBack }, { children: _jsx(PlaceOrderForm, { onGoBack: goBack, shouldAutoFocusVolume: false }) })));
};
export default React.memo(MobilePlaceOrderSheet);
