import { isAxiosError } from 'axios';
export const shouldShowGlobalErrorFor = (e) => {
    var _a, _b, _c;
    if (e === null) {
        return false;
    }
    if (!isAxiosError(e)) {
        return true;
    }
    return Boolean(((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) !== 422 || ((_c = (_b = e.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.errors.some((error) => error.field === null)));
};
