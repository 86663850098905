import { all, takeLatest } from 'redux-saga/effects';
import { RECEIVED_TIMEOUT_ERROR, } from '../actions';
import * as Sentry from '@sentry/react';
const messagesRequiredForLogin = ['Login', 'HistoryQuotesResponse', 'PrimeQuotes'];
function* handleReceivedTimeoutError(action) {
    const { message } = action.payload;
    Sentry.addBreadcrumb({
        category: 'timeout-error',
        level: 'warning',
        data: message,
    });
    if (messagesRequiredForLogin.includes(message.originalMessage)) {
        throw Error(`[LOGIN FAILED] Internal timeout on message: ${message.originalMessage}`);
    }
}
function* timeoutSaga() {
    yield all([
        takeLatest(RECEIVED_TIMEOUT_ERROR, handleReceivedTimeoutError),
    ]);
}
export default timeoutSaga;
