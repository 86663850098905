import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import styles from './AnalysisChart.module.scss';
import { widget, } from '../../charting_library';
import { analysisChartDatafeed } from 'utils/analysisChartDataFeed';
import { useAppDispatch, useAppSelector } from '../../storeHooks';
import { setChartResolution } from 'wintrado-api/src/actions';
import { actions } from 'wintrado-api';
import _t from 'counterpart';
import { useHistory } from 'react-router-dom';
import { isSet } from '../../helpers';
import { useForwardRef } from '../../hooks';
const AnalysisChart = forwardRef(({ showPlaceOrderButton = false, showSymbolSelectButton = false, hideHeaderFullscreenbutton = false }, ref) => {
    const chartContainerRef = useForwardRef(ref);
    const initialActiveInstrument = useAppSelector((state) => state.activeInstrument, () => true);
    const initialActiveResolution = useAppSelector((state) => state.settings.chartResolution, () => true);
    const activeInstrument = useAppSelector((state) => state.activeInstrument);
    const activeResolution = useAppSelector((state) => state.settings.chartResolution);
    const crosshairPriceSelectionEnabled = useAppSelector((state) => state.proTradingScreen.crosshairPriceSelection.enabled);
    const dispatch = useAppDispatch();
    const tvWidgetRef = useRef(null);
    const placeOrderButtonRef = useRef(null);
    const history = useHistory();
    if (!initialActiveInstrument) {
        throw new Error('activeInstrument is not defined');
    }
    useEffect(() => {
        const disabledFeatures = [
            'header_screenshot',
            'use_localstorage_for_settings',
            'header_compare',
        ];
        if (!showSymbolSelectButton) {
            disabledFeatures.push('header_symbol_search');
        }
        if (hideHeaderFullscreenbutton) {
            disabledFeatures.push('header_fullscreen_button');
        }
        const widgetOptions = {
            symbol: initialActiveInstrument.symbol,
            interval: initialActiveResolution,
            library_path: '/charting_library/',
            fullscreen: false,
            autosize: true,
            theme: 'dark',
            datafeed: analysisChartDatafeed,
            container: chartContainerRef.current,
            locale: 'en',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            disabled_features: disabledFeatures,
            custom_css_url: '/custom_chart.css',
            overrides: {
                'paneProperties.backgroundType': 'solid',
                'paneProperties.background': '#21232B',
            },
        };
        const handleIntervalChanged = (interval) => {
            dispatch(setChartResolution(interval));
        };
        tvWidgetRef.current = new widget(widgetOptions);
        tvWidgetRef.current.onChartReady(() => {
            var _a;
            (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.activeChart().onIntervalChanged().subscribe(null, handleIntervalChanged);
        });
        return () => {
            var _a;
            (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.remove();
        };
    }, [initialActiveInstrument.symbol, initialActiveResolution, chartContainerRef, dispatch, showSymbolSelectButton]);
    useEffect(() => {
        const handleShowPlaceOrder = () => {
            history.push('/trading/place-order');
        };
        if (showPlaceOrderButton) {
            if (tvWidgetRef.current === null) {
                throw new Error('tvWidgetRef.current is null');
            }
            tvWidgetRef.current.onChartReady(() => {
                var _a;
                // this workaround is required due to the fact that TradingView does not handle removing buttons properly
                // thus leaving separators in the header if we try to remove the button and replace it with a new one
                if (placeOrderButtonRef.current) {
                    placeOrderButtonRef.current.addEventListener('click', handleShowPlaceOrder);
                }
                else {
                    (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.headerReady().then(() => {
                        var _a;
                        placeOrderButtonRef.current = (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.createButton({
                            align: 'right',
                            useTradingViewStyle: false,
                        });
                        if (isSet(placeOrderButtonRef.current)) {
                            placeOrderButtonRef.current.setAttribute('title', _t('main.click_to_place_order') + '(Ctrl+O)');
                            placeOrderButtonRef.current.classList.add('button');
                            placeOrderButtonRef.current.addEventListener('click', handleShowPlaceOrder);
                            placeOrderButtonRef.current.innerHTML = `+ ${_t('main.order')}`;
                        }
                    });
                }
            });
        }
        return () => {
            var _a;
            (_a = placeOrderButtonRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', handleShowPlaceOrder);
        };
    }, [dispatch, history, showPlaceOrderButton]);
    useEffect(() => {
        const handleGoToInstruments = (e) => {
            e.stopPropagation();
            history.push('/trading/instruments');
        };
        if (showSymbolSelectButton) {
            if (tvWidgetRef.current === null) {
                throw new Error('tvWidgetRef.current is null');
            }
            tvWidgetRef.current.onChartReady(() => {
                var _a;
                (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.headerReady().then(() => {
                    var _a;
                    // tradinview charts do not support overriding symbol search button, so this is a workaround to enable us to do that
                    const iframe = chartContainerRef.current.querySelectorAll(':scope > iframe')[0];
                    const originalButton = (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.document.getElementById('header-toolbar-symbol-search');
                    originalButton === null || originalButton === void 0 ? void 0 : originalButton.addEventListener('click', handleGoToInstruments);
                });
            });
        }
    }, [chartContainerRef, dispatch, history, showSymbolSelectButton]);
    useEffect(() => {
        const handleCrosshairMoved = ({ time, price }) => {
            dispatch(actions.setLiveCrosshairPrice(price));
        };
        if (tvWidgetRef.current === null) {
            throw new Error('tvWidgetRef.current is null');
        }
        if (crosshairPriceSelectionEnabled) {
            tvWidgetRef.current.onChartReady(() => {
                var _a;
                (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.activeChart().crossHairMoved().subscribe(null, handleCrosshairMoved);
            });
        }
        else {
            tvWidgetRef.current.onChartReady(() => {
                var _a;
                (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.activeChart().crossHairMoved().unsubscribeAll(null);
            });
        }
        // cleaning handled by tvWidgetRef.current.remove() call in useEffect above
    }, [crosshairPriceSelectionEnabled, dispatch]);
    useEffect(() => {
        var _a;
        const handleMouseUp = () => {
            dispatch(actions.selectCurrentCrosshairPrice());
        };
        const iframe = chartContainerRef.current.querySelectorAll(':scope > iframe')[0];
        if (crosshairPriceSelectionEnabled) {
            (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            var _a;
            (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.removeEventListener('mouseup', handleMouseUp);
        };
    }, [chartContainerRef, crosshairPriceSelectionEnabled, dispatch]);
    useEffect(() => {
        if (tvWidgetRef.current === null) {
            throw new Error('tvWidgetRef.current is null');
        }
        tvWidgetRef.current.onChartReady(() => {
            var _a;
            (_a = tvWidgetRef.current) === null || _a === void 0 ? void 0 : _a.setSymbol(activeInstrument.symbol, activeResolution, console.log);
        });
    }, [activeInstrument, activeResolution]);
    return _jsx("div", { ref: chartContainerRef, className: styles.container });
});
export default React.memo(AnalysisChart);
