let customSagaErrorLogger;
export let incomingMessageLogger;
export let outgoingMessageLogger;
export let sagaErrorLogger = (error, extra) => {
    if (!!customSagaErrorLogger) {
        customSagaErrorLogger(error, extra);
    }
    else {
        console.error(error);
        console.error(extra.sagaStack);
    }
};
export const setIncomingMessageLogger = (callback) => {
    incomingMessageLogger = callback;
};
export const setOutgoingMessageLogger = (callback) => {
    outgoingMessageLogger = callback;
};
export const setSagaErrorLogger = (callback) => {
    customSagaErrorLogger = callback;
};
