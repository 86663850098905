import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './PositionsList.module.scss';
import _t from 'counterpart';
import { formatDateTime, isSet } from '../../helpers';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import { actions, PositionSide, selectors } from 'wintrado-api';
import { useAppDispatch, useAppSelector } from '../../storeHooks';
import { formatCurrency } from 'utils/currency';
import classNames from 'classnames';
import InstrumentIconName from 'components/InstrumentIconName/InstrumentIconName';
import { useHistory, useRouteMatch } from 'react-router-dom';
const PositionsList = ({ positions }) => {
    const instruments = useAppSelector((state) => state.instruments);
    const latestQuotes = useAppSelector(selectors.latestQuotesSelector);
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    const match = useRouteMatch('/trading/positions/:positionId');
    const editingPositionId = match !== null ? Number(match.params.positionId) : null;
    const history = useHistory();
    const dispatch = useAppDispatch();
    const handleRowClick = (e, position) => {
        e.preventDefault();
        if (!position.positionId) {
            throw Error('Trying to click position without positionId');
        }
        const url = `/trading/positions/${position.positionId}`;
        dispatch(actions.setActivePositionId(position.positionId));
        if (history.location.pathname.includes('/trading/positions/')) {
            history.replace(url);
        }
        else {
            history.push(url);
        }
    };
    return (_jsxs("div", Object.assign({ className: styles.table }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("span", { children: _t('main.symbol') }), _jsx("span", { children: _t('main.time') }), _jsx("span", { children: _t('main.type') }), _jsx("span", { children: _t('main.volume') }), _jsx("span", { children: _t('main.open_price') }), _jsx("span", { children: _t('main.stop_loss') }), _jsx("span", { children: _t('main.take_profit') }), _jsx("span", { children: _t('main.current_price') }), _jsx("span", { children: _t('main.profit') })] })), positions.map((position) => {
                if (!position.symbol) {
                    return null;
                }
                const instrument = instruments[position.symbol];
                const latestQuote = latestQuotes[position.symbol];
                const currentPrice = position.side === PositionSide.BUY ? latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.bid : latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.ask;
                if (!instrument) {
                    throw Error(`Instrument ${position.symbol} not found`);
                }
                return (_jsxs("div", Object.assign({ className: classNames(styles.position, {
                        [styles.positionActive]: editingPositionId === position.positionId,
                    }), onClick: (e) => handleRowClick(e, position) }, { children: [_jsx(InstrumentIconName, { instrument: instrument }), _jsx("div", { children: isSet(position.openedAt) && formatDateTime(position.openedAt) }), _jsx("div", { children: isSet(position.side) ? _t(`main.${position.side.toLowerCase()}`) : _jsx(LoadingDots, {}) }), _jsx("div", Object.assign({ className: styles.size }, { children: isSet(position.size) ? position.size : _jsx(LoadingDots, {}) })), _jsx("div", Object.assign({ className: styles.openPrice }, { children: isSet(position.openPrice) ? position.openPrice.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) })), _jsx("div", Object.assign({ className: styles.stopLoss }, { children: isSet(position.stopLoss) ? position.stopLoss : '—' })), _jsx("div", Object.assign({ className: styles.takeProfit }, { children: isSet(position.takeProfit) ? position.takeProfit : '—' })), _jsx("div", Object.assign({ className: styles.currentPrice }, { children: isSet(currentPrice) ? currentPrice.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) })), _jsx("div", Object.assign({ className: classNames(styles.profit, {
                                [styles.profitNegative]: isSet(position.profitLoss) && position.profitLoss < 0,
                                [styles.profitPositive]: isSet(position.profitLoss) && position.profitLoss >= 0,
                            }) }, { children: isSet(position.profitLoss) ? formatCurrency(position.profitLoss, accountCurrency) : _jsx(LoadingDots, {}) }))] }), position.positionId));
            })] })));
};
export default React.memo(PositionsList);
