var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
const AnyLink = React.forwardRef((_a, ref) => {
    var { to, onClick, children, disabled = false, exact, activeClassName, openExternalInNewTab = false } = _a, props = __rest(_a, ["to", "onClick", "children", "disabled", "exact", "activeClassName", "openExternalInNewTab"]);
    const handleClick = useCallback((e) => {
        if (disabled) {
            e.preventDefault();
        }
        else {
            if (onClick) {
                onClick(e);
            }
        }
    }, [disabled, onClick]);
    // If external link
    if (/^https?:\/\//.test(to)) {
        return (
        // @ts-ignore
        _jsx("a", Object.assign({ href: to, target: openExternalInNewTab ? '_blank' : undefined, rel: "noreferrer", onClick: handleClick, ref: ref }, props, { children: children })));
    }
    // It is an internal link
    return (_jsx(NavLink, Object.assign({ ref: ref, to: to, onClick: handleClick, activeClassName: activeClassName, exact: exact }, props, { children: children })));
});
export default React.memo(AnyLink);
