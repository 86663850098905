import { all, call, delay, put, race, select, spawn, take, takeEvery } from "redux-saga/effects";
import { API_DISCONNECTED, appendQuoteForSymbol, PRIME_QUOTES_RECEIVED, primeQuotesSet, QUOTE_RECEIVED, setQuotes, setQuotesForSymbol } from "../actions";
import quotesHelpers from "../utils/quotesHelpers";
import { isSet } from "../utils/helpers";
import { activeInstrumentQuotesSelector } from "../selectors";
import { resolutionToStartDate } from "../utils/aggregateCandles";
const TICK_FREQUENCY = 1000;
function* receiveQuote(quote) {
    const { symbol } = quote;
    if (!isSet(symbol)) {
        return;
    }
    const activeInstrumentSymbol = yield select((state) => { var _a; return (_a = state.activeInstrument) === null || _a === void 0 ? void 0 : _a.symbol; });
    const currentQuote = quotesHelpers.sanitizeSingleQuote(quote);
    if (activeInstrumentSymbol === symbol) {
        yield put(appendQuoteForSymbol(symbol, currentQuote));
    }
    else {
        yield put(setQuotesForSymbol(symbol, [currentQuote]));
    }
}
function* handleQuoteReceived(action) {
    let data = action.payload;
    yield call(receiveQuote, data);
}
function* handlePrimeQuotesReceived(action) {
    const { quotes } = action.payload;
    for (let quote of quotes) {
        yield call(receiveQuote, quote);
    }
    yield put(primeQuotesSet());
}
function* handleActiveInstrumentTicks() {
    while (true) {
        yield delay(TICK_FREQUENCY);
        yield call(createTickIfNeeded);
    }
}
function* createTickIfNeeded() {
    const activeInstrumentQuotes = yield select(activeInstrumentQuotesSelector);
    if (!activeInstrumentQuotes || activeInstrumentQuotes.length === 0) {
        return;
    }
    const lastQuote = activeInstrumentQuotes[activeInstrumentQuotes.length - 1];
    if (!lastQuote || (new Date().getTime()) - lastQuote.timestamp.getTime() < TICK_FREQUENCY) {
        return;
    }
    yield spawn(receiveQuote, {
        symbol: lastQuote.symbol,
        ask: lastQuote.ask,
        bid: lastQuote.bid,
        timestamp: lastQuote.timestampMs,
    });
}
function* cleanQuotesSaga() {
    while (true) {
        yield delay(20 * 1000);
        const chartResolution = yield select((state) => state.settings.chartResolution);
        const removeOlderThan = resolutionToStartDate(chartResolution);
        const quotes = yield select((state) => state.quotes);
        const clearedQuotes = quotesHelpers.clearOldQuotes(Object.assign({}, quotes), removeOlderThan);
        yield put(setQuotes(clearedQuotes));
    }
}
function* quotesSaga() {
    yield all([
        takeEvery(QUOTE_RECEIVED, handleQuoteReceived),
        takeEvery(PRIME_QUOTES_RECEIVED, handlePrimeQuotesReceived),
        race([
            call(cleanQuotesSaga),
            call(handleActiveInstrumentTicks),
            take(API_DISCONNECTED),
        ])
    ]);
}
export default quotesSaga;
