import { ENABLE_CROSSHAIR_PRICE_SELECTION, DISABLE_CROSSHAIR_PRICE_SELECTION, SELECT_CURRENT_CROSSHAIR_PRICE, SET_LIVE_CROSSHAIR_PRICE } from '../../actions';
export default function proTradingScreenReducer(state, action) {
    switch (action.type) {
        case ENABLE_CROSSHAIR_PRICE_SELECTION: {
            return Object.assign(Object.assign({}, state), { proTradingScreen: Object.assign(Object.assign({}, state.proTradingScreen), { crosshairPriceSelection: {
                        enabled: true,
                        livePrice: null,
                        selectedPrice: null,
                    } }) });
        }
        case DISABLE_CROSSHAIR_PRICE_SELECTION: {
            return Object.assign(Object.assign({}, state), { proTradingScreen: Object.assign(Object.assign({}, state.proTradingScreen), { crosshairPriceSelection: {
                        enabled: false,
                        livePrice: null,
                        selectedPrice: null,
                    } }) });
        }
        case SET_LIVE_CROSSHAIR_PRICE: {
            return Object.assign(Object.assign({}, state), { proTradingScreen: Object.assign(Object.assign({}, state.proTradingScreen), { crosshairPriceSelection: Object.assign(Object.assign({}, state.proTradingScreen.crosshairPriceSelection), { livePrice: action.payload.price }) }) });
        }
        case SELECT_CURRENT_CROSSHAIR_PRICE: {
            return Object.assign(Object.assign({}, state), { proTradingScreen: Object.assign(Object.assign({}, state.proTradingScreen), { crosshairPriceSelection: Object.assign(Object.assign({}, state.proTradingScreen.crosshairPriceSelection), { selectedPrice: state.proTradingScreen.crosshairPriceSelection.livePrice }) }) });
        }
        default:
            return state;
    }
}
