import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './Spacer.module.scss';
import classNames from 'classnames';
import { isSet } from '../../helpers';
const Spacer = ({ x, y }) => {
    return (_jsx("div", { "aria-hidden": "true", className: classNames(styles.spacer, {
            [styles.spacerY]: isSet(y),
            [styles.spacerX]: isSet(x),
            [styles.extraExtraSmall]: (x || y) === 'extraExtraSmall',
            [styles.extraSmall]: (x || y) === 'extraSmall',
            [styles.small]: (x || y) === 'small',
            [styles.medium]: (x || y) === 'medium',
            [styles.large]: (x || y) === 'large',
            [styles.extraLarge]: (x || y) === 'extraLarge',
            [styles.flexExpand]: (x || y) === 'flex-expand',
        }) }));
};
export default React.memo(Spacer);
