import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useAppSelector } from '../../storeHooks';
import styles from './CustomBanner.module.scss';
import AnyLink from 'components/AnyLink/AnyLink';
const CustomBanner = () => {
    const activeBanner = useAppSelector((state) => state.broker.activeBanner);
    const renderBannerImage = useCallback(() => {
        return _jsx("img", { src: activeBanner.fileLink, alt: "banner", className: styles.image });
    }, [activeBanner]);
    if (!activeBanner) {
        return null;
    }
    if (!activeBanner.link) {
        renderBannerImage();
    }
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [!activeBanner.link && renderBannerImage(), activeBanner.link && (_jsx(AnyLink, Object.assign({ className: styles.link, to: activeBanner.link, target: activeBanner.linkTarget }, { children: renderBannerImage() })))] })));
};
CustomBanner.defaultProps = {
    asStat: false,
};
export default CustomBanner;
