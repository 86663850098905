import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styles from './AccountStatementTable.module.scss';
import LoadingDots from 'components/LoadingDots/LoadingDots';
const _t = require('counterpart');
const AccountStatementTable = ({ columns, data, rowsKey, cellOverrides, isLoading, card, useCards, noContentPlaceholder, }) => {
    const calculatedWidth = 100 % columns.length;
    const generateCell = (rowKey, objKey, record) => {
        if (cellOverrides[objKey] !== undefined) {
            const override = cellOverrides[objKey](record);
            return (_jsx(React.Fragment, { children: React.cloneElement(override, { style: { width: `${calculatedWidth}%` } }) }, `${rowKey}-${objKey}`));
        }
        return (_jsx("td", Object.assign({ style: { width: `${calculatedWidth}%` } }, { children: record[objKey] }), `${rowKey}-${objKey}`));
    };
    const isNotEmpty = data && data.length > 0;
    return (_jsxs(_Fragment, { children: [!useCards && (_jsx("div", Object.assign({ className: styles.tableWrapper }, { children: _jsxs("table", Object.assign({ className: styles.table }, { children: [_jsx("thead", { children: _jsx("tr", { children: columns.map((c) => (_jsx("th", Object.assign({ className: styles.tableHeadTh }, { children: c.label }), c.key))) }) }), _jsxs("tbody", { children: [isNotEmpty &&
                                    data.map((d) => {
                                        if (d[rowsKey] === null) {
                                            throw new Error(`Missing property with key ${rowsKey}`);
                                        }
                                        return (_jsx("tr", Object.assign({ className: styles.tableBodyTr }, { children: columns.map((c) => generateCell(d[rowsKey], c.key, d)) }), d[rowsKey]));
                                    }), !isNotEmpty && !isLoading && !noContentPlaceholder && (_jsx("tr", { children: _jsx("td", Object.assign({ className: styles.emptyRow, colSpan: columns.length }, { children: _jsx("span", { children: _t('main.no-content') }) })) })), !isNotEmpty && !isLoading && noContentPlaceholder && _jsx("tr", { children: noContentPlaceholder }), isLoading && (_jsx("tr", { children: _jsx("td", Object.assign({ className: styles.emptyRow, colSpan: columns.length }, { children: _jsx(LoadingDots, {}) })) }))] })] })) }))), useCards && (_jsxs("div", Object.assign({ className: styles.cards }, { children: [isNotEmpty &&
                        !isLoading &&
                        data.map((d) => (_jsx("div", Object.assign({ className: styles.card }, { children: React.createElement(card, d) }), d.id))), !isNotEmpty && !isLoading && !noContentPlaceholder && (_jsx("div", Object.assign({ className: styles.emptyCard }, { children: _jsx("span", { children: _t('main.no-content') }) }))), !isNotEmpty && !isLoading && noContentPlaceholder && (_jsx("div", Object.assign({ className: styles.emptyCard }, { children: noContentPlaceholder }))), isLoading && (_jsx("div", Object.assign({ className: styles.emptyCard }, { children: _jsx(LoadingDots, {}) })))] })))] }));
};
export default React.memo(AccountStatementTable);
