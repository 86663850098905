import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import styles from './PendingOrdersList.module.scss';
import _t from 'counterpart';
import { formatDateTime, isSet } from '../../helpers';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import { selectors } from 'wintrado-api';
import { useAppSelector } from '../../storeHooks';
import { OrderSide } from 'wintrado-api/src/reducers/orders/types';
import InstrumentIconName from 'components/InstrumentIconName/InstrumentIconName';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
const PendingOrdersList = ({ pendingOrders }) => {
    const instruments = useAppSelector((state) => state.instruments);
    const latestQuotes = useAppSelector(selectors.latestQuotesSelector);
    const history = useHistory();
    const handleRowClick = useCallback((e, order) => {
        const url = `/trading/orders/${order.id}`;
        if (history.location.pathname.includes('/trading/orders/')) {
            history.replace(url);
        }
        else {
            history.push(url);
        }
    }, [history]);
    const editingOrderId = useMemo(() => {
        if (!history.location.pathname.includes('/trading/orders/')) {
            return null;
        }
        return Number(history.location.pathname.split('/').pop());
    }, [history.location.pathname]);
    return (_jsxs("div", Object.assign({ className: styles.table }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("span", { children: _t('main.symbol') }), _jsx("span", { children: _t('main.placed_at') }), _jsx("span", { children: _t('main.type') }), _jsx("span", { children: _t('main.volume') }), _jsx("span", { children: _t('main.stop_price') }), _jsx("span", { children: _t('main.limit_price') }), _jsx("span", { children: _t('main.current_price') })] })), pendingOrders.map((order) => {
                if (!order.symbol) {
                    return null;
                }
                const instrument = instruments[order.symbol];
                const latestQuote = latestQuotes[order.symbol];
                const currentPrice = order.side === OrderSide.BUY ? latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.bid : latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.ask;
                if (!instrument) {
                    throw new Error(`Instrument ${order.symbol} not found`);
                }
                return (_jsxs("div", Object.assign({ onClick: (e) => handleRowClick(e, order), className: classNames(styles.pendingOrder, {
                        [styles.pendingOrderActive]: editingOrderId === order.id,
                    }) }, { children: [_jsx(InstrumentIconName, { instrument: instrument }), _jsx("div", { children: formatDateTime(order.placedAt) }), _jsx("div", { children: _t(`main.${order.side.toLowerCase()}`) }), _jsx("div", Object.assign({ className: styles.size }, { children: order.size })), _jsx("div", Object.assign({ className: styles.stopPrice }, { children: isSet(order.stopPrice) ? order.stopPrice.toFixed(instrument.decimals) : '—' })), _jsx("div", Object.assign({ className: styles.limitPrice }, { children: isSet(order.limitPrice) ? order.limitPrice.toFixed(instrument.decimals) : '—' })), _jsx("div", Object.assign({ className: styles.currentPrice }, { children: isSet(currentPrice) ? currentPrice.toFixed(instrument.decimals) : _jsx(LoadingDots, {}) }))] }), order.id));
            })] })));
};
export default React.memo(PendingOrdersList);
