import { ERROR_CLEAR, ERROR_SHOW } from '../../actions';
const initialState = {
    type: null,
    message: null,
};
function errorReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case ERROR_CLEAR:
            return Object.assign(Object.assign({}, state), { error: initialState });
        case ERROR_SHOW: {
            return Object.assign(Object.assign({}, state), { loading: false, error: {
                    type: action.payload.type,
                    message: action.payload.message,
                    extra: action.payload.extra,
                } });
        }
        default:
            return state;
    }
}
export default errorReducer;
