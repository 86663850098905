import posthog from 'posthog-js';
class Analytics {
    constructor() {
        this.initialized = false;
    }
    init(key, config) {
        posthog.init(key, config);
        this.initialized = true;
    }
    identifyUser(userId, email) {
        if (this.initialized) {
            posthog.identify(userId);
            if (email) {
                posthog.people.set({ email });
            }
        }
    }
    resetUserData() {
        if (this.initialized) {
            posthog.reset();
        }
    }
    captureEvent(eventName, additionalData) {
        if (this.initialized) {
            posthog.capture(eventName, additionalData);
        }
    }
}
const analytics = new Analytics();
export default analytics;
