import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import styles from './TradingScreen.module.scss';
import BalanceBar from 'components/BalanceBar/BalanceBar';
import InstrumentSidebar from 'components/InstrumentSidebar/InstrumentSidebar';
import PlaceOrderSidebar from 'components/PlaceOrderSidebar/PlaceOrderSidebar';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PositionsOrdersSection from 'components/PositionsOrdersSection/PositionsOrdersSection';
import EditOrderSidebar from 'components/EditOrderSidebar/EditOrderSidebar';
import EditPositionSidebar from 'components/EditPositionSidebar/EditPositionSidebar';
import ResizableContainer from 'components/ResizableContainer/ResizableContainer';
import AnalysisChart from 'components/AnalysisChart/AnalysisChart';
const getMaxWidthFromWindowWidth = (windowWidth) => {
    return windowWidth * 0.5;
};
const TradingScreen = () => {
    const { path } = useRouteMatch();
    const [width, setWidth] = React.useState(435);
    const [maxWidth, setMaxWidth] = React.useState(getMaxWidthFromWindowWidth(window.innerWidth));
    const analysisChartRef = useRef(null);
    useEffect(() => {
        function handleResize() {
            const newMaxWidth = getMaxWidthFromWindowWidth(window.innerWidth);
            setMaxWidth(newMaxWidth);
            if (width > newMaxWidth) {
                setWidth(newMaxWidth);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    const handleOnResize = (event, data) => {
        setWidth(data.size.width);
    };
    const handleOnResizeStart = (event, data) => {
        if (analysisChartRef.current) {
            analysisChartRef.current.style.pointerEvents = 'none';
        }
    };
    const handleOnResizeStop = (event, data) => {
        if (analysisChartRef.current) {
            analysisChartRef.current.style.pointerEvents = 'auto';
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(BalanceBar, {}), _jsxs("div", Object.assign({ className: styles.mainContent }, { children: [_jsx("div", Object.assign({ className: styles.main }, { children: _jsx(AnalysisChart, { ref: analysisChartRef, showPlaceOrderButton: true }) })), _jsx(ResizableContainer, Object.assign({ width: width, height: Infinity, minConstraints: [435, 0], maxConstraints: [maxWidth, Infinity], onResize: handleOnResize, onResizeStart: handleOnResizeStart, onResizeStop: handleOnResizeStop, axis: 'x', handlePlacement: 'w' }, { children: _jsx("div", Object.assign({ className: styles.sidebar }, { children: _jsxs(Switch, { children: [_jsx(Route, Object.assign({ exact: true, path: `${path}/orders/:orderId` }, { children: _jsx(EditOrderSidebar, {}) })), _jsx(Route, Object.assign({ exact: true, path: `${path}/positions/:positionId` }, { children: _jsx(EditPositionSidebar, {}) })), _jsx(Route, Object.assign({ exact: true, path: `${path}/place-order` }, { children: _jsx(PlaceOrderSidebar, {}) })), _jsx(Route, Object.assign({ exact: true, path: "*" }, { children: _jsx(InstrumentSidebar, {}) }))] }) })) }))] })), _jsx("footer", Object.assign({ className: styles.footer }, { children: _jsx(PositionsOrdersSection, { onResizeStart: handleOnResizeStart, onResizeStop: handleOnResizeStop }) }))] })) }));
};
export default React.memo(TradingScreen);
