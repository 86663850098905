import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './PerformanceItem.module.scss';
import classNames from 'classnames';
import { isSet } from '../../helpers';
const PerformanceItem = ({ value, label }) => {
    return (_jsxs("div", Object.assign({ className: styles.item }, { children: [_jsx("span", Object.assign({ className: classNames(styles.itemValue, {
                    [styles.itemValuePositive]: value !== null && value > 0,
                    [styles.itemValueNegative]: value !== null && value < 0,
                }) }, { children: isSet(value) ? `${value.toFixed(2)}%` : '—' })), _jsx("span", Object.assign({ className: styles.itemLabel }, { children: label }))] })));
};
export default React.memo(PerformanceItem);
