import React, { useState } from 'react';
import Loader from 'components/Loader/Loader';

export const CashierIframeWrapper = ({ url }) => {
	const [isLoading, setIsLoading] = useState(true);

	const handleIframeLoaded = () => {
		setIsLoading(false);
	};

	return (
		<div className="cashier-iframe-wrapper">
			<iframe src={url} frameBorder={0} onLoad={handleIframeLoaded} className="cashier-iframe-wrapper__iframe" />
			{isLoading && <Loader opaqueBackground />}
		</div>
	);
};
