import { CURRENT_COMPETITION_REJECT, CURRENT_COMPETITION_REQUESTED, CURRENT_COMPETITION_SUCCESS, JOIN_COMPETITION_REJECT, JOIN_COMPETITION_REQUESTED, JOIN_COMPETITION_SUCCESS, LEADERBOARD_RANKING_REJECT, LEADERBOARD_RANKING_REQUEST, LEADERBOARD_RANKING_SUCCESS, SET_LEADERBOARD_USER_DETAILS, } from '../../actions';
export default function brokerReducer(state, action) {
    switch (action.type) {
        case LEADERBOARD_RANKING_REQUEST:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { ranks: {
                        data: null,
                        loading: true,
                        error: false,
                    } }) });
        case LEADERBOARD_RANKING_REJECT:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { ranks: {
                        data: null,
                        loading: false,
                        error: true,
                    } }) });
        case LEADERBOARD_RANKING_SUCCESS:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { ranks: {
                        data: action.payload.ranks,
                        loading: false,
                        error: false,
                    } }) });
        case SET_LEADERBOARD_USER_DETAILS:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { user: {
                        rank: action.payload.rank,
                        nickname: action.payload.nickname,
                        points: action.payload.points,
                        change: action.payload.change,
                    } }) });
        case JOIN_COMPETITION_REQUESTED:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { joinCompetition: {
                        error: false,
                        loading: true,
                        success: false,
                    } }) });
        case JOIN_COMPETITION_SUCCESS:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { joinCompetition: {
                        error: false,
                        loading: false,
                        success: true,
                    }, currentCompetition: {
                        loading: false,
                        error: false,
                        data: action.payload.competition,
                    } }) });
        case JOIN_COMPETITION_REJECT:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { joinCompetition: {
                        error: action.payload.error,
                        loading: false,
                        success: false,
                    } }) });
        case CURRENT_COMPETITION_REQUESTED:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { currentCompetition: {
                        loading: true,
                        error: false,
                        data: null,
                    } }) });
        case CURRENT_COMPETITION_SUCCESS:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { currentCompetition: {
                        loading: false,
                        error: false,
                        data: action.payload.data,
                    } }) });
        case CURRENT_COMPETITION_REJECT:
            return Object.assign(Object.assign({}, state), { leaderboard: Object.assign(Object.assign({}, state.leaderboard), { currentCompetition: {
                        loading: false,
                        error: true,
                        data: null,
                    } }) });
        default:
            return state;
    }
}
