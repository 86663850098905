import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './BrandLogo.module.scss';
import { useAppSelector } from '../../storeHooks';
// TODO fix this, it's nasty
const extension = __BRAND__ === 'empirexinvest' ? 'png' : 'svg';
const BrandLogo = () => {
    const { data: affiliateLogo, isLoading: affiliateLogoIsLoading } = useAppSelector((state) => state.greyLabelLogo);
    const logoUrl = useMemo(() => { var _a; return (_a = affiliateLogo === null || affiliateLogo === void 0 ? void 0 : affiliateLogo.squareLogo) !== null && _a !== void 0 ? _a : require(`/src/old/images/brands/${__BRAND__}/logo.${extension}`); }, [affiliateLogo]);
    return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: !affiliateLogoIsLoading && (_jsx("img", { src: logoUrl, alt: `${__BRAND__}-logo`, className: styles.logo })) })));
};
export default React.memo(BrandLogo);
